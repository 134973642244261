import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { AppService } from "@services/app.service";
import { ToastrService } from "ngx-toastr";
declare var $: any;

@Component({
  selector: 'app-mapa-escrutinio',
  templateUrl: './mapa-escrutinio.component.html',
  styleUrls: ['./mapa-escrutinio.component.scss']
})
export class MapaEscrutinioComponent implements OnInit {
  constructor(
    private appService: AppService,
    private http: HttpClient,
    private toastr: ToastrService
  ) { }
  public user: any = null;
  count_mesas: any;
  puestos_votacion: any = [];
  sectores: any = [];
  mesaInfo: any = null;
  intevalo: number = 0;
  timer: any;
  timerSelect: any;

  cargando: boolean = false;
  testigosList: any = [];
  puestoNombre: any;
  mesaNombre: any;
  e14Img: any;
  imgName: any;
  usuario_nombre: any;
  nota_escrutinio: any;
  usuario_celular: any;
  countMesaTestigo: any;
  usuario_testigo: any = [];
  usuario_supervisor: any = [];
  usuario_escrutinio_nombre: any;
  usuario_escrutinio_celular: any;

  votacion_app: false;


  ngOnInit(): void {
    this.user = this.appService.user;
    // console.log(this.user);
    this.getMapaVotacion();
  }

  counter(i: number) {
    // console.log(i);
    return new Array(i);
  }

  getMapaVotacion() {
    let params = {
      getMapaEscrutinio: true,
      et: this.user.et,
      id: this.user.uid,
    };
    // console.log(params);
    this.appService.postPuestoVotacion(params).subscribe((data) => {
      // console.log(data);
      if (data["logger"] == true) {
        this.count_mesas = parseFloat(data["count_mesas"]);
        this.sectores = data["sectores"];
        this.votacion_app = data["votacion_app"];
      } else {
        this.toastr.error(data["msg"]);
      }
    });
  }

  votacionApp(estado) {
    let params = {
      votacionApp: true,
      estado: estado,
      et: this.user.et,
    };
    // console.log(params);
    this.appService.postPuestoVotacion(params).subscribe((data) => {
      // console.log(data);
      if (data["logger"] == true) {
        this.getMapaVotacion();
      } else {
        this.toastr.error(data["msg"]);
      }
    });
  }

  selectMesa(item) {
    $("#verMesa").modal("show");
    this.mesaInfo = item;
    this.nota_escrutinio = this.mesaInfo.nota_escrutinio;
    // console.log(item);

    let params = {
      votacionResultadoMesa: true,
      et: this.user.et,
      puesto_id: item.puesto_id,
      mesa_id: item.id,
    };
    this.appService.postVotacion(params)
      .subscribe((data) => {
        this.puestoNombre = item.puesto_nombre;
        this.mesaNombre = item.nombre;
        if (data["logger"] == true) {
          // console.log(data);
          this.e14Img = data["e14Img"];
          this.imgName = data["imgName"];
          this.testigosList = data["objTestigo"];
          this.countMesaTestigo = data["countMesaTestigo"];
          this.usuario_nombre = data["usuario_nombre"];
          this.usuario_celular = data["usuario_celular"];
          this.usuario_testigo = data["usuario_testigo"];
          this.usuario_supervisor = data["usuario_supervisor"];
          this.usuario_escrutinio_nombre = data["usuario_escrutinio_nombre"];
          this.usuario_escrutinio_celular = data["usuario_escrutinio_celular"];
          this.cargando = false;
        } else {
          this.toastr.error(data["msg"]);
        }
      });

  }

  updateEscrutinio(estado,puesto_id, mesa_id){
    let params = {
      actualizarEscrutinio: true,
      et: this.user.et,
      id: this.user.uid,
      puesto_id: puesto_id,
      detalle: this.nota_escrutinio,
      mesa_id: mesa_id,
      estado: estado
    };
    // console.log(params);
    this.appService.postPuestoVotacion(params).subscribe((data) => {
      // console.log(data);
      if (data["logger"] == true) {
        $("#verMesa").modal("hide");
        this.getMapaVotacion();
      } else {
        this.toastr.error(data["msg"]);
      }
    });
  }

  updateEscrutinioAlert(estado){
    this.mesaInfo.escrutinio_estado = estado;
  }


  girarImagen(grados, imgNombre) {
    this.cargando = true;
    let params = {
      girarImg: true,
      grados: grados,
      imgName: imgNombre,
      folder: 'e14',
      et: this.user.et,
    };
    this.appService.uploadImgEdit(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          // console.log(data);
          this.e14Img = data["fileUrl"];
          this.toastr.success('imagen ajustada');
        } else {
          this.toastr.error(data["msg"]);
        }
        this.cargando = false;
      });

  }


  startTimer(duration: number) {
    this.timerSelect = duration;
    clearInterval(this.timer);
    this.timer = setInterval(() => {
      this.getMapaVotacion();
      console.log('El temporizador ha finalizado.' + duration);
    }, duration);
  }

  stopTimer() {
    this.timerSelect = null;
    clearTimeout(this.timer);
    console.log('stopTimer');
  }

  reseall() {
    this.testigosList = [];
    this.puestoNombre = null;
    this.mesaNombre = null;
    this.e14Img = null;
    this.imgName = null;
    this.usuario_nombre = null;
    this.usuario_celular = null;
    this.countMesaTestigo = null;
    this.usuario_testigo = null;
    this.usuario_supervisor = null;
  }

  parseIntValue(value: any): number {
    return parseInt(value, 10); // La base 10 es común para la conversión numérica
  }
  
  ngOnDestroy() {
    console.log('destroy');
    this.timerSelect = null;
    clearInterval(this.timer);
  }

}
