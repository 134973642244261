import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { AppService } from "@services/app.service";
import { ToastrService } from "ngx-toastr";
declare var $: any;

@Component({
  selector: "app-mapa-votacion",
  templateUrl: "./mapa-votacion.component.html",
  styleUrls: ["./mapa-votacion.component.scss"],
})
export class MapaVotacionComponent implements OnInit {
  constructor(
    private appService: AppService,
    private http: HttpClient,
    private toastr: ToastrService
  ) { }
  public user: any = null;
  count_mesas: any;
  puestos_votacion: any = [];
  mesaInfo: any = null;
  intevalo: number = 0;
  timer: any;
  timerSelect: any;

  cargando: boolean = false;
  testigosList: any = [];
  puestoNombre: any;
  mesaNombre: any;
  e14Img: any;
  imgName: any;
  usuario_nombre: any;
  usuario_celular: any;
  countMesaTestigo: any;
  usuario_testigo: any = [];
  usuario_supervisor: any = [];

  votacion_app: any;


  ngOnInit(): void {
    this.user = this.appService.user;
    this.getMapaVotacion();
  }

  counter(i: number) {
    // console.log(i);
    return new Array(i);
  }

  getMapaVotacion() {
    let params = {
      getMapa: true,
      et: this.user.et,
    };
    // console.log(params);
    this.appService.postPuestoVotacion(params).subscribe((data) => {
      console.log(data);
      if (data["logger"] == true) {
        this.count_mesas = parseFloat(data["count_mesas"]);
        this.puestos_votacion = data["puestos_votacion"];
        this.votacion_app = data["votacion_app"];
      } else {
        this.toastr.error(data["msg"]);
      }
    });
  }

  votacionApp(estado) {
    let params = {
      votacionApp: true,
      estado: estado,
      et: this.user.et,
    };
    // console.log(params);
    this.appService.postPuestoVotacion(params).subscribe((data) => {
      console.log(data);
      if (data["logger"] == true) {
        this.getMapaVotacion();
      } else {
        this.toastr.error(data["msg"]);
      }
    });
  }

  selectMesa(item) {
    $("#verMesa").modal("show");
    this.mesaInfo = item;
    console.log(item);

    let params = {
      votacionResultadoMesa: true,
      et: this.user.et,
      puesto_id: item.puesto_id,
      mesa_id: item.id,
    };
    this.appService.postVotacion(params)
      .subscribe((data) => {
        this.puestoNombre = item.puesto_nombre;
        this.mesaNombre = item.nombre;
        if (data["logger"] == true) {
          console.log(data);
          this.e14Img = data["e14Img"];
          this.imgName = data["imgName"];
          this.testigosList = data["objTestigo"];
          this.countMesaTestigo = data["countMesaTestigo"];
          this.usuario_nombre = data["usuario_nombre"];
          this.usuario_celular = data["usuario_celular"];
          this.usuario_testigo = data["usuario_testigo"];
          this.usuario_supervisor = data["usuario_supervisor"];
          this.cargando = false;
        } else {
          this.toastr.error(data["msg"]);
        }
      });

  }

  borrarVotacion(puesto_id, mesa_id) {
    var confirmacion = confirm("¿Esta seguro que desea eliminar el reporte de votación de esta mesa?.");
    if (confirmacion) {
      let params = {
        deteleVotacion: true,
        et: this.user.et,
        puesto_id: puesto_id,
        mesa_id: mesa_id
      };
      // console.log(params);
      this.appService.postPuestoVotacion(params).subscribe((data) => {
        console.log(data);
        if (data["logger"] == true) {
          $("#verMesa").modal("hide");
          this.getMapaVotacion();
        } else {
          this.toastr.error(data["msg"]);
        }
      });
    }

  }

  girarImagen(grados, imgNombre) {
    this.cargando = true;
    let params = {
      girarImg: true,
      grados: grados,
      imgName: imgNombre,
      folder: 'e14',
      et: this.user.et,
    };
    this.appService.uploadImgEdit(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          console.log(data);
          this.e14Img = data["fileUrl"];
          this.toastr.success('imagen ajustada');
        } else {
          this.toastr.error(data["msg"]);
        }
        this.cargando = false;
      });

  }


  startTimer(duration: number) {
    this.timerSelect = duration;
    clearInterval(this.timer);
    this.timer = setInterval(() => {
      this.getMapaVotacion();
      console.log('El temporizador ha finalizado.' + duration);
    }, duration);
  }

  stopTimer() {
    this.timerSelect = null;
    clearTimeout(this.timer);
    console.log('stopTimer');
  }

  reseall() {
    this.testigosList = [];
    this.puestoNombre = null;
    this.mesaNombre = null;
    this.e14Img = null;
    this.imgName = null;
    this.usuario_nombre = null;
    this.usuario_celular = null;
    this.countMesaTestigo = null;
    this.usuario_testigo = null;
    this.usuario_supervisor = null;
  }

  parseIntValue(value: any): number {
    return parseInt(value, 10); // La base 10 es común para la conversión numérica
  }

  ngOnDestroy() {
    console.log('destroy');
    this.timerSelect = null;
    clearInterval(this.timer);
  }

}
