<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-5">
                <h1>
                  Clientes
                  <button class="btn btn-sm btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNuevaUsuario" aria-controls="offcanvasNuevaUsuario"><i class="fa fa-plus"></i></button>
                  <button class="btn btn-sm btn-success ml-1" type="button" (click)="exportCliente()"><i class="fa fa-file-excel"></i></button>
                </h1>
              </div>
              <div class="col-7">
                <div class="input-group mb-0">
                  <input type="text" class="form-control" [(ngModel)]="findInput" placeholder="Buscador">
                  <button class="btn btn-secondary" type="button"  id="button-addon2" (click)="findCliente()"><i class="fa fa-search"></i></button>
                </div>                  
              </div>
            </div>

          </div>
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">

                  <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                  <li class="breadcrumb-item active">Clientes</li>
              </ol>
          </div>
      </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content m-2">
  <div class="card">
    <div class="card-body table-responsive p-0">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Nit. C.C</th>
            <th scope="col">Email</th>
            <th scope="col">Contacto</th>
            <th scope="col">Estado</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of clientes.items">
            <td>{{item.nombre | titlecase}}</td>
            <td>{{item.cc}}</td>
            <td>{{item.email}}</td>
            <td>{{item.celular}}</td>
            <td><span class="badge bg-success" *ngIf="item.estado ==1">Activo</span></td>
            <td>
              
              <div class="dropdown">
                <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"></button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><span class="dropdown-item"  style="cursor: pointer;" (click)="ver(item)">Ver Cliente</span></li>
                  <li><span class="dropdown-item text-danger" style="cursor: pointer;" (click)="eliminarUsuario(item)">Eliminar</span></li>
                </ul>
              </div>           

            </td>
          </tr>
        </tbody>
      </table>      
    </div>
  </div>

  <!-- paginador -->
  <div class="card-footer clearfix">
    <ul class="pagination pagination-sm m-0 float-right">
      <li class="page-item" *ngIf="clientes.current > 1">
        <button type="button" class="page-link" (click)="changePage(clientes.current - 1)">
          <span>Atras</span>
        </button>
      </li>                  

      <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': clientes.current == page}">
        <button type="button" class="page-link" (click)="changePage(page)">
          {{ page }}
        </button>
      </li>                  

      <li class="page-item" *ngIf="clientes.current < clientes.total_page">
        <button type="button" class="page-link" (click)="changePage(clientes.next)">
          <span>Siguiente</span>
        </button>
      </li>                  
    </ul>
    <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
      <p>Total registros encontrados: <b>{{clientes.size}}</b></p>
    </div>
  </div>              
  <!-- Fin paginador -->  

</section>




  <!-- Modal Ver  -->
  <div class="modal fade" id="ver" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Cliente</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">

            <form [formGroup]="editForm" (ngSubmit)="editUsuario()">
              <div class="row">

                <div class="col-12 mt-3">
                      <div class="form-group">
                        <input formControlName="nombre" placeholder="Nombre"  type="text" class="form-control"/>
                      </div> 

                      <div class="form-group"> 
                        <input formControlName="cc" placeholder="Nit. / C.C" type="text" class="form-control"/>
                      </div>  

                      <div class="form-group">    
                        <input formControlName="email" placeholder="Email" type="text" class="form-control"/>                    
                      </div> 

                      <div class="form-group">
                        <input formControlName="celular" placeholder="Celular" type="text" class="form-control"/>
                      </div>

                      <div class="form-group">                        
                        <input formControlName="direccion" placeholder="Direccion" type="text" class="form-control"/>
                      </div>   
  
                      
                      <div class="form-group">
                        <input formControlName="ciudad" placeholder="Ciudad" type="text" class="form-control"/>
                      </div>  
                      
                      <div class="form-group">
                        <textarea formControlName="nota" placeholder="Nota" type="text" class="form-control" rows="3"></textarea>
                      </div>                        

                      <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Actualizar Cliente</app-button>                       
                </div> 
              </div>

            </form>
                    
          </div>
        
        </div>
      </div>
    </div>
  </div>



  <!-- Modal Nueva  -->
  <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNuevaUsuario" aria-labelledby="offcanvasNuevaUsuarioLabel">
    <div class="offcanvas-header">
      <h5 id="offcanvasRightLabel">Nuevo Cliente</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">

      <form [formGroup]="nuevoForm" (ngSubmit)="newCliente()">
        <div class="col-12 mt-3" id="crearCliente">
              <div class="form-group">
                <input formControlName="nombre" placeholder="Nombre" type="text" class="form-control"/>
              </div> 

              <div class="form-group">
                <input formControlName="cc" placeholder="NIT. / C.C" type="text" class="form-control"/>
              </div>              
              
              <div class="form-group">
                <input formControlName="email" placeholder="Email" type="text" class="form-control"/>
              </div> 

              <div class="form-group">
                <input formControlName="celular" placeholder="Celular Contacto" type="text" class="form-control"/>
              </div> 
                            
              <div class="form-group">
                <input formControlName="direccion" placeholder="Dirección" type="text" class="form-control"/>
              </div>                           

              <div class="form-group">
                <input formControlName="ciudad" placeholder="Ciudad" type="text" class="form-control"/>
              </div> 

              <div class="form-group">
                <textarea formControlName="nota" class="form-control" placeholder="Nota" rows="5"></textarea>
              </div>               


              <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Crear Cliente</app-button>                       
        </div> 

      </form>

    </div>
  </div>
