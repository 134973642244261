<!-- Brand Logo -->
<a [routerLink]="['/']" class="brand-link bg-dark">
    <img
        src="assets/img/logo.png"
        alt="DiaE"
        class="brand-image img-circle elevation-3"
        style="opacity: 0.8"
    />
    <span class="brand-text font-weight-bold"><span *ngIf="!user.ename">Día Electoral</span> <span *ngIf="user.ename">{{user.ename}}</span></span>
</a>

<!-- Sidebar -->
<div class="sidebar">
    <!-- Sidebar user (optional) -->
    <div class="user-panel mt-3 pb-0 mb-3 d-flex">
        <div class="image">
            <img
                [src]="user.fotografia || 'assets/img/default-profile.png'"
                class="img-circle elevation-2"
                alt="User Image"
            />
        </div>
        <div class="info">
            <a [routerLink]="['/profile']" class="d-block" style="font-size: 0.8em;">
                {{ user.email }}
            </a>
            <p *ngIf="user.perfil == '15kwqgk3123'" class="text-white mb-0">SuperAdministrador</p>
            <p *ngIf="user.perfil == '2fk6i2o3143'" class="text-white mb-0">Administrador</p>
            <p *ngIf="user.perfil == '3af3s12f153'" class="text-white mb-0">Asesor</p>
            <p *ngIf="user.empresaid" class="text-body mb-0">{{user.ename}}</p>
        </div>
    </div>
  

    <!-- Sidebar Menu -->
    <nav class="mt-2" style="overflow-y: hidden">
        <ul
            class="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
        >
            <app-menu-item
                *ngFor="let item of menu"
                [menuItem]="item"
                [perfilId]="user.perfil"
            ></app-menu-item>
        </ul>
    </nav>
</div>
