import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
declare var $: any;


@Component({
  selector: 'app-proveedores',
  templateUrl: './proveedores.component.html',
  styleUrls: ['./proveedores.component.scss']
})
export class ProveedoresComponent implements OnInit {
  public nuevoForm: FormGroup;
  public nuevoActividad: FormGroup
  public nuevoActividadEdit: FormGroup
  public editForm: FormGroup;
  public isAuthLoading = false;


  public user: any = null;
  reporteExport:any;
  findInput: any;
  proveedor: any = [];
  pagesNumber: any;
  offset= 3;
  page= 1;  
  public cargando = false;

  selectActividades = [];
  selectActividadesEdit = [];


  constructor( public router: Router,private toastr: ToastrService, private appService: AppService, private http: HttpClient) { }

  ngOnInit(): void {
    this.user = this.appService.user;
    this.getList();  
    
    this.nuevoForm = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      direccion: new FormControl(null, Validators.required),
      contacto: new FormControl(null, Validators.required),
      ciudad: new FormControl(null, Validators.required),
      img: new FormControl(null, Validators.nullValidator),
    });   

    this.nuevoActividad= new FormGroup({
      nombre: new FormControl(null, Validators.required),
    });

    this.nuevoActividadEdit= new FormGroup({
      id: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.required),
    });    


    
    this.editForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.required),
      direccion: new FormControl(null, Validators.required),
      contacto: new FormControl(null, Validators.required),
      ciudad: new FormControl(null, Validators.required),
      img: new FormControl(null, Validators.nullValidator),
    });       

  }

  getList(){
    this.appService.getProveedor(this.user.et+'-'+this.page).subscribe( (data) => {
    this.proveedor = data['obj']; 
    this.ForpagesNumber();
    this.cargando = false;
    });
  }

  changePage(page) {
    this.page = page; //para el filtro
    this.proveedor.current = page;
    this.getList();
  }
  
  ForpagesNumber() {
    let from = this.proveedor.current - this.offset;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offset * 2); 
    if(to >= this.proveedor.total_page){
      to = this.proveedor.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

  async new() {     
    if (this.nuevoForm.valid) {
        this.isAuthLoading = true; 
          let params = {
              nuevo:true,
              et: this.user.et,
              nombre: this.nuevoForm.value.nombre,
              direccion: this.nuevoForm.value.direccion,
              ciudad: this.nuevoForm.value.ciudad,
              contacto: this.nuevoForm.value.contacto,
              img: this.nuevoForm.value.img,
              actividades: this.selectActividades,              
              estado: 1,
            };
            // console.log(params);
            this.appService.postProveedor(params)
          .subscribe( (data) => {
            // console.log(data);
              if (data["logger"] == true){
                  this.nuevoForm.reset()
                  this.selectActividades = [];
                  $('#offcanvasNueva').offcanvas('hide');
                  this.toastr.success(data["msg"]);
                  this.getList();
              }else{
                  this.toastr.error(data["msg"]);
              }
          }); 
        this.isAuthLoading = false;
    } else {
        this.toastr.error('El formulario no es válido! verifique los campos.');
    }
  }

  addActividad(){
    $('#addActividadNew').show();
    $('#addActividadEdit').show();
  } 

  addActividadEditar(){
    $('#addActividadVerNew').show();
    $('#addActividadVerEdit').hide();
  }   

  async newAdd(){  
    if (this.nuevoActividad.valid) {
      // this.selectActividades.push(this.nuevoActividad.value.nombre);
      this.selectActividades.push(this.nuevoActividad.value);
      console.log(this.selectActividades);
      this.nuevoActividad.reset()
      $('#addActividadVerNew').hide();
      $('#addActividadVerEdit').hide();      
    }
  }

  async newAddVer(){  
    if (this.nuevoActividad.valid) {
      // this.selectActividades.push(this.nuevoActividad.value.nombre);
      this.selectActividadesEdit.push(this.nuevoActividad.value);
      console.log(this.selectActividadesEdit);
      this.nuevoActividad.reset()
      $('#addActividadNew').hide();
      $('#addActividadEdit').hide();      
    }
  }  
  
  async eliminarAdd(i: number,item){
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea eliminar este registro?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        this.selectActividades.splice(i,1);       
      }
    });      
  }

  async eliminarAddVer(i: number,item){    
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea eliminar este registro?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        if(this.editForm.value.id){
          let params = {
            eliminar:true,
            id: item.id,
          };  
          this.appService.postProveedorActividad(params)
          .subscribe( (data) => {
              if (data["logger"] == true){
                // this.toastr.success(data["msg"]);
                this.selectActividadesEdit.splice(i,1);       
              }else{
                this.toastr.error(data["msg"]);
              }
          });                    
        }      
      }
    });      
  }  

  async newAddNew(){
    if(this.editForm.value.id){
      // console.log(this.editForm.value.id);
      this.isAuthLoading = true;
      if (this.nuevoActividad.valid) {
        let params = {
          nuevo:true,
          id: this.editForm.value.id,
          nombre: this.nuevoActividad.value.nombre,
          detalle: this.nuevoActividad.value.detalle,
        };
        // console.log(params);
        this.appService.postProveedorActividad(params)
      .subscribe( (data) => {
          if (data["logger"] == true){
            this.toastr.success(data["msg"]);
            console.log(this.nuevoActividad.value);
            // this.selectActividadesEdit.push(["nombre",this.nuevoActividad.value.nombre]);
            this.selectActividadesEdit.push(this.nuevoActividad.value);
            this.nuevoActividad.reset()
            $('#addActividadVerNew').hide();
            $('#addActividadVerEdit').hide();      
          }else{
            this.toastr.error(data["msg"]);
          }
      }); 
        this.isAuthLoading = false;
      }      
    }
  }

  async newAddEdit(){
    if(this.editForm.value.id){
      // console.log(this.editForm.value.id);
      this.isAuthLoading = true;
      if (this.nuevoActividadEdit.valid) {
        let params = {
          editar:true,
          id: this.nuevoActividadEdit.value.id,
          nombre: this.nuevoActividadEdit.value.nombre,
        };
        console.log(params);
        this.appService.postProveedorActividad(params)
      .subscribe( (data) => {
          if (data["logger"] == true){
            this.toastr.success(data["msg"]);
            this.selectActividadesEdit.push(this.nuevoActividadEdit.value);
            this.nuevoActividadEdit.reset()
            $('#addActividadVerNew').hide();
            $('#addActividadVerEdit').hide();      
          }else{
            this.toastr.error(data["msg"]);
          }
      }); 
        this.isAuthLoading = false;
      }      
    }    
  }

  async editAdd(i: number,item){
    this.selectActividades.splice(i,1);       
    this.nuevoActividad.get('nombre').setValue(item.nombre);
    // this.nuevoActividad.get('detalle').setValue(item.detalle);
    $('#addActividadNew').show();
    $('#addActividadEdit').show();      
  }

  async editAddEdit(i: number,item){
    this.selectActividadesEdit.splice(i,1);   
    console.log(item);
        
    this.nuevoActividadEdit.get('id').setValue(item.id);
    this.nuevoActividadEdit.get('nombre').setValue(item.nombre);
    // this.nuevoActividad.get('detalle').setValue(item.detalle);
    $('#addActividadVerEdit').show();
  }  

  ver(item){
    this.editForm.get('id').setValue(item.id);
    this.editForm.get('nombre').setValue(item.nombre);
    this.editForm.get('direccion').setValue(item.direccion);
    this.editForm.get('contacto').setValue(item.contacto);
    this.editForm.get('ciudad').setValue(item.ciudad);
    this.editForm.get('img').setValue(item.img);

    this.selectActividadesEdit = item.actividades;
    $('#offcanvasVer').offcanvas('show');
  }

  async edit(){
    if (this.editForm.valid) {
      this.isAuthLoading = true; 
        let params = {
            editar:true,
            id: this.editForm.value.id,
            nombre: this.editForm.value.nombre,
            direccion: this.editForm.value.direccion,
            ciudad: this.editForm.value.ciudad,
            contacto: this.editForm.value.contacto,
            img: this.editForm.value.img,
          };
          // console.log(params);
        this.appService.postProveedor(params)
        .subscribe( (data) => {
            if (data["logger"] == true){
              this.toastr.success(data["msg"]);
              this.editForm.reset()
              this.selectActividades = [];
              $('#offcanvasVer').offcanvas('hide');
              this.getList();
            }else{
              this.toastr.error(data["msg"]);
            }
        }); 
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }

  async eliminar(item){
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea eliminar este registro?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        // console.log('dentro true');        
        let params = {
          eliminar : true,
          id: item.id,
          empresa_id: this.user.eid
        }
        this.appService.postProveedor(params)
        .subscribe( (data) => {
            if (data["logger"] == true){
                this.getList();
                this.toastr.success(data["msg"]);
            }else{
                this.toastr.error(data["msg"]);
            }
        });         

      }
    });  

  }

  resetFormCampos(){
    $('#addActividadNew').show();
    $('#addActividadEdit').show();
    this.nuevoForm.reset();
    this.nuevoActividad.reset();
    this.selectActividades = [];
  }

  async findBuscar() {     
    let params = {
        buscar:true,
        buscar_input: this.findInput,
        page: this.page,
        et: this.user.et,
      };
        this.appService.postProveedor(params)
        .subscribe( (data) => {
            if (data["logger"] == true){
              this.proveedor = data['obj']; 
            }else{
              this.toastr.error(data["msg"]);
            }
        });
  }

 
}
