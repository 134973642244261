<div *ngIf="empresaValida" class="container col-md-8 bg-light">
  <form [formGroup]="nuevaSolicitudForm" (ngSubmit)="enviarSolicitud()">
  <div class="row mt-5">
    <div class="col-12 text-center mt-2 fontBlue"><h3>SOLICITAR COTIZACIÓN</h3></div>
    <div class="col-12 mt-4  text-center fontBlue">
      <label for="nombre" class="form-label">Nombre Completo</label>
      <input formControlName="nombre" type="text" class="form-control" placeholder="" />      
    </div>
    <div class="col-6 mt-4  text-center fontBlue">
      <label for="nombre" class="form-label">Celular de contacto</label>
      <input formControlName="celular" type="text" class="form-control" placeholder="" />      
    </div>
    <div class="col-6 mt-4  text-center fontBlue">
      <label for="nombre" class="form-label">Email</label>
      <input formControlName="email" type="email" class="form-control" placeholder="" />      
    </div>
    <div class="col-12 text-center mt-4 fontBlue">
      <label for="nombre" class="form-label text-center">¿A dónde Deseas Viajar?<br>
        Indicanos Fecha de viaje y el numero de personas adultos y niños</label>
    </div>
    <div class="col-4 text-center mt-4 fontBlue">
        <input type="date" class="form-control" formControlName="fecha" placeholder="Fecha estimada">
    </div>
    <div class="col-4 text-center mt-4 fontBlue">
      <input type="number" class="form-control" formControlName="adultos" placeholder="Adultos">
    </div> 
    <div class="col-4 text-center mt-4 fontBlue">
      <input type="number" class="form-control" formControlName="infantes" placeholder="Niños">
    </div>        
    <div class="col-12 text-center mt-4 fontBlue">
      <textarea formControlName="detalle" rows="5" type="text" class="form-control" placeholder=""></textarea>
    </div>

    <div class="col-12 text-center mt-4 mb-4"><button class="btn btn-warning btn-lg">Enviar Solicitud</button></div>
  </div>  
  </form>
</div>

<div  class="container col-md-8 bg-light">
  <div class="row mt-5">
    <div *ngIf="isAuthLoading" class="col-12">
      <div class="d-flex justify-content-center">
        <div class="spinner-border fontBlue" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>

    <div *ngIf="empresaNoValida" class="col-12 text-center fontBlue  mt-5">
      <h1> Error 404</h1>
      <small>Pongase en contacto con el departamento de soporte.</small>
    </div>
  </div>
</div>