import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Gatekeeper} from 'gatekeeper-client-sdk';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public user: any = null;
    // public static get baseURL():string{ return"http://localhost:8888/apirest_diae/"};
    // public static get baseURL():string{ return"https://api.germancasagua.com/"};
    public static get baseURL():string{ return"https://apie.zonaelectoral.com/"};


    
    // constructor(private router: Router, private toastr: ToastrService, private http: HttpClient) {}

    cabecera;

    constructor(private router: Router, private http: HttpClient,  private toastr: ToastrService) {
      this.cabecera = new Headers();
      this.cabecera.append('Access-Control-Allow-Origin', '*');
      this.cabecera.append("Cache-Control", "no-cache");
      this.cabecera.append("Access-Control-Allow-Methods", 'GET,POST,OPTIONS,DELETE,PUT');
      this.cabecera.append("Access-Control-Allow-Headers", "gid, filename, Origin, X - Requested - With, Content - Type, Accept, Authorization");
    }    

// ESTADISTICAS    
    getEstadisticas(params){
        return this.http.get(AppService.baseURL+'status/'+params);
    } 


// EMPRESAS    
    getEmpresas(params){
        return this.http.get(AppService.baseURL+'empresa/'+params);
    }    
    
    postEmpresa(params){
        return this.http.post(AppService.baseURL+'empresa/',params);
    }    

// USUARIOS    
    getUsuarios(params){
        return this.http.get(AppService.baseURL+'usuario/'+params);
    }    

    postUsuario(params){
        return this.http.post(AppService.baseURL+'usuario/',params);
    }  
    
// CANDIDATOS    
    getCandidatos(params){
        return this.http.get(AppService.baseURL+'candidato/'+params);
    }    

    postCandidato(params){
        return this.http.post(AppService.baseURL+'candidato/',params);
    } 
    
// PUESTO DE VOTACION    
    getPuestoVotacion(params){
        return this.http.get(AppService.baseURL+'puesto_votacion/'+params);
    }    

    postPuestoVotacion(params){
        return this.http.post(AppService.baseURL+'puesto_votacion/',params);
    } 
    
// MESA DE VOTACION    
    getMesaVotacion(params){
        return this.http.get(AppService.baseURL+'mesa_votacion/'+params);
    }    

    postMesaVotacion(params){
        return this.http.post(AppService.baseURL+'mesa_votacion/',params);
    }  
    
// RELACION DE VOTACION    
    getRelacionVotacion(params){
        return this.http.get(AppService.baseURL+'relacion_votacion/'+params);
    }    

    postRelacionVotacion(params){
        return this.http.post(AppService.baseURL+'relacion_votacion/',params);
    } 
    
// VOTACION    
    getVotacion(params){
        return this.http.get(AppService.baseURL+'votacion/'+params);
    }    

    postVotacion(params){
        return this.http.post(AppService.baseURL+'votacion/',params);
    }

    
// INSCRIPCION PUESTO VOTACION    
    getInscripcionPuestoVotacion(params){
        return this.http.get(AppService.baseURL+'inscripcion_puesto_votacion/'+params);
    }    

    postInscripcionPuestoVotacion(params){
        return this.http.post(AppService.baseURL+'inscripcion_puesto_votacion/',params);
    }

// INSCRIPCION RECOLECTORES
    getInscripcionRecolectores(params){
        return this.http.get(AppService.baseURL+'inscripcion_recolectores/'+params);
    }    

    postInscripcionRecolecotres(params){
        return this.http.post(AppService.baseURL+'inscripcion_recolectores/',params);
    }




// SECTORES    
    getSector(params){
        return this.http.get(AppService.baseURL+'sector/'+params);
    }    

    postSector(params){
        return this.http.post(AppService.baseURL+'sector/',params);
    }     

// SECTOR PUESTO    
    getSectorPuesto(params){
        return this.http.get(AppService.baseURL+'sector_puesto/'+params);
    }    

    postSectorPuesto(params){
        return this.http.post(AppService.baseURL+'sector_puesto/',params);
    }  

// SECTOR RELACION    
    getSectorRelacion(params){
        return this.http.get(AppService.baseURL+'sector_relacion/'+params);
    }    

    postSectorRelacion(params){
        return this.http.post(AppService.baseURL+'sector_relacion/',params);
    }     

// CLIENTES    
    getClientes(params){
        return this.http.get(AppService.baseURL+'cliente/'+params);
    }  

    postClientes(params){
        return this.http.post(AppService.baseURL+'cliente/',params);
    }  

// SOLICITUDES    
    getSolicitudes(params){
        return this.http.get(AppService.baseURL+'solicitud/'+params);
    }  

    postSolicitudes(params){
        return this.http.post(AppService.baseURL+'solicitud/',params);
    }

// PROVEEDORES
    getProveedor(params){
        return this.http.get(AppService.baseURL+'proveedor/'+params);
    }  

    postProveedor(params){
        return this.http.post(AppService.baseURL+'proveedor/',params);
    } 
    
// PROVEEDOR ACTIVIDADES
    
    getProveedorActividad(params){
        return this.http.get(AppService.baseURL+'proveedor_actividad/'+params);
    }  

    postProveedorActividad(params){
        return this.http.post(AppService.baseURL+'proveedor_actividad/',params);
    } 

// CODIGOS IATA    
    getIata(params){
        return this.http.get(AppService.baseURL+'codigo_iata/'+params);
    }  

    postIata(params){
        return this.http.post(AppService.baseURL+'codigo_iata/',params);
    }       

    
// PASAJEROS
    getPasajeros(params){
        return this.http.get(AppService.baseURL+'pasajero/'+params);
    }
    
    postPasajeros(params){
        return this.http.post(AppService.baseURL+'pasajero/',params);
    }    
    
// TARJETA RESERVA    
    getTarjetaReserva(params){
        return this.http.get(AppService.baseURL+'tarjeta_reserva/'+params);
    }
    
    postTarjetaReserva(params){
        return this.http.post(AppService.baseURL+'tarjeta_reserva/',params);
    }    

// UPLOAD IMG
    uploadImg(params){
        return this.http.post(AppService.baseURL+'upload_img/',params);
    }
// UPLOAD IMG Edit
    uploadImgEdit(params){
        return this.http.post(AppService.baseURL+'upload_img_edit/',params);
    }


//  OTROS
    async loginByAuth({email, password}) {
        try {
            const token = await Gatekeeper.loginByAuth(email, password);
            // console.log(token);            
            // const token = "fasdfasdfadfafdafasf";
            localStorage.setItem('token', token);
            // await this.getProfile();
            this.router.navigate(['/']);
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async registerByAuth({email, password}) {
        try {
            const token = await Gatekeeper.registerByAuth(email, password);
            localStorage.setItem('token', token);
            // await this.getProfile();
            this.router.navigate(['/']);
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async Login({email, password}){
        let params = {
            lg:true,
            email: email,
            password: password,
          };
        //   console.log(params);
        this.http.post(AppService.baseURL+'login',params)
        .subscribe( (data) => {
            if (data["logger"] == true){
                // console.log(data);
                const token = data["token"];
                localStorage.setItem('token', token);
                this.user = data;
                this.router.navigate(['/']);
                this.toastr.success(data["msg"]);
            }else{
                this.toastr.error(data["msg"]);

            }
        }); 
        // return this.http.post(AppService.baseURL+'login',params);
    }  

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('gatekeeper_token');
        this.user = null;
        this.router.navigate(['/login']);
        this.toastr.success('Sesión Cerrada');
    }
}
