import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Chart } from 'chart.js';

import * as XLSX from 'xlsx';
declare var $: any;

@Component({
  selector: 'app-escrutinio',
  templateUrl: './escrutinio.component.html',
  styleUrls: ['./escrutinio.component.scss']
})
export class EscrutinioComponent implements OnInit {
  public chart: any = null;
  public chartGeneral: any = null;
  public chartMesa: any = null;
  chartGeneralData: any = [];
  chartGeneralCountAll: any = [];
  chartMesaCountAll: any;


  public nuevoForm: FormGroup;
  public editForm: FormGroup;
  public isAuthLoading = false;


  public user: any = null;

  reporteExport:any;
  findInput: any;
  puestos: any = [];
  mesa_votacion: any = [];
  puestoSelect: any;

  supervisor_votacion:any = [];
  listUsuarios: any = [];
  buscador: any;
  mesaId:any;
  puestoId:any;
  pagesNumber: any;
  offset= 3;
  page= 1;  

  puestoNombre: any;
  mesaNombre: any;
  e14Img: any;
  usuario_nombre: any;
  testigosList:any = [];
  countMesaTestigo : any;
  supervisorList:any = [];
  countMesaSupervisor : any;
  barraCandidatoStatus = false;
  public cargando = false;


  constructor( public router: Router,private toastr: ToastrService, private appService: AppService, private http: HttpClient) { }

  ngOnInit(): void {
    this.user = this.appService.user;
    this.getListPuestos();  
    // this.getListCountGeneral();  

    this.nuevoForm = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      detalle: new FormControl(null, Validators.nullValidator),
    });   
    
    this.editForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.required),
      detalle: new FormControl(null, Validators.nullValidator),
    });       
  }

  getListPuestos(){
    let params = {
      puestoAll:true,
      et: this.user.et,
    };
    this.appService.postVotacion(params)
        .subscribe( (data) => {          
          if (data["logger"] == true){
            this.puestos = data['obj'];  
            this.cargando = false;                
            }else{
              this.toastr.error(data["msg"]);
            }
        }); 
  }



  // getListCountGeneral(){
  //     this.isAuthLoading = true; 
  //       let params = {
  //           countGeneral:true,
  //           et: this.user.et,
  //         };
  //         this.appService.postVotacion(params)
  //       .subscribe( (res) => {          
  //         if (res["logger"] == true){            
  //           this.chartGeneralData = res['obj'];
  //           this.chartGeneralCountAll = res['objCountAll'];
  //           const dataChart = [];
  //           const dataChartlabel = [];
  //           const dataChartColor = [];

            
  //             res['obj'].forEach((item) => {                
  //               dataChartlabel.push(item.nombre) 
  //               dataChart.push(item.total) 
  //               dataChartColor.push(item.color) 
  //             });

  //           this.chartGeneral = new Chart('generalChart', {
  //             type: 'bar',
  //             data: {
  //               labels: dataChartlabel,
  //               datasets: [{
  //                 data: dataChart,
  //                 backgroundColor: dataChartColor        
  //                 // backgroundColor: [
  //                 //   '#ffc107',
  //                 //   '#e74c3c',
  //                 //   'black'
  //                 // ]          
  //             }]        
  //             },
  //             options: {
  //               responsive: true,            
  //             }      
  //            });
                                  
  //           }else{
  //             this.toastr.error(res["msg"]);
  //           }
  //       }); 
  //     this.isAuthLoading = false;
  // }
  
  getListCountMesa(){
      this.isAuthLoading = true; 
        let params = {
            countGeneral:true,
            et: this.user.et,
          };
          this.appService.postVotacion(params)
        .subscribe( (res) => {          
          if (res["logger"] == true){            
            this.chartGeneralData = res['obj'];
            this.chartGeneralCountAll = res['objCountAll'];
            const dataChart = [];
            const dataChartlabel = [];
            const dataChartColor = [];

            
              res['obj'].forEach((item) => {                
                dataChartlabel.push(item.nombre) 
                dataChart.push(item.total) 
                dataChartColor.push(item.color) 
              });

            this.chartGeneral = new Chart('generalChartMesa', {
              type: 'bar',
              data: {
                labels: dataChartlabel,
                datasets: [{
                  data: dataChart,
                  backgroundColor: dataChartColor          
              }]        
              },
              options: {
                responsive: true,
                scales: {
                  xAxes: [{
                      gridLines: {
                          display:false
                      }
                  }],
                  yAxes: [{
                      gridLines: {
                          display:false
                      },
                      scaleLabel: {
                        display: false,
                      }                         
                  }]
                },
                legend: {
                  display: false
                },            
              }      
             });
                                  
            }else{
              this.toastr.error(res["msg"]);
            }
        }); 
      this.isAuthLoading = false;
  }


  ver(item){
    let params = {
      mesaVotacion:true,
      et: this.user.et,
      id: item.id,
    };
    this.appService.postVotacion(params)
        .subscribe( (data) => {          
          if (data["logger"] == true){
            console.log(data);
            this.puestoSelect = item.nombre;
            this.mesa_votacion = data['obj'];  
            this.cargando = false;  
            
          
            // this.chartGeneralData = data['objChart'];
            this.chartMesaCountAll = data['objCountAll'];
            const dataChartMesa = [];
            const dataChartMesalabel = [];
            const dataChartMesaColor = [];

            data['objChart'].forEach((item) => {                
              dataChartMesalabel.push(item.nombre) 
              dataChartMesa.push(item.votos) 
              dataChartMesaColor.push(item.color) 
            });

            if(data['objCountAll'] >= 0) {
              if(this.chartMesa){
                this.chartMesa.destroy();
              }
              console.log('dentro true');
              
            }else{
              console.log('dentro false');

              this.chartMesa.destroy();
            }
            
            this.chartMesa = new Chart('generalChartMesa', {
              type: 'bar',
              data: {
                labels: dataChartMesalabel,
                datasets: [{
                  data: dataChartMesa,
                  backgroundColor: dataChartMesaColor         
              }]        
              },
              options: {
                responsive: true,
                scales: {
                  xAxes: [{
                      gridLines: {
                          display:false
                      }
                  }],
                  yAxes: [{
                      gridLines: {
                          display:false,
                      },
                      ticks: {
                        beginAtZero: true,
                      },
                      scaleLabel: {
                        display: false,
                      }                         
                  }]
                },
                legend: {
                  display: false
                }, 
                tooltips: {
                  position: 'average',
                  mode: 'index',
                  intersect: false,
              },           
              }      
            });            

            }else{
              this.toastr.error(data["msg"]);
            }
        }); 

      // $('#offcanvasVer').offcanvas('show');
  }

  scrollToElement(element: any){
    (document.getElementById(element) as HTMLElement).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  verResultados(item, puestoNombre){
    // console.log(item);
    this.scrollToElement('myTab');

    let params = {
      votacionResultadoMesa:true,
      et: this.user.et,
      puesto_id: item.puesto_id,
      mesa_id: item.id,
    };
    this.appService.postVotacion(params)
        .subscribe( (data) => {   
          this.puestoNombre = puestoNombre;
          this.mesaNombre = item.nombre;      
          if (data["logger"] == true){
            console.log(data);
            this.e14Img = data["e14Img"];
            this.testigosList = data["objTestigo"];
            this.countMesaTestigo = data["countMesaTestigo"];
            this.usuario_nombre = data["usuario_nombre"];
            // this.supervisorList = data["objSupervisor"];
            // this.countMesaSupervisor = data["countMesaSupervisor"];

            this.cargando = false;                
            }else{
              this.toastr.error(data["msg"]);
            }
        }); 
  }

  async find() {     
    let params = {
        buscar_votacion:true,
        buscar_input: this.findInput,
        et: this.user.et,
      };
        this.appService.postPuestoVotacion(params)
        .subscribe( (data) => {
            if (data["logger"] == true){
              this.puestos = data['obj']; 
            }else{
              this.toastr.error(data["msg"]);
            }
        });
  }


}
