import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
declare var $: any;

@Component({
  selector: 'app-solicitudes',
  templateUrl: './solicitudes.component.html',
  styleUrls: ['./solicitudes.component.scss']
})
export class SolicitudesComponent implements OnInit {
  public nuevaSolicitudForm: FormGroup;
  public nuevoClienteForm: FormGroup;
  public isAuthLoading = false;

  
  public user: any = null;
  detalle: any;
  buscador: any;
  listClientes: any[];

  listSolicitudes: any[];
  count_solicitudes: any;
  count_solicitudes_web: any;
  listSolicitudesWeb: any[];
  count_solicitudes_procesadas: any;
  listSolicitudesProcesadas: any[];
  listHistorial: any[];

  itemSolicitud: any ={};
  cargando: boolean =false;

  constructor( public router: Router,private toastr: ToastrService, private appService: AppService, private http: HttpClient) { }


  ngOnInit(): void {
    this.cargando = true;
    this.user = this.appService.user;
    // console.log(this.user);

    this.getListClientes();    
    this.getListSolicitudes();    

    this.nuevaSolicitudForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      celular: new FormControl(null, Validators.required),
      detalle: new FormControl(null, Validators.required),
    });

    
    this.nuevoClienteForm = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      celular: new FormControl(null, Validators.required),
    });

}


  getListClientes(){
    this.appService.getClientes(this.user.et).subscribe( (data) => {
      this.listClientes = data['clientes'].items;                 
      console.log(this.listClientes);   
        this.cargando = false;
    });
  }

  getListSolicitudes(){
    this.cargando = true;
    setTimeout(() => this.appService.getSolicitudes(this.user.et).subscribe( (data) => {
      this.count_solicitudes = data['count_solicitudes'];
      this.count_solicitudes_web = data['count_solicitudes_web'];
      this.count_solicitudes_procesadas = data['count_solicitudes_procesadas'];
      this.listSolicitudes = data['solicitudes'];                 
      this.listSolicitudesWeb = data['solicitudesWeb'];                 
      this.listSolicitudesProcesadas = data['solicitudesProcesadas'];   
      this.listHistorial = data['historial'];                 
      // console.log(this.listHistorial);      
      this.cargando = false;
  }), 300);


  }

  verSolicitud(item){
    this.itemSolicitud = item;
    console.log(this.itemSolicitud);
    $('#verSolicitud').modal('show');

  }

  cancelarSolicitud(item,estado){
      $('#verConfirmacion').modal('show');
  }

  procesarSolicitud(item,estado){
    let params = {
      editar:true,
      id: item.id,
      detalle: item.detalle,
      nota: item.nota,
      estado: estado,
      et: this.user.et,
      ut: this.user.token,      
    };
    // console.log(params);
    this.appService.postSolicitudes(params)
  .subscribe( (data) => {
      if (data["logger"] == true){
          this.toastr.success(data["msg"]);
          $('#verSolicitud').modal('hide');
          $('#verConfirmacion').modal('hide');
          this.getListSolicitudes();
      }else{
          this.toastr.error(data["msg"]);
      }
  });     
  }


  selectCliente(item){
    this.buscador = null;
    this.nuevaSolicitudForm.get('id').setValue(item.id);
    this.nuevaSolicitudForm.get('nombre').setValue(item.nombre);
    this.nuevaSolicitudForm.get('email').setValue(item.email);    
    this.nuevaSolicitudForm.get('celular').setValue(item.celular);    
  }

  createCliente(buscador){
    this.buscador = null;
    $('#crearCliente').show();
  }

  async newCliente(){     
    if (this.nuevoClienteForm.valid) {
        this.isAuthLoading = true; 
          let params = {
              nuevo:true,
              nombre: this.nuevoClienteForm.value.nombre,
              celular: this.nuevoClienteForm.value.celular,
              email: this.nuevoClienteForm.value.email,
              et: this.user.et,            
            };
            // console.log(params);
            this.appService.postClientes(params)
          .subscribe( (data) => {
              if (data["logger"] == true){
                this.nuevaSolicitudForm.get('id').setValue(data["data"].id);
                this.nuevaSolicitudForm.get('nombre').setValue(data["data"].nombre);
                this.nuevaSolicitudForm.get('email').setValue(data["data"].email);    
                this.nuevaSolicitudForm.get('celular').setValue(data["data"].celular);                
                  // console.log(data);
                  // this.router.navigate(['/']);
                  this.nuevoClienteForm.reset()
                  $('#crearCliente').hide();
                  this.getListClientes();
                  this.getListSolicitudes();
              }else{
                  this.toastr.error(data["msg"]);
              }
          }); 
        this.isAuthLoading = false;
    } else {
        this.toastr.error('El formulario no es válido!');
    }
  }

  async newSolicitud(){     
    if (this.nuevaSolicitudForm.valid) {
        this.isAuthLoading = true; 
          let params = {
              nuevo:true,
              id: this.nuevaSolicitudForm.value.id,
              nombre: this.nuevaSolicitudForm.value.nombre,
              celular: this.nuevaSolicitudForm.value.celular,
              email: this.nuevaSolicitudForm.value.email,            
              detalle: this.nuevaSolicitudForm.value.detalle,
              et: this.user.et,
              ut: this.user.token,
              estado:1,
            };
            // console.log(params);
            this.appService.postSolicitudes(params)
          .subscribe( (data) => {
              if (data["logger"] == true){
                  // console.log(data);
                  // this.router.navigate(['/']);
                  this.toastr.success(data["msg"]);
                  this.nuevaSolicitudForm.reset()
                  $('#nuevaSolicitud').modal('hide');
                  this.getListClientes();
                  this.getListSolicitudes();
              }else{
                  this.toastr.error(data["msg"]);
              }
          }); 
        this.isAuthLoading = false;
    } else {
        this.toastr.error('El formulario no es válido!');
    }
  }

}
