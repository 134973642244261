import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visas',
  templateUrl: './visas.component.html',
  styleUrls: ['./visas.component.scss']
})
export class VisasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
