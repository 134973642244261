<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-8">
                <h1>
                  Tarjeta de Reservas
                  <button class="btn btn-sm btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#nuevo"><i class="fa fa-plus"></i></button>
                </h1>
              </div>
              <!-- <div class="col-7">
                <div class="input-group mb-0">
                  <input type="text" class="form-control" [(ngModel)]="findInput" placeholder="Buscador">
                  <button class="btn btn-secondary" type="button"  id="button-addon2" (click)="findCliente()"><i class="fa fa-search"></i></button>
                </div>                  
              </div> -->
            </div>

          </div>
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">

                  <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                  <li class="breadcrumb-item active">Tarjeta de reservas</li>
              </ol>
          </div>
      </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content m-2">
  <div class="card">
    <div class="card-body table-responsive p-0">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Nit. C.C</th>
            <th scope="col">Email</th>
            <th scope="col">Contacto</th>
            <th scope="col">Estado</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <!-- <tr *ngFor="let item of clientes.items">
            <td>{{item.nombre | titlecase}}</td>
            <td>{{item.cc}}</td>
            <td>{{item.email}}</td>
            <td>{{item.celular}}</td>
            <td><span class="badge bg-success" *ngIf="item.estado ==1">Activo</span></td>
            <td>
              
              <div class="dropdown">
                <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"></button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><span class="dropdown-item"  style="cursor: pointer;" (click)="ver(item)">Ver Cliente</span></li>
                  <li><span class="dropdown-item text-danger" style="cursor: pointer;" (click)="eliminarUsuario(item)">Eliminar</span></li>
                </ul>
              </div>           

            </td>
          </tr> -->
        </tbody>
      </table>      
    </div>
  </div>

  <!-- paginador -->
  <!-- <div class="card-footer clearfix">
    <ul class="pagination pagination-sm m-0 float-right">
      <li class="page-item" *ngIf="clientes.current > 1">
        <button type="button" class="page-link" (click)="changePage(clientes.current - 1)">
          <span>Atras</span>
        </button>
      </li>                  

      <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': clientes.current == page}">
        <button type="button" class="page-link" (click)="changePage(page)">
          {{ page }}
        </button>
      </li>                  

      <li class="page-item" *ngIf="clientes.current < clientes.total_page">
        <button type="button" class="page-link" (click)="changePage(clientes.next)">
          <span>Siguiente</span>
        </button>
      </li>                  
    </ul>
    <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
      <p>Total registros encontrados: <b>{{clientes.size}}</b></p>
    </div>
  </div>               -->
  <!-- Fin paginador -->  

</section>



  <!-- Modal nuevo  -->
  <div class="modal fade" id="nuevo" tabindex="-1" aria-labelledby="nuevoLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="nuevoLabel">Tarjeta Reserva</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="card">
            <h5 class="card-header">
              <button class="btn btn-sm btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#buscarClienteModal"><i class="fa fa-plus"></i></button> Seleccione Cliente
            </h5>
            <!-- CLIENTE -->
            <div class="card-body">
              <div class="row">
                <form [formGroup]="nuevoForm" (ngSubmit)="nuevoTarjeta()">
                  <div class="row">
                      <!-- <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Actualizar Cliente</app-button>                        -->
                    <div class="col-4">
                      <div class="form-group row">
                        <label for="staticNombre" class="col-sm-4 col-form-label">Nombre</label>
                        <div class="col-sm-8">
                          <input formControlName="nombre" readonly id="staticNombre" type="text" class="form-control"/>
                        </div>
                      </div>
                    </div> 
    
                    <div class="col-4">
                      <div class="form-group row">
                        <label for="staticCc" class="col-sm-4 col-form-label">Nit. / C.C</label>
                        <div class="col-sm-8">
                          <input formControlName="cc" readonly id="staticCc" type="text" class="form-control"/>
                        </div>
                      </div>
                    </div>
    
                    <div class="col-4">
                      <div class="form-group row">
                        <label for="staticEmail" class="col-sm-3 col-form-label">Email</label>
                        <div class="col-sm-9">
                          <input formControlName="email" readonly id="staticEmail" type="text" class="form-control"/>
                        </div>
                      </div>
                    </div> 
                    
                    <div class="col-4">
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Dirección</label>
                        <div class="col-sm-8">
                          <input formControlName="direccion" readonly  type="text" class="form-control"/>
                        </div>
                      </div>
                    </div> 
                    
                    <div class="col-4">
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Telefono</label>
                        <div class="col-sm-8">
                          <input formControlName="celular" readonly  type="text" class="form-control"/>
                        </div>
                      </div>
                    </div>
              
                  </div>
                </form>                    
              </div>
            </div>           
          </div>

          <!-- PASAJEROS -->
          <div class="card">
            <h5 class="card-header"><button class="btn btn-sm btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#buscarPasajeroModal"><i class="fa fa-plus"></i></button> Pasajeros</h5>
            <div class="card-body">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">NOMBRES Y APELLIDOS</th>
                    <th scope="col">INDENTIFICACIÓN</th>
                    <th scope="col">ADL / NIÑO</th>
                    <th scope="col">FECHA NAC.</th>
                    <th scope="col">NOTA</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of selectPasajeros; let i=index" data-bs-toggle="modal" data-bs-target="#verPasajeroModal">
                    <th  scope="row">{{i+1}}</th>
                    <td>{{item.nombre | titlecase}}</td>
                    <td>{{item.cc}}</td>
                    <td class="text-center">{{item.adulto}}</td>
                    <td>{{item.fecha_nacimiento}}</td>
                    <td>{{item.nota}}</td>
                    <td><button class="btn btn-sm btn-outline-danger" (click)="deletePasajeros(i)"><i class="fa fa-trash"></i></button></td>
                  </tr>
                </tbody>
              </table>

            </div>
          </div> 
          
          
          <!-- VUELOS -->
          <div class="card">
            <h5 class="card-header"><button class="btn btn-sm btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#buscarVueloModal"><i class="fa fa-plus"></i></button> Vuelos</h5>
            <div class="card-body">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">CIA</th>
                    <th scope="col">VUELO</th>
                    <th scope="col">DE</th>
                    <th scope="col">A</th>
                    <th scope="col">CLASE</th>
                    <th scope="col">DIA</th>
                    <th scope="col">MES</th>
                    <th scope="col">SALE</th>
                    <th scope="col">LLEGA</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of selectVuelos; let i=index">
                    <th  scope="row">{{i+1}}</th>
                    <td>{{item.cia}}</td>
                    <td>{{item.vuelo}}</td>
                    <td>{{item.destino}}</td>
                    <td>{{item.origen}}</td>
                    <td>{{item.clase}}</td>
                    <td>{{item.dia}}</td>
                    <td>{{item.mes}}</td>
                    <td>{{item.sale}}</td>
                    <td>{{item.llega}}</td>   
                    <td><button class="btn btn-sm btn-outline-danger" (click)="deleteVuelos(i)"><i class="fa fa-trash"></i></button></td>                 
                  </tr>

                </tbody>
              </table>

            </div>
          </div> 

          <!-- PROVEEDORES -->
          <div class="card">
            <h5 class="card-header"><button class="btn btn-sm btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#buscarActividadModal"><i class="fa fa-plus"></i></button> Proveedores</h5>
            <div class="card-body">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">PROVEEDOR</th>
                    <th scope="col">ACTIVIDAD</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of selectProveedorActividades; let i=index">
                    <th  scope="row">{{i+1}}</th>
                    <td>{{item.proveedor | titlecase}} {{item.proveedor_ciudad | titlecase}}</td>
                    <td>{{item.nombre}}</td>
                    <td><button class="btn btn-sm btn-outline-danger" (click)="deleteProveedorActividades(i)"><i class="fa fa-trash"></i></button></td>
                  </tr>

                </tbody>
              </table>

            </div>
          </div>           
          
          <!-- ACOMODACIÓN -->
          <div class="card">
            <h5 class="card-header"><button class="btn btn-sm btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#buscarActividadModal"><i class="fa fa-plus"></i></button> Acomodación</h5>
            <div class="card-body"></div>
          </div>

          <!-- CONTACTO GENERAL -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">Contacto Nombre</label>
                    <input [(ngModel)]="contacto_nombre" type="text"class="form-control">
                  </div> 
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">Contacto Celular</label>
                    <input [(ngModel)]="contacto_celular" type="text"class="form-control">
                  </div> 
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">Seguro Viaje</label>
                    <input [(ngModel)]="seguro_viaje" type="text"class="form-control">
                  </div> 
                </div>  
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">Seguro Viaje Nota</label>
                    <input [(ngModel)]="seguro_viaje_nota" type="text"class="form-control">
                  </div> 
                </div> 
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">Referido</label>
                    <input [(ngModel)]="referido" type="text"class="form-control">
                  </div> 
                </div>                                                

              </div>              
            </div>
          </div>

          <div>
            <button class="btn btn-lg btn-primary" type="button" (click)="nuevoTarjeta()">Crear Tarjeta de Reserva</button>
          </div>         


        
        </div>
      </div>
    </div>
  </div>


  <!-- Modal buscar y crear CLIENTES -->
  <div class="modal fade" id="buscarClienteModal" tabindex="-1" aria-labelledby="buscarClienteModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Seleccione Cliente</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#nuevo" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label class="form-label">Buscar Cliente</label>
                <input [(ngModel)]="buscador" type="text"class="form-control">
              </div> 
              
              <div *ngIf="!buscador"></div>

              <ul class="list-group"*ngIf="buscador">
                <button  *ngFor="let item of listClientes | filtroCliente:buscador" type="button" class="list-group-item list-group-item-action" (click)="selectCliente(item)" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#nuevo">
                  {{ item.nombre | titlecase }}
                </button>

                <button class="list-group-item list-group-item-action text-bold mb-2" style="background:#343a40cc; color:white;font-size: 1.0em" (click)="createCliente(buscador)">
                  <i class="fa fa-user-plus"> </i> CREA UN CLIENTE.
                </button>
              </ul>
            </div>
            <form [formGroup]="nuevoClienteForm" (ngSubmit)="newCliente()">
              <div class="col-12 mt-3" id="crearCliente" style="display: none;">
                <div class="card">
                  <div class="card-body">
                    <h5>Cliente</h5>
                    <div class="form-group">
                      <input formControlName="nombre" placeholder="Nombre" type="text" class="form-control"/>
                    </div> 

                    <div class="form-group">
                      <input formControlName="cc" placeholder="Nit. / C.C" type="text" class="form-control"/>
                    </div> 

                    <div class="form-group">
                      <input formControlName="email" placeholder="Email" type="text" class="form-control"/>
                    </div> 

                    <div class="form-group">
                      <input formControlName="direccion" placeholder="Direccion" type="text" class="form-control"/>
                    </div> 

                    <div class="form-group">
                      <input formControlName="celular" placeholder="Celular" type="text" class="form-control"/>
                    </div> 
                    
                    <app-button [disabled]="!nuevoClienteForm.value.nombre || !nuevoClienteForm.value.celular || !nuevoClienteForm.value.email" [type]="'submit'" [block]="true" [loading]="isAuthLoading">
                      Crear Cliente
                  </app-button>
                  </div>
                </div>                         
              </div> 
            </form>
            <div class="col-12">
              <button type="button" class="btn btn-secondary btn-block" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#nuevo"><i class="fa fa-chevron-left"></i> Atrás</button>
            </div>
        
            
          </div>
       
        </div>
      </div>
    </div>
  </div>  


  <!-- Modal buscar y crear PASAJEROS -->
  <div class="modal fade" id="buscarPasajeroModal" tabindex="-1" aria-labelledby="buscarPasajeroModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Seleccione Pasajero</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#nuevo" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label class="form-label">Buscar Pasajero</label>
                <input [(ngModel)]="buscador" type="text"class="form-control">
              </div> 
              
              <div *ngIf="!buscador"></div>

              <ul class="list-group"*ngIf="buscador">
                <button  *ngFor="let item of listPasajeros | filtroCliente:buscador" type="button" class="list-group-item list-group-item-action" (click)="selectPasajero(item)" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#nuevo">
                  {{ item.nombre | titlecase }}
                </button>
              </ul>

              <h5>Pasajeros Agregados {{selectPasajeros.length}}</h5>
              <button *ngIf="selectPasajeros.length ==0" class="list-group-item list-group-item-action text-bold mb-2" style="background:#343a40cc; color:white;font-size: 1.0em" (click)="createPasajero(buscador)">
                <i class="fa fa-user-plus"> </i> CREA UN PASAJERO.
              </button>

              <button *ngIf="selectPasajeros.length >=1" class="list-group-item list-group-item-action text-bold mb-2" style="background:#ff9100cc; color:white;font-size: 1.0em" (click)="createPasajero(buscador)">
                <i class="fa fa-user-plus"> </i> CREA OTRO PASAJERO.
              </button>

            </div>
            <form [formGroup]="nuevoPasajeroForm" (ngSubmit)="newPasajero()">
              <div class="col-12 mt-3" id="crearPasajero" style="display: none;">
                <div class="card">
                  <div class="card-body">
                    <h5>Pasajero</h5>

                    <div class="form-group">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" value="si" formControlName="adulto"   id="flexRadioDefault1" checked/>
                        <label class="form-check-label" for="flexRadioDefault1">
                          Adulto
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" value="no" formControlName="adulto" id="flexRadioDefault2"/>
                        <label class="form-check-label" for="flexRadioDefault2">
                          Niño
                        </label>
                      </div>                       
                    </div>


                    
                    <div class="form-group">
                      <input formControlName="nombre" placeholder="Nombre" type="text" class="form-control"/>
                    </div> 

                    <div class="form-group">
                      <input formControlName="cc" placeholder="Nit. / C.C" type="text" class="form-control"/>
                    </div>
                    
                                       

                    <div class="form-group">
                      <input formControlName="fecha_nacimiento" placeholder="fecha nacimiento" type="date" class="form-control"/>
                    </div> 

                    <div class="form-group">
                      <input formControlName="nota" placeholder="Nota" type="text" class="form-control"/>
                    </div> 

                    
                    <app-button [disabled]="!nuevoPasajeroForm.value.adulto || !nuevoPasajeroForm.value.nombre || !nuevoPasajeroForm.value.cc || !nuevoPasajeroForm.value.fecha_nacimiento" [type]="'submit'" [block]="true" [loading]="isAuthLoading">
                      Crear Pasajero
                  </app-button>
                  </div>
                </div>                         
              </div> 
            </form>
            <div class="col-12">
              <button type="button" class="btn btn-secondary btn-block" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#nuevo"><i class="fa fa-chevron-left"></i> Atrás</button>
            </div>
        
            
          </div>
       
        </div>
      </div>
    </div>
  </div>  
  
  
  <!-- Modal buscar y VER PASAJERO -->
  <div class="modal fade" id="verPasajeroModal" tabindex="-1" aria-labelledby="verPasajeroModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Pasajero</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#nuevo" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <form [formGroup]="editPasajeroForm" (ngSubmit)="editPasajero()">
              <div class="col-12 mt-3" id="crearPasajero" style="display: none;">
                <div class="card">
                  <div class="card-body">
                    <h5>Pasajero</h5>

                    <div class="form-group">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" value="si" formControlName="adulto"   id="flexRadioDefault1" checked/>
                        <label class="form-check-label" for="flexRadioDefault1">
                          Adulto
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" value="no" formControlName="adulto" id="flexRadioDefault2"/>
                        <label class="form-check-label" for="flexRadioDefault2">
                          Niño
                        </label>
                      </div>                       
                    </div>


                    
                    <div class="form-group">
                      <input formControlName="nombre" placeholder="Nombre" type="text" class="form-control"/>
                    </div> 

                    <div class="form-group">
                      <input formControlName="cc" placeholder="Nit. / C.C" type="text" class="form-control"/>
                    </div>
                    
                                       

                    <div class="form-group">
                      <input formControlName="fecha_nacimiento" placeholder="fecha nacimiento" type="date" class="form-control"/>
                    </div> 

                    <div class="form-group">
                      <input formControlName="nota" placeholder="Nota" type="text" class="form-control"/>
                    </div> 

                    
                    <app-button [disabled]="!nuevoPasajeroForm.value.adulto || !nuevoPasajeroForm.value.nombre || !nuevoPasajeroForm.value.cc || !nuevoPasajeroForm.value.fecha_nacimiento" [type]="'submit'" [block]="true" [loading]="isAuthLoading">
                      Crear Pasajero
                  </app-button>
                  </div>
                </div>                         
              </div> 
            </form>
            <div class="col-12">
              <button type="button" class="btn btn-secondary btn-block" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#nuevo"><i class="fa fa-chevron-left"></i> Atrás</button>
            </div>
        
            
          </div>
       
        </div>
      </div>
    </div>
  </div>    


  <!-- Modal buscar y crear VUELOS -->
  <div class="modal fade" id="buscarVueloModal" tabindex="-1" aria-labelledby="buscarVueloModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Agregar Vuelo</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#nuevo" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              
              <!-- <div class="form-group">
                <label class="form-label">Buscar Pasajero</label>
                <input [(ngModel)]="buscador" type="text"class="form-control">
              </div> 
              
              <div *ngIf="!buscador"></div> -->

              <!-- <ul class="list-group"*ngIf="buscador">
                <button  *ngFor="let item of listIata | filtroCliente:buscador" type="button" class="list-group-item list-group-item-action" (click)="selectPasajero(item)" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#nuevo">
                  {{ item.nombre | titlecase }}
                </button>
              </ul> -->
  

              <form [formGroup]="nuevoVueloForm" (ngSubmit)="newVuelo()">
                <div class="col-12 mt-3" id="crearVuelo" style="display: block;">
                  <div class="card">
                    <div class="card-body">
                      <h5>Vuelos</h5>
                      <div class="row">
                        <div class="col-md-10">
                          <div class="row">
                            <div class="col-md-3">
                              <div class="form-group">
                                <input formControlName="cia" placeholder="CIA" type="text" class="form-control"/>
                              </div> 
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <input formControlName="vuelo" placeholder="Vuelo" type="text" class="form-control"/>
                              </div>
                            </div>
    
                            <div class="col-md-3">
                              <div class="form-group">
                                <input formControlName="dia" placeholder="Día" type="text" class="form-control"/>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <input formControlName="mes" placeholder="Mes" type="text" class="form-control"/>
                              </div>
                            </div> 
    
                          </div>
                          <div class="row">
                            <div class="col-md-2">
                              <div class="form-group">
    
                                <input formControlName="origen" placeholder="Origen" type="text" class="form-control"/>
                                <ul class="list-group" *ngIf="nuevoVueloForm.value.origen" id="iataOrigen" style="z-index: 10000;position: absolute;width: 160px;">
                                  <button  *ngFor="let item of listIata | filtroIata:nuevoVueloForm.value.origen" type="button" class="list-group-item list-group-item-action" (click)="selectVueloOrigen(item)">
                                    {{ item.codigo }}
                                  </button>
                                </ul>                            
                              </div> 
                            </div>
                            <div class="col-md-2">
                              <div class="form-group">
                                <input formControlName="sale" placeholder="Sale" type="time" class="form-control"/>
                              </div>
                            </div> 
    
                            <div class="col-md-2">
                              <div class="form-group">
                                <input formControlName="destino" placeholder="Destino" type="text" class="form-control"/>
                                <ul class="list-group" *ngIf="nuevoVueloForm.value.destino" id="iataDestino" style="z-index: 10000;position: absolute;width: 160px;">
                                  <button  *ngFor="let item of listIata | filtroIata:nuevoVueloForm.value.destino" type="button" class="list-group-item list-group-item-action" (click)="selectVueloDestino(item)">
                                    {{ item.codigo }}
                                  </button>
                                </ul>                              
                              </div>                           
                            </div>
    
                            <div class="col-md-2">
                              <div class="form-group">
                                <input formControlName="llega" placeholder="Llega" type="time" class="form-control"/>
                              </div>
                            </div>  
    
                            <div class="col-md-2">
                              <div class="form-group">
                                <input formControlName="clase" placeholder="Clase" type="text" class="form-control"/>
                              </div>
                            </div>

                          </div>

                        </div>
                        <div class="col-md-2">
                          <app-button [disabled]="!nuevoVueloForm.value.cia || !nuevoVueloForm.value.origen || !nuevoVueloForm.value.destino" [type]="'submit'" [block]="true" [loading]="isAuthLoading">
                            Agregar Vuelo
                          </app-button>
                        </div>
                                                                                              
                      </div>
                                      
                      

                    </div>
                  </div>                         
                </div> 
              </form>              

            </div>

            <div class="col-12">
              <button type="button" class="btn btn-secondary btn-block" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#nuevo"><i class="fa fa-chevron-left"></i> Atrás</button>
            </div>
        
            
          </div>
        
        </div>
      </div>
    </div>
  </div>  

  <!-- Modal buscar PROVEEDOR ACTIVIDAD -->
  <div class="modal fade" id="buscarActividadModal" tabindex="-1" aria-labelledby="buscarActividadModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Agregar Actividad</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#nuevo" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              
              <div class="form-group">
                <label class="form-label">Buscar Actividad</label>
                <input [(ngModel)]="buscador" type="text"class="form-control">
              </div> 
              
              <div *ngIf="!buscador"></div> 

              <ul class="list-group"*ngIf="buscador">
                <button  *ngFor="let item of listProveedoresActividades | filtroProveedorActividad:buscador" type="button" class="list-group-item list-group-item-action" (click)="selectProveedorActividad(item)" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#nuevo">
                  <div class="row">
                    <div class="col-md-12">{{ item.nombre | titlecase }}</div>                    
                    <div class="col-md-12"><small>Proveedor: {{item.proveedor | titlecase}} - {{item.proveedor_ciudad | titlecase}}</small></div>
                  </div>
                </button>
              </ul>
  

              <!-- <form [formGroup]="nuevoVueloForm" (ngSubmit)="newVuelo()">
                <div class="col-12 mt-3" id="crearVuelo" style="display: block;">
                  <div class="card">
                    <div class="card-body">
                      <h5>Vuelos</h5>
                      <div class="row">
                        <div class="col-md-10">
                          <div class="row">
                            <div class="col-md-3">
                              <div class="form-group">
                                <input formControlName="cia" placeholder="CIA" type="text" class="form-control"/>
                              </div> 
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <input formControlName="vuelo" placeholder="Vuelo" type="text" class="form-control"/>
                              </div>
                            </div>
    
                            <div class="col-md-3">
                              <div class="form-group">
                                <input formControlName="dia" placeholder="Día" type="text" class="form-control"/>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <input formControlName="mes" placeholder="Mes" type="text" class="form-control"/>
                              </div>
                            </div> 
    
                          </div>
                          <div class="row">
                            <div class="col-md-2">
                              <div class="form-group">
    
                                <input formControlName="origen" placeholder="Origen" type="text" class="form-control"/>
                                <ul class="list-group" *ngIf="nuevoVueloForm.value.origen" id="iataOrigen" style="z-index: 10000;position: absolute;width: 160px;">
                                  <button  *ngFor="let item of listIata | filtroIata:nuevoVueloForm.value.origen" type="button" class="list-group-item list-group-item-action" (click)="selectVueloOrigen(item)">
                                    {{ item.codigo }}
                                  </button>
                                </ul>                            
                              </div> 
                            </div>
                            <div class="col-md-2">
                              <div class="form-group">
                                <input formControlName="sale" placeholder="Sale" type="time" class="form-control"/>
                              </div>
                            </div> 
    
                            <div class="col-md-2">
                              <div class="form-group">
                                <input formControlName="destino" placeholder="Destino" type="text" class="form-control"/>
                                <ul class="list-group" *ngIf="nuevoVueloForm.value.destino" id="iataDestino" style="z-index: 10000;position: absolute;width: 160px;">
                                  <button  *ngFor="let item of listIata | filtroIata:nuevoVueloForm.value.destino" type="button" class="list-group-item list-group-item-action" (click)="selectVueloDestino(item)">
                                    {{ item.codigo }}
                                  </button>
                                </ul>                              
                              </div>                           
                            </div>
    
                            <div class="col-md-2">
                              <div class="form-group">
                                <input formControlName="llega" placeholder="Llega" type="time" class="form-control"/>
                              </div>
                            </div>  
    
                            <div class="col-md-2">
                              <div class="form-group">
                                <input formControlName="clase" placeholder="Clase" type="text" class="form-control"/>
                              </div>
                            </div>

                          </div>

                        </div>
                        <div class="col-md-2">
                          <app-button [disabled]="!nuevoVueloForm.value.cia || !nuevoVueloForm.value.origen || !nuevoVueloForm.value.destino" [type]="'submit'" [block]="true" [loading]="isAuthLoading">
                            Agregar Vuelo
                          </app-button>
                        </div>                                                                                              
                      </div>
                                                            
                    </div>
                  </div>                         
                </div> 
              </form>               -->

            </div>

            <div class="col-12">
              <button type="button" class="btn btn-secondary btn-block" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#nuevo"><i class="fa fa-chevron-left"></i> Atrás</button>
            </div>
        
            
          </div>
        
        </div>
      </div>
    </div>
  </div>    


  <!-- Modal Nueva  -->
  <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNuevaUsuario" aria-labelledby="offcanvasNuevaUsuarioLabel">
    <div class="offcanvas-header">
      <h5 id="offcanvasRightLabel">Nuevo Cliente</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">

      <!-- <form [formGroup]="nuevoForm" (ngSubmit)="newCliente()">
        <div class="col-12 mt-3" id="crearCliente">
              <div class="form-group">
                <input formControlName="nombre" placeholder="Nombre" type="text" class="form-control"/>
              </div> 

              <div class="form-group">
                <input formControlName="cc" placeholder="NIT. / C.C" type="text" class="form-control"/>
              </div>              
              
              <div class="form-group">
                <input formControlName="email" placeholder="Email" type="text" class="form-control"/>
              </div> 

              <div class="form-group">
                <input formControlName="celular" placeholder="Celular Contacto" type="text" class="form-control"/>
              </div> 
                            
              <div class="form-group">
                <input formControlName="direccion" placeholder="Dirección" type="text" class="form-control"/>
              </div>                           

              <div class="form-group">
                <input formControlName="ciudad" placeholder="Ciudad" type="text" class="form-control"/>
              </div> 

              <div class="form-group">
                <textarea formControlName="nota" class="form-control" placeholder="Nota" rows="5"></textarea>
              </div>               

              <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Crear Cliente</app-button>                       
        </div> 
      </form> -->
    </div>
  </div>
