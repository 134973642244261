<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark"><i class="fas fa-tachometer-alt"></i>  Operaciones</h1>
            </div>
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <!-- Small boxes (Stat box) -->
        <div class="row">

            <div class="col-lg-3 col-6" *ngIf="user.perfil =='15kwqgk3123'">
                <!-- small box -->
                <div class="small-box bg-info" [routerLink]="['/empresas']">
                    <div class="inner">
                        <h3>{{estadisticas.countEmpresa}}</h3>
                        <p>Empresas</p>
                    </div>
                    <div class="icon">
                        <i class="fa fa-building"></i>
                    </div>
                    <div class="small-box-footer"></div>
                </div>
            </div>

            <div class="col-lg-3 col-6" *ngIf="user.perfil =='15kwqgk3123'">
                <!-- small box -->
                <div class="small-box bg-warning">
                    <div class="inner">
                        <h3>{{estadisticas.countUsuariosAll}}</h3>
                        <p>Usuarios Registrados</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-person-add"></i>
                    </div>
                    <div class="small-box-footer"></div>
                </div>
            </div>

            <div class="col-lg-3 col-6" *ngIf="user.perfil =='2fk6i2o3143'">
                <!-- small box -->
                <div class="small-box bg-info">
                    <div class="inner">
                        <h3>{{estadisticas.countVotosAll}}</h3>
                        <p>Votación recibida</p>
                    </div>
                    <div class="icon">
                        <i class="fa fa-vote-yea"></i>
                    </div>
                    <div class="small-box-footer"></div>
                </div>
            </div>
            
            <div class="col-lg-3 col-6" *ngIf="user.perfil =='2fk6i2o3143'">
                <!-- small box -->
                <div class="small-box bg-warning">
                    <div class="inner">
                        <h3>{{estadisticas.countPuestoVotacion}}</h3>
                        <p>Puesto Votación</p>
                    </div>
                    <div class="icon">
                        <i class="fas fa-couch"></i>
                    </div>
                    <div class="small-box-footer"></div>
                </div>
            </div>

            <div class="col-lg-3 col-6" *ngIf="user.perfil =='2fk6i2o3143'">
                <!-- small box -->
                <div class="small-box bg-primary">
                    <div class="inner">
                        <h3>{{estadisticas.countUsuariosAll}}</h3>
                        <p>Usuarios</p>
                    </div>
                    <div class="icon">
                        <i class="fas fa-users"></i>
                    </div>
                    <div class="small-box-footer"></div>
                </div>
            </div> 

            <div class="col-lg-3 col-6" *ngIf="user.perfil =='2fk6i2o3143'">
                <!-- small box -->
                <div class="small-box bg-light">
                    <div class="inner">
                        <h3>{{estadisticas.countCandidatos}}</h3>
                        <p>Candidatos</p>
                    </div>
                    <div class="icon">
                        <i class="fas fa-user-tie"></i>
                    </div>
                    <div class="small-box-footer"></div>
                </div>
            </div>  
            
            <div class="col-lg-3 col-6" *ngIf="user.perfil =='3af3s12f153'">
                <!-- small box -->
                <div class="small-box bg-success">
                    <div class="inner">
                        <h3>{{estadisticas.count_clientes}}</h3>
                        <p>Clientes</p>
                    </div>
                    <div class="icon">
                        <i class="fas fa-users"></i>
                    </div>
                    <div class="small-box-footer"></div>
                </div>
            </div>              

            <div class="col-lg-3 col-6" *ngIf="user.perfil =='2fk6i2o3143'">
                <!-- small box -->
                <div class="small-box bg-success">
                    <div class="inner">
                        <h3>Android</h3>
                        <p><a href="https://play.google.com/store/apps/details?id=io.diae.sd" target="_blank" style="color: #fff;">Descargar</a></p>
                    </div>
                    <div class="icon">
                        <i class="fa fa-mobile"></i>
                    </div>
                    <div class="small-box-footer"></div>
                </div>
            </div>   

            <div class="col-12" *ngIf="user.perfil =='5AFC4A35f555'">
                <app-mapa-escrutinio></app-mapa-escrutinio>
            </div>            
                        
        </div>
        <!-- /.row -->
    </div>
    <!-- Main row -->
</section>
<!-- /.content -->
