import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

declare var $: any;

@Component({
  selector: 'app-inscripcion',
  templateUrl: './inscripcion.component.html',
  styleUrls: ['./inscripcion.component.scss']
})
export class InscripcionComponent implements OnInit {
  documento: any;
  public user: any = null;
  countHabilitados= 0;
  countDuplicados= 0;
  countErrados= 0;
  datos: any = [];
  ultimosDatos: any = [];
  exporData: any = [];
  recolectores: any = [];
  recolectoresList: any = [];
  selectRecolector: any;
  selectRecolectorNombre: any;
  buscarRecolector: any;

  public nuevoForm: FormGroup;
  public isAuthLoading = false;

  constructor(private appService: AppService, private http: HttpClient,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.user = this.appService.user;
    this.status();
    this.ultimos();
    this.getListRecolectores();


    this.nuevoForm = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      celular: new FormControl(null, Validators.required),
    });   
    

  }
 
  status(){
      let params = {
        status: true,
        et: this.user.et,
      };
      this.appService.postInscripcionPuestoVotacion(params)
        .subscribe((data) => {
          if (data["logger"] == true) {
            this.countHabilitados = data['countHabilitados'];
            this.countDuplicados = data['countDuplicados'];
            this.countErrados = data['countErrados'];
            
          } else {
            this.datos.error(data["msg"]);
          }
        });    
  }

  findRecolector(){
    this.isAuthLoading = true; 
    let params = {
      buscar_recolector: true,
      nombre: this.buscarRecolector,
      et: this.user.et,
    };
    this.appService.postInscripcionRecolecotres(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.recolectoresList = data['obj'];
          // console.log(this.recolectoresList);
        } else {
          this.datos.error(data["msg"]);
        }
        this.isAuthLoading = false; 
      });    
  }  

  getListRecolectores(){
    let params = {
      recolectores: true,
      id: this.user.uid,
      et: this.user.et,
    };
    this.appService.postInscripcionRecolecotres(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.recolectores = data['obj'];
          // console.log(this.recolectores);
        } else {
          this.datos.error(data["msg"]);
        }
      });    
  }  

  ultimos(){
    let params = {
      ultimos: true,
      id: this.user.uid,
      et: this.user.et,
    };
    this.appService.postInscripcionPuestoVotacion(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.ultimosDatos = data['obj'];
          console.log(this.ultimosDatos);
        } else {
          this.datos.error(data["msg"]);
        }
      });    
  }  

  buscarDocumento(){
    // console.log(this.documento);
    if(this.documento){
      let params = {
        buscar_votacion: true,
        documento: this.documento,
        et: this.user.et,
      };
      this.appService.postInscripcionPuestoVotacion(params)
        .subscribe((data) => {
          if (data["logger"] == true) {
            this.datos = data['obj'];
            // console.log(this.datos);
            
          } else {
            this.datos.error(data["msg"]);
          }
        });    
    }else{
      this.toastr.error("digite un documento valido.");
    }
  }

  agregar(estado){
    // console.log(this.documento);
    if(this.documento){
      let params = {
        nuevo: true,
        documento: this.datos.cedula,
        puesto: this.datos.puesto,
        nombre1: this.datos.nombre1,
        nombre2: this.datos.nombre2,
        apellido1: this.datos.apellido1,
        apellido2: this.datos.apellido2,
        fchn: this.datos.fchn,
        fche: this.datos.fche,
        sexo: this.datos.sexo,
        estado: estado,
        recolector_id: this.selectRecolector,
        id: this.user.uid,
        et: this.user.et,
      };
      this.appService.postInscripcionPuestoVotacion(params)
        .subscribe((data) => {
          if (data["logger"] == true) {
            this.documento = null;
            this.datos = [];
            // this.getListRecolectores();
            this.ultimos();
            this.toastr.success(data["msg"]);
          } else {
            this.toastr.error(data["msg"]);
          }
        });    
    }else{
      this.toastr.error("digite un documento valido.");
    }    
  }

  async nuevoRecolector() {     
    if (this.nuevoForm.valid) {
        this.isAuthLoading = true; 
          let params = {
              nuevo:true,
              nombre: this.nuevoForm.value.nombre,
              celular: this.nuevoForm.value.celular,
              estado: 1,
              et: this.user.et,
            };
            // console.log(params);
            this.appService.postInscripcionRecolecotres(params)
          .subscribe( (data) => {
              if (data["logger"] == true){
                  this.nuevoForm.reset()
                  $('#nuevo').modal('hide');
                  // this.getListRecolectores();
              }else{
                  this.toastr.error(data["msg"]);
              }
          }); 
        this.isAuthLoading = false;
    } else {
        this.toastr.error('El formulario no es válido!');
    }
  }

  exportAll(){
    let params = {
      export: true,
      et: this.user.et,
    };
    this.appService.postInscripcionPuestoVotacion(params)
      .subscribe((data) => {
        if (data["logger"] == true) {

          let timerInterval
          Swal.fire({
            title: 'Generando Archivo!',
            html: 'Por favor espere<b></b>.',
            timer: 1000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading()
              const b = Swal.getHtmlContainer().querySelector('b')
              timerInterval = setInterval(() => {
                // b.textContent = Swal.getTimerLeft()
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
      
              this.exporData = data['obj'];
              // console.log(data['obj']);
            
              const fileName = 'export_inscripcion_.xlsx';
              // const fileName = 'curso_' + this.obj.fecha_inicio + '.csv';
              const myHeader = ["logger","cedula", "pusto", "nombre1", "nombre2", "apellido1", "apellido2", "fchn", "fche", "sexo", "recolector_id", "estado", "inscripcion_habilitados_at"];
    
              const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.exporData);
    
              // ANCHO COLUMNAS
              ws['!cols'] = [{ width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }];
              // ELIMINA COLUMNA1
              ws['!cols'][0] = { hidden: true };
              // ws['!cols'][1] = { hidden: true };
              // ws['!cols'][16] = { hidden: true };
              // ws['!cols'][17] = { hidden: true };
              // ws['!cols'][18] = { hidden: true };
    
              const wb: XLSX.WorkBook = XLSX.utils.book_new();
              // CAMBIA CABECERA
              XLSX.utils.sheet_add_aoa(ws, [["","CEDULA", "PUESTO", "PRIMER NOMBRE", "SEGUNDO NOMBRE", "PRIMER APELLIDO", "SEGUNDO APELLIDO", "FECHA NACIMIENTO", "FECHA EXPEDICION", "GENERO", "RECOLECTOR", "ESTADO", "FECHA REGISTRO"]]);
              // XLSX.utils.sheet_add_json(ws, this.exporData, { origin: 'A2', header: myHeader, skipHeader: true });
    
    
              XLSX.utils.book_append_sheet(wb, ws, 'reporte');
              XLSX.writeFile(wb, fileName);              

      
            }
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              console.log('temporizador cerrado')
            }
          })


        } else {
          this.datos.error(data["msg"]);
        }
      });    
  }  

}
