<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h1><i class="fas fa-user-tie"></i>  Candidatos <button class="btn btn-sm btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#nuevo"><i class="fa fa-plus"></i></button></h1>
          </div>
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                  <li class="breadcrumb-item active">Candidatos</li>
              </ol>
          </div>
      </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content m-2">
  <div class="row">
    <div *ngFor="let item of candidatos.items" class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
      <div class="card bg-white d-flex flex-fill">
        <div class="card-header bg-white text-muted border-bottom-0">
          <b><span *ngIf="item.perfil_id ==4">Testigo</span> <span *ngIf="item.perfil_id ==3">Supervisor</span> <span *ngIf="item.perfil_id ==2">Administrador</span></b>
        </div>
        <div class="card-body pt-0" data-bs-toggle="modal" data-bs-target="#verUsuario" (click)="verUsuario(item)" style="cursor:pointer">
          <div class="row">
            <div class="col-7">
              <h2 class="lead"><b>{{item.nombre | titlecase}}</b></h2>
              <h6 class=""><b>{{item.partido | titlecase}}</b></h6>
              <h6 class=""><b>{{item.color}}</b></h6>
            </div>
            <div class="col-5 text-center">
              <img *ngIf="item.fotografia_url" width="62px"
              [src]="item.fotografia_url"
              class="user-image img-circle elevation-2 bg-body"
              alt="User Image"/>               
            </div>
          </div>
        </div>
        <div class="card-footer bg-white" [style.background-color]="item.color + ' !important'" >
          <div class="text-right">
            <button type="button" class="btn btn-sm btn-warning mr-1" data-bs-toggle="modal" data-bs-target="#verUsuario" (click)="verUsuario(item)"><i class="fas fa-user-tie"></i> Ver Candidato</button>
            <button type="button" class="btn btn-sm btn-secondary" (click)="eliminarUsuario(item)"><i class="fa fa-trash"></i></button>

          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- <div class="card">
    <div class="card-body table-responsive p-0">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Nombre</th>
            <th scope="col">Email</th>
            <th scope="col">Perfil</th>
            <th scope="col">Estado</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of candidatos.items">
            <td>
              <img *ngIf="item.fotografia_url" width="36px"
              [src]="item.fotografia_url"
              class="user-image img-circle elevation-2"
              alt="User Image"/>              
            </td>
            <td>{{item.nombre | titlecase}}</td>
            <td>{{item.email}}</td>
            <td><span *ngIf="item.perfil_id ==3">Asesor</span> <span *ngIf="item.perfil_id ==2">Administrador</span></td>
            <td><span class="badge bg-success" *ngIf="item.estado ==1">Activo</span></td>
            <td>
              
              <div class="dropdown">
                <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"></button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><span class="dropdown-item" data-bs-toggle="modal" data-bs-target="#verUsuario" style="cursor: pointer;" (click)="verUsuario(item)">Ver Usuario</span></li>
                  <li><span class="dropdown-item text-danger" style="cursor: pointer;" (click)="eliminarUsuario(item)">Eliminar</span></li>
                </ul>
              </div>           

            </td>
          </tr>
        </tbody>
      </table>      
    </div>
  </div> -->

  <!-- paginador -->
  <div class="card-footer clearfix">
    <ul class="pagination pagination-sm m-0 float-right">
      <li class="page-item" *ngIf="candidatos.current > 1">
        <button type="button" class="page-link" (click)="changePage(candidatos.current - 1)">
          <span>Atras</span>
        </button>
      </li>                  

      <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': candidatos.current == page}">
        <button type="button" class="page-link" (click)="changePage(page)">
          {{ page }}
        </button>
      </li>                  

      <li class="page-item" *ngIf="candidatos.current < candidatos.total_page">
        <button type="button" class="page-link" (click)="changePage(candidatos.next)">
          <span>Siguiente</span>
        </button>
      </li>                  
    </ul>
    <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
      <p>Total registros encontrados: <b>{{candidatos.size}}</b></p>
    </div>
  </div>              
  <!-- Fin paginador -->  

</section>


  <!-- Modal Nueva  -->
  <div class="modal fade" id="nuevo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Nuevo Candidato</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">

            <form [formGroup]="nuevoForm" (ngSubmit)="newUsuario()">
              <div class="col-12 mt-3" id="crearCliente">

                <div class="col-12 mb-3 text-center">
                  <!-- <div class="user-image img-circle elevation-2 img-fluid" 
                    (click)="file.click()"
                    [ngStyle]="{'background-image':' url(nuevoForm.value.fotografia_url || https://api.germancasagua.com/img/upload/candidatos/default.png)','height':'250px','width':'250px','background-size':'cover','background-position':'center','cursor':'pointer','margin-left':'auto','margin-right': 'auto'}">
                  </div> -->

                  <span *ngIf="nuevoForm.value.fotografia_url">
                    <div class="user-image img-circle elevation-2 img-fluid"  
                      (click)="file.click()"
                      [ngStyle]="{'background-image':' url(' + nuevoForm.value.fotografia_url + ')','height':'250px','width':'250px','background-size':'cover','background-position':'center','cursor':'pointer','margin-left':'auto','margin-right': 'auto'}">
                    </div>
                  </span>
                  
                  <span *ngIf="!nuevoForm.value.fotografia_url">
                    <div class="user-image img-circle elevation-2 img-fluid"  
                      (click)="file.click()"
                      [ngStyle]="{'background-image':' url(https://api.germancasagua.com/img/upload/candidatos/default.png)','height':'250px','width':'250px','background-size':'cover','background-position':'center','cursor':'pointer','margin-left':'auto','margin-right': 'auto'}">
                    </div>
                  </span>

                  <!-- <img
                  (click)="file.click()"
                  [src]="nuevoForm.value.fotografia_url || 'https://api.germancasagua.com/img/upload/candidatos/default.png'"
                  class="user-image img-circle elevation-2 img-fluid"
                  alt="User Image"
                  style="cursor: pointer;"
                  width="250px"/> -->

                  <div *ngIf="loadingImg" class="spinner-border" style="width: 3rem; height: 3rem;position: absolute;left: 45%;top: 40%;" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>

                  <input #file type="file" accept='image/*' (change)="preview(file.files,'nuevo')" style="display: none"/>                          

                </div>

                    <div class="form-group">
                      <input formControlName="nombre" placeholder="Nombre" type="text" class="form-control"/>
                    </div> 
                    
                    <div class="form-group">
                      <input formControlName="partido" placeholder="Partido" type="text" class="form-control"/>
                    </div> 


                    <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Crear Candidato</app-button>                       
              </div> 

            </form>
                    
          </div>
       
        </div>
      </div>
    </div>
  </div>


  <!-- Modal Ver  -->
  <div class="modal fade" id="verUsuario" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Candidato</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">

            <form [formGroup]="editForm" (ngSubmit)="editUsuario()">
              <div class="row">
                <div class="col-3 mt-3">

                  <img
                  (click)="fileEditar.click()"
                  [src]="editForm.value.fotografia_url || 'https://api.germancasagua.com/img/upload/candidatos/default.png'"
                  class="user-image img-circle elevation-2 img-fluid"
                  alt="User Image"
                  style="cursor: pointer;"
                  width="250px"
                  height="250px"/>                

                  <div *ngIf="loadingImg" class="spinner-border text-secondary" style="width: 3rem; height: 3rem;position: absolute;left: 42%;top: 40%;" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>

                  <input #fileEditar type="file" accept='image/*' (change)="preview(fileEditar.files,'editar')" style="display: none"/>                          

                </div>
                <div class="col-9 mt-3">
                    <div class="form-floating mb-3">
                      <input  formControlName="nombre" type="text" class="form-control" id="floatingInput" placeholder="Nombre">
                      <label for="floatingInput">Nombre</label>
                    </div>

                    <div class="row">
                      <div class="col-8">
                        <div class="form-floating mb-3">
                          <input  formControlName="partido" type="text" class="form-control" id="floatingInput" placeholder="Partido">
                          <label for="floatingInput">Partido</label>
                        </div>                        
                      </div>
                      <div class="col-4"><input type="color" class="form-control form-control-color" id="exampleColorInput" formControlName="color" title="Choose your color">
                      {{editForm.value.color}}</div>
                    </div>                        
                      <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Actualizar Usuario</app-button>                       
                </div> 
              </div>

            </form>
                    
          </div>
        
        </div>
      </div>
    </div>
  </div>
