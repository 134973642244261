<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#general" type="button"
      role="tab" aria-controls="general" aria-selected="true">General</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#puestos_votacion" type="button"
      role="tab" aria-controls="puestos_votacion" aria-selected="false">Puestos de votacion <i
        class="fa fa-vote-yea"></i></button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="mapa-tab" data-bs-toggle="tab" data-bs-target="#mapa_votacion" type="button" role="tab"
      aria-controls="mapa_votacion" aria-selected="false">Mapa <i class="fa fa-traffic-light"></i></button>
  </li>
</ul>

<div class="tab-content" id="myTabContent">
  <!-- TAB GENERAL -->
  <div class="tab-pane fade show active  bg-body" id="general" role="tabpanel" aria-labelledby="general-tab">

  

    <div class="text-right pr-2 pt-1 pb-1">
      <!-- <button class="btn btn-sm btn-outline-secondary mr-1" (click)="stopTimer()"><i class="fa fa-chart-pie"></i></button>
      <button class="btn btn-sm btn-outline-secondary mr-1" (click)="stopTimer()"><i class="fa fa-chart-bar"></i></button> -->

      <button *ngIf="!barraCandidatoStatus" type="button" class="btn btn-sm btn-warning mr-1" (click)="barraCandidatoStatus =true"><i class="fa fa-users"></i> </button>
      <button *ngIf="barraCandidatoStatus" type="button" class="btn btn-sm btn-primary mr-1" (click)="barraCandidatoStatus =false"><i class="fa fa-users"></i> </button>
      <button type="button" class="btn btn-sm btn-secondary" (click)="getListCountGeneral()"><i class="fa fa-sync"></i> Actualizar</button>

      <button class="btn btn-sm btn-outline-secondary" (click)="stopTimer()">Detener</button>
      <button [ngClass]="{ active: timerSelect == 10000 }" class="btn btn-sm btn-outline-secondary" type="button"
        (click)="startTimer(10000)">10 seg</button>
      <button [ngClass]="{ active: timerSelect == 20000 }" class="btn btn-sm btn-outline-secondary" type="button"
        (click)="startTimer(20000)">20 seg</button>
      <button [ngClass]="{ active: timerSelect == 60000 }" class="btn btn-sm btn-outline-secondary" type="button"
        (click)="startTimer(60000)">60 seg</button>

    </div>
    <div class="card">
      <div *ngIf="barraCandidatoStatus" style="width: 18rem;position: absolute;top:0%;right:1%;" id="barraCandidatos">
        <div class="card" *ngFor="let item of chartGeneralData" style="margin-bottom: 5px;">
          <div class="card-body">
            <div class="row">
              <div class="col-md-2">
                <img *ngIf="item.fotografia_url" width="" [src]="item.fotografia_url"
                  class="img-fluid user-image img-circle elevation-2" alt="User Image" />
              </div>
              <div class="col-md-6">
                <h4 style="font-size: 0.7em;font-weight: 400;">{{item.nombre | titlecase}}</h4>
                <!-- <h4 style="font-size: 0.6em;font-weight: 400;">{{item.partido | titlecase}}</h4> -->
              </div>
              <div class="col-md-4">
                <h6 style="font-size: 1em;">{{item.porcentaje}}%</h6>
                <h3 style="font-size: 0.8em;">{{item.total}}</h3>
              </div>
            </div>
            <div class="progress mt-2" style="height: 4px;">
              <div class="progress-bar" role="progressbar" [style.width]="item.porcentaje+'%'"
                [attr.aria-valuenow]="item.porcentaje" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
      </div>

      <div style="min-height: 600px;" *ngIf="chartGeneralCountAll == 0">
        <span style="width: 22rem;position: absolute;top:15%;left:50%"  >
          <h1 class="text-center mt-5 mb-2"><i class="fa fa-vote-yea"></i><br> No hay registro de votos disponibles</h1>
        </span>
        
        <div style="width: 22rem;position: absolute;top:50%;left:50%" class="card-content text-center">
          <p>La gráfica que muestra información del proceso general de votos se genera cuando hay registor de votos mediante la aplicacion movil.</p>
        </div>      
      </div>
      
      <span>
        <canvas id="generalChart">{{ chartGeneral }}</canvas>
      </span>

      <div *ngIf="chartGeneralCountAll > 0" class="card-content text-center mt-2">
        <h1>{{chartGeneralCountAll}}</h1>
        <p>Esta gráfica muestra información del proceso general de votos reportados a la fecha.</p>
      </div>


      <span class="mt-5">
        <div class="row">
          <!-- <div class="col-4">
            <div class="col-md-6">
              <div class="card" style="width: 30rem;margin-left: auto;">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-8">
                      <h3 style="font-size: 1.2em;">Reporte Votacion general </h3>
                    </div>
                  </div>
      
                  <div class="card" *ngFor="let item of resultadoGeneralList">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-2">
                          <img width="62px" [src]="item.fotografia_url" class="user-image img-circle elevation-2"
                            alt="User Image" />
                        </div>
                        <div class="col-md-7">
                          <h5>{{item.nombre | titlecase}}</h5>
                          <h6 style="font-weight: 300;">{{item.partido | titlecase}}</h6>
                        </div>
                        <div class="col-md-3 text-right">
                          <h3>{{item.votos}}</h3>
                          <h6>{{item.porcentaje}}%</h6>
                        </div>
                        <div class="progress mt-2" style="height: 4px;">
                          <div class="progress-bar" role="progressbar" [style.width]="item.porcentaje+'%'"
                            [attr.aria-valuenow]="item.porcentaje" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                    </div>
                  </div>
      
                  <div style="text-align: center;">
                    <h3>Total Votos {{resultadoGeneralListTotal}}</h3>
                  </div>
                </div>
              </div>
            </div>

          </div> -->
          <div class="col-9">
            <canvas id="generalChartPipe">{{ chartGeneral }}</canvas>
          </div>
          <div class="col-3"></div>

        </div>
      </span>
    </div>


  </div>

  <!-- TAB PUESTO -->
  <div class="tab-pane fade" id="puestos_votacion" role="tabpanel" aria-labelledby="puestos_votacion-tab">
    <div class="row" style="min-height: 500px;" *ngIf="puestoNombre && mesaNombre">
      <div style="text-align: center;" class="mt-2">
        <h2 style="font-size: 1.4em;">PUESTO <b>{{puestoNombre | uppercase}} - {{mesaNombre | uppercase}}</b></h2>
      </div>
      <div class="col-md-6">
        <div class="card" style="width: 30rem;margin-left: auto;">
          <div class="card-body">
            <div class="row">
              <div class="col-md-8">
                <h3 style="font-size: 1.2em;">E14 Reporte Testigo </h3>
              </div>
            </div>

            <div class="card" *ngFor="let item of testigosList">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-2">
                    <img width="62px" [src]="item.fotografia_url" class="user-image img-circle elevation-2"
                      alt="User Image" />
                  </div>
                  <div class="col-md-7">
                    <h5>{{item.nombre | titlecase}}</h5>
                    <h6 style="font-weight: 300;">{{item.partido | titlecase}}</h6>
                  </div>
                  <div class="col-md-3 text-right">
                    <h3>{{item.votos}}</h3>
                    <h6>{{item.porcentaje}}%</h6>
                  </div>
                  <div class="progress mt-2" style="height: 4px;">
                    <div class="progress-bar" role="progressbar" [style.width]="item.porcentaje+'%'"
                      [attr.aria-valuenow]="item.porcentaje" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
              </div>
            </div>


            <div style="text-align: center;">
              <h3>Total Votos {{countMesaTestigo}}</h3>
              <small *ngIf="usuario_nombre">Reportado por <b>{{usuario_nombre | titlecase}}</b></small>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card" style="width: 30rem; margin-right: auto;">
          <div class="card-body">
            <div class="row">
              <div class="col-md-8">
                <h3 style="font-size: 1.2em;">Imagen Formato </h3>
              </div>
              <div class="col-md-4 text-right"><a [href]="e14Img" target="_blank"><i class="fas fa-vote-yea"
                    style="font-size: 1.8em;"></i></a></div>
            </div>
            <img [src]="e14Img" class="img-fluid">
          </div>
        </div>
      </div>

    </div>

    <div style="background-color: white;">
      <div class="row">
        <div class="col-md-6">
          <!-- PUESTO DE VOTACION -->
          <div class="card mt-3 ml-3">
            <div class="card-body table-responsive p-0">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">PUESTOS DE VOTACIÓN</th>
                  </tr>
                  <tr>
                    <th>
                      <div class="input-group mb-0">
                        <input type="text" class="form-control" [(ngModel)]="findInput" placeholder="Buscador">
                        <button class="btn btn-secondary" type="button" id="button-addon2" (click)="find()"><i
                            class="fa fa-search"></i></button>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of puestos">
                    <td style="cursor: pointer;" (click)="ver(item)">{{item.nombre | titlecase}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
        <div class="col-md-6">
          <!-- MESAS -->
          <div class="card mt-3">
            <div class="card-body table-responsive p-0">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col"><span *ngIf="puestoSelect" class="text-primary">{{puestoSelect | uppercase }}
                        ></span> MESAS DE VOTACIÓN</th>
                  </tr>

                </thead>
                <tbody>
                  <tr *ngFor="let item of mesa_votacion">
                    <td style="cursor: pointer;" (click)="verResultados(item, puestoSelect)"
                      fragmente="puestos_votacion">{{item.nombre | titlecase}}</td>
                  </tr>
                </tbody>
              </table>
              <canvas class="mt-4" id="generalChartMesa">{{ chartMesa }}</canvas>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <!-- TAB MAPA VOTACION -->
  <div class="tab-pane fade show bg-body" id="mapa_votacion" role="tabpanel" aria-labelledby="mapa-tab">
    <app-mapa-votacion></app-mapa-votacion>
  </div>

</div>