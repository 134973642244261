<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-7">
            <h1>
              <i class="fas fa-couch"></i>  Puesto Votación
              <button
                class="btn btn-sm btn-primary"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNuevaUsuario"
                aria-controls="offcanvasNuevaUsuario"
              >
                <i class="fa fa-plus"></i>
              </button>
              <!-- <button class="btn btn-sm btn-success ml-1" type="button" (click)="exportCliente()"><i class="fa fa-file-excel"></i></button> -->
            </h1>
          </div>
          <div class="col-5">
            <div class="input-group mb-0">
              <input
                type="text"
                class="form-control"
                [(ngModel)]="findInput"
                placeholder="Buscador"
              />
              <button
                class="btn btn-secondary"
                type="button"
                id="button-addon2"
                (click)="find()"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item active">Puesto Votación</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content m-2">
  <div class="card">
    <div class="card-body table-responsive p-0">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">NOMBRE</th>
            <th scope="col" class="text-center">MESAS</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of puestos.items">
            <td style="cursor: pointer; font-size: 1.2em" (click)="ver(item)">
              {{ item.nombre | titlecase }}
            </td>
            <td class="text-center"><span *ngIf="item.count_mesas">{{ item.count_mesas }}</span> <span *ngIf="!item.count_mesas">0</span></td>
            <td>
              <div class="dropdown">
                <button
                  class="btn btn-block btn-light dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                ></button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <span
                      class="dropdown-item"
                      style="cursor: pointer"
                      (click)="ver(item)"
                      >Ver Puesto</span
                    >
                  </li>
                  <li>
                    <span
                      class="dropdown-item text-danger"
                      style="cursor: pointer"
                      (click)="eliminar(item)"
                      >Eliminar</span
                    >
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- paginador -->
  <div class="card-footer clearfix">
    <ul class="pagination pagination-sm m-0 float-right">
      <li class="page-item" *ngIf="puestos.current > 1">
        <button
          type="button"
          class="page-link"
          (click)="changePage(puestos.current - 1)"
        >
          <span>Atras</span>
        </button>
      </li>

      <li
        class="page-item"
        *ngFor="let page of pagesNumber"
        [ngClass]="{ active: puestos.current == page }"
      >
        <button type="button" class="page-link" (click)="changePage(page)">
          {{ page }}
        </button>
      </li>

      <li class="page-item" *ngIf="puestos.current < puestos.total_page">
        <button
          type="button"
          class="page-link"
          (click)="changePage(puestos.next)"
        >
          <span>Siguiente</span>
        </button>
      </li>
    </ul>
    <div
      class="pagination"
      style="height: auto !important; margin: 10px; margin-top: 0px"
    >
      <p>
        Total registros encontrados: <b>{{ puestos.size }}</b>
      </p>
    </div>
  </div>
  <!-- Fin paginador -->
</section>

<!-- Modal Ver  -->
<div
  class="offcanvas offcanvas-end"
  tabindex="-1"
  id="offcanvasVer"
  aria-labelledby="offcanvasVerLabel"
>
  <div class="offcanvas-header">
    <h5 id="offcanvasRightLabel">
      Puesto de Votación {{ editForm.value.nombre | titlecase }}
    </h5>
    <button
      type="button"
      class="btn-close text-reset"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    ></button>
  </div>
  <div class="offcanvas-body">
    <div class="row">
      <div class="col-5 mt-3">
        <form [formGroup]="editForm" (ngSubmit)="edit()">
          <div class="form-group">
            <input
              formControlName="nombre"
              placeholder="Nombre"
              type="text"
              class="form-control"
            />
          </div>

          <div class="form-group">
            <input
              formControlName="detalle"
              placeholder="Detalle"
              type="text"
              class="form-control"
            />
          </div>

          <div class="card">
            <div class="card-body">
              <h5>Total Mesas {{ count_mesas }}</h5>
              <h5>Total sufragantes {{ count_sufragantes }}</h5>
            </div>
          </div>

          <!-- SUPERVIDOR -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-8"><h4>Supervisor</h4></div>
                <div class="col-4">
                  <button
                    class="btn btn-sm btn-block btn-primary"
                    (click)="getListUsuarios(3, editForm.value.id, 0)"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#buscarUsuarioModal"
                  >
                    <i class="fa fa-user-plus"></i>
                  </button>
                </div>
              </div>

              <div class="card mt-3" *ngFor="let item of supervisor_votacion">
                <div class="card-body">
                  <div class="row">
                    <div class="col-8" *ngIf="item.usuario.nombre">
                      {{ item.usuario.nombre | titlecase }}
                      <h6>{{ item.usuario.celular }}</h6>
                    </div>
                    <div class="col-4" *ngIf="item.usuario.nombre">
                      <button
                        type="button"
                        class="btn btn-sm btn-block btn-outline-danger"
                        (click)="
                          eliminarTestigo(item.relacion_id, editForm.value.id)
                        "
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- FIN SUPERVIDOR -->
          <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Actualizar Puesto</app-button
          >
        </form>
      </div>
      <div class="col-7 mt-3">
        <!-- MESAS -->
        <div class="card bg-secondary">
          <div class="card-body">
            <div class="row mb-4">
              <div class="col-10">
                <h3>
                  <div>Mesas de votación</div>
                  <small>Testigo mesa</small>
                </h3>
              </div>
              <div class="col-2">
                <button
                  class="btn btn-sm btn-block btn-primary"
                  (click)="addMesaVotacion(editForm.value.id)"
                  type="button"
                >
                  <i class="fa fa-plus"></i>
                </button>
              </div>
            </div>

            <div *ngIf="editando" class="card">
              <div class="card-body">
                <div class="input-group">
                  <span class="input-group-text" id="basic-addon1"
                    >nombre <span class="text-danger"> *</span></span
                  >
                  <input
                    class="form-control"
                    [(ngModel)]="nombreMesa"
                    type="text"
                    placeholder=""
                  />
                </div>
                <div class="input-group mt-2">
                  <span class="input-group-text" id="basic-addon1"
                    >Sufragantes <span class="text-danger"> *</span></span
                  >
                  <input
                    type="number"
                    class="form-control"
                    [(ngModel)]="sufragantesMesa"
                    placeholder=""
                  />
                </div>

                <div class="btn-group mt-2">
                  <button
                    *ngIf="editando"
                    (click)="editando = false"
                    type="button"
                    class="btn btn-secondary btn-sm"
                  >
                    <i class="fa fa-chevron-left"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    (click)="actualizarMesa()"
                  >
                    Actualizar Mesa
                  </button>
                </div>
              </div>
            </div>

            <div class="card text-body" *ngFor="let item of mesa_votacion">
              <div class="card-body">
                <div class="row">
                  <div class="col-10">
                    <h5>
                      <i class="fas fa-vote-yea"></i>
                      {{ item.nombre | titlecase }}
                    </h5>
                    <h6>
                      <i class="fas fa-user-tie"></i> Sufragantes
                      {{ item.sufragantes }}
                    </h6>
                  </div>
                  <div class="col-2">
                    <button
                      class="btn btn-sm btn-block btn-secondary"
                      type="button"
                      (click)="edicion(item)"
                    >
                      <i class="fa fa-edit"></i>
                    </button>
                    <button
                      [disabled]="item.usuario.nombre"
                      class="btn btn-sm btn-block btn-secondary"
                      (click)="getListUsuarios(4, editForm.value.id, item.id)"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#buscarUsuarioModal"
                    >
                      <i class="fa fa-user-plus"></i>
                    </button>
                  </div>
                  <div class="col-10" *ngIf="item.usuario.nombre">
                    <i class="fa fa-info"></i> {{ item.usuario.nombre | titlecase }}
                    {{ item.usuario.celular }}
                  </div>
                  <div class="col-2" *ngIf="item.usuario.nombre">
                    <button
                      type="button"
                      class="btn btn-sm btn-block btn-danger mt-1"
                      (click)="
                        eliminarTestigo(item.relacion_id, editForm.value.id)
                      "
                    >
                      <i class="fa fa-user-times"></i>
                    </button>
                  </div>
                  <div class="col-12" *ngIf="!item.usuario.nombre">
                    <button
                      type="button"
                      class="btn btn-outline-danger btn-sm mt-2"
                      (click)="eliminarMesa(item.id, editForm.value.id)"
                    >
                      <i class="fa fa-trash"></i> Eliminar mesa
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <!-- <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Actualizar Puesto</app-button>                        -->
      </div>
    </div>

    <!-- </form> -->
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="buscarUsuarioModal"
  tabindex="-1"
  aria-labelledby="buscarUsuarioModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Buscar Usuario</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div>
          <div class="form-group">
            <input [(ngModel)]="buscador" type="text" class="form-control" />
          </div>
          <div *ngIf="!buscador"></div>

          <button
            type="button"
            class="btn btn-block btn-sm btn-secondary"
            (click)="crearUsuarioCard()"
          >
            <i class="fa fa-user"> </i> Crear Usuario
          </button>

          <div class="card" id="nuevoUsuario" style="display: none">
            <div class="body-card">
              <form [formGroup]="nuevoFormUsuario" (ngSubmit)="newUsuario()">
                <div class="col-12 mt-3" id="crearCliente">
                  <div class="form-group">
                    <input
                      formControlName="nombreUser"
                      placeholder="Nombre"
                      type="text"
                      class="form-control"
                    />
                  </div>

                  <div class="form-group">
                    <input
                      formControlName="emailUser"
                      placeholder="Email"
                      type="text"
                      class="form-control"
                    />
                  </div>

                  <div class="form-group">
                    <input
                      formControlName="passwordUser"
                      placeholder="Contraseña"
                      type="text"
                      class="form-control"
                    />
                  </div>

                  <div class="form-group">
                    <input
                      formControlName="celularUser"
                      placeholder="Celular"
                      type="number"
                      class="form-control"
                    />
                  </div>
                  <app-button
                    [type]="'submit'"
                    [block]="true"
                    [loading]="isAuthLoading"
                    >Crear Usuario</app-button
                  >
                </div>
              </form>
            </div>
          </div>

          <ul class="list-group" *ngIf="buscador">
            <button
              *ngFor="let item of listUsuarios | filtroUsuario: buscador"
              type="button"
              class="list-group-item list-group-item-action"
              (click)="selectUsuarioMesa(item.id, item.perfil_id)"
              data-bs-dismiss="modal"
              data-bs-toggle="modal"
              data-bs-target="#buscarUsuarioModal"
            >
              {{ item.nombre | titlecase }} {{ item.celular | titlecase }}
            </button>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Nueva  -->
<div
  class="offcanvas offcanvas-end"
  tabindex="-1"
  id="offcanvasNuevaUsuario"
  aria-labelledby="offcanvasNuevaUsuarioLabel"
>
  <div class="offcanvas-header">
    <h5 id="offcanvasRightLabel">Nuevo Puesto de Votación</h5>
    <button
      type="button"
      class="btn-close text-reset"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    ></button>
  </div>
  <div class="offcanvas-body">
    <form [formGroup]="nuevoForm" (ngSubmit)="new()">
      <div class="col-12 mt-3" id="crearCliente">
        <div class="form-group">
          <input
            formControlName="nombre"
            placeholder="Nombre"
            type="text"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <input
            formControlName="detalle"
            placeholder="Detalle"
            type="text"
            class="form-control"
          />
        </div>
        <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading"
          >Crear Puesto</app-button
        >
      </div>
    </form>
  </div>
</div>
