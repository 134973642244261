<section class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-12">
                <h1>
                  Visas
                  <button class="btn btn-sm btn-success " type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNuevaUsuario" aria-controls="offcanvasNuevaUsuario"><i class="fa fa-paper-plane"></i> </button>
                  <button class="btn btn-sm btn-primary ml-1" type="button" data-bs-toggle="modal" data-bs-target="#verformato"><i class="fa fa-passport"></i> Formatos</button>
                  <button class="btn btn-sm btn-primary ml-1" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNuevaSolicitud" aria-controls="offcanvasNuevaSolicitud"><i class="fa fa-plus"></i> Nueva Solicitud</button>
                </h1>
              </div>

            </div>

          </div>
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">

                  <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                  <li class="breadcrumb-item active">Visas</li>
              </ol>
          </div>
      </div>
  </div>
  <!-- /.container-fluid -->
</section>

  <!-- Modal VerFormato -->
  <div class="modal fade" id="verformato" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Formatos Visas</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">       
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Nueva  -->
  <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNuevaSolicitud" aria-labelledby="offcanvasNuevaSolicitudLabel">
    <div class="offcanvas-header">
      <h5 id="offcanvasRightLabel">Nueva Solicitud</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">

      <!-- <form [formGroup]="nuevoForm" (ngSubmit)="newCliente()">
        <div class="col-12 mt-3" id="crearCliente">
              <div class="form-group">
                <input formControlName="nombre" placeholder="Nombre" type="text" class="form-control"/>
              </div> 
              
              <div class="form-group">
                <input formControlName="email" placeholder="Email" type="text" class="form-control"/>
              </div> 
              
              <div class="form-group">
                <input formControlName="celular" placeholder="Celular Contacto" type="text" class="form-control"/>
              </div> 

              <div class="form-group">
                <input formControlName="ciudad" placeholder="Ciudad" type="text" class="form-control"/>
              </div> 

              <div class="form-group">
                <textarea formControlName="nota" class="form-control" placeholder="Nota" rows="5"></textarea>
              </div>               


              <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Crear Cliente</app-button>                       
        </div> 

      </form> -->
    </div>
  </div>


<section>
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#interesados" type="button" role="tab" aria-controls="interesados" aria-selected="true">Interesados</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#proceso" type="button" role="tab" aria-controls="proceso" aria-selected="false">En Proceso</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#agendado" type="button" role="tab" aria-controls="agendado" aria-selected="false">Agendado</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#finalizado" type="button" role="tab" aria-controls="finalizado" aria-selected="false">Finalizado</button>
    </li>    
  </ul>

  <div class="tab-content" id="myTabContent">
    <!-- TAB interesados -->
    <div class="tab-pane fade show active  bg-body" id="interesados" role="tabpanel" aria-labelledby="recientes-tab">
      <div class="container bg-body">
        <table class="table table-hover table-sm">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Visa</th>
              <th scope="col">Nombre</th>
              <th scope="col">Contacto</th>
              <th scope="col">Visto</th>
              <th scope="col">Estado</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr *ngFor="let item of listHistorial">
              <th scope="row">{{item.consecutivo}}</th>
              <td>{{item.solicitud_at}}</td>
              <td>{{item.nombre | titlecase}}</td>
              <td><span *ngIf="!item.usuario_id">WEB</span> <span *ngIf="item.usuario_id">{{item.usuario_nombre}}</span></td>
              <td>{{item.solicitud_up}}</td>
              <td>
                <span *ngIf="item.estado == -1" class="badge bg-danger">Cancelada</span>
                <span *ngIf="item.estado == 3" class="badge bg-success">Procesada</span>
                <span *ngIf="item.estado == 2" class="badge bg-warning">Pendiente</span>
                <span *ngIf="item.estado == 1" class="badge bg-warning">Pendiente</span>
              </td>
            </tr> -->
          </tbody>
        </table>
      </div>        
    </div>


    <!-- TAB proceso -->
    <div class="tab-pane fade" id="proceso" role="tabpanel" aria-labelledby="proceso-tab">
      <div class="container bg-body">
        <table class="table table-hover table-sm">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Visa</th>
              <th scope="col">Nombre</th>
              <th scope="col">Contacto</th>
              <th scope="col">Personas</th>
              <th scope="col">Fotos</th>
              <th scope="col">Formulario</th>
              <th scope="col">Pasaporte</th>
              <th scope="col">Fecha Cita</th>
              <th scope="col">Visto</th>
              <th scope="col">Estado</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr *ngFor="let item of listHistorial">
              <th scope="row">{{item.consecutivo}}</th>
              <td>{{item.solicitud_at}}</td>
              <td>{{item.nombre | titlecase}}</td>
              <td><span *ngIf="!item.usuario_id">WEB</span> <span *ngIf="item.usuario_id">{{item.usuario_nombre}}</span></td>
              <td>{{item.solicitud_up}}</td>
              <td>
                <span *ngIf="item.estado == -1" class="badge bg-danger">Cancelada</span>
                <span *ngIf="item.estado == 3" class="badge bg-success">Procesada</span>
                <span *ngIf="item.estado == 2" class="badge bg-warning">Pendiente</span>
                <span *ngIf="item.estado == 1" class="badge bg-warning">Pendiente</span>
              </td>
            </tr> -->
          </tbody>
        </table>
      </div>
    </div>

    <!-- TAB agendado -->
    <div class="tab-pane fade  bg-body" id="agendado" role="tabpanel" aria-labelledby="agendado-tab">
      <div class="container bg-body">
        <table class="table table-hover table-sm">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Visa</th>
              <th scope="col">Nombre</th>
              <th scope="col">Contacto</th>
              <th scope="col">Personas</th>
              <th scope="col">Fecha Cita 1</th>
              <th scope="col">Fecha Cita 2</th>
              <th scope="col">Visto</th>
              <th scope="col">Estado</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr *ngFor="let item of listHistorial">
              <th scope="row">{{item.consecutivo}}</th>
              <td>{{item.solicitud_at}}</td>
              <td>{{item.nombre | titlecase}}</td>
              <td><span *ngIf="!item.usuario_id">WEB</span> <span *ngIf="item.usuario_id">{{item.usuario_nombre}}</span></td>
              <td>{{item.solicitud_up}}</td>
              <td>
                <span *ngIf="item.estado == -1" class="badge bg-danger">Cancelada</span>
                <span *ngIf="item.estado == 3" class="badge bg-success">Procesada</span>
                <span *ngIf="item.estado == 2" class="badge bg-warning">Pendiente</span>
                <span *ngIf="item.estado == 1" class="badge bg-warning">Pendiente</span>
              </td>
            </tr> -->
          </tbody>
        </table>
      </div>     
    </div>

    <!-- TAB finalizado -->
    <div class="tab-pane fade  bg-body" id="finalizado" role="tabpanel" aria-labelledby="finalizado-tab">
      <div class="container bg-body">
        <table class="table table-hover table-sm">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Visa</th>
              <th scope="col">Nombre</th>
              <th scope="col">Contacto</th>
              <th scope="col">Fecha Cita 1</th>
              <th scope="col">Fecha Cita 2</th>
              <th scope="col">Visto</th>
              <th scope="col">Estado</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr *ngFor="let item of listHistorial">
              <th scope="row">{{item.consecutivo}}</th>
              <td>{{item.solicitud_at}}</td>
              <td>{{item.nombre | titlecase}}</td>
              <td><span *ngIf="!item.usuario_id">WEB</span> <span *ngIf="item.usuario_id">{{item.usuario_nombre}}</span></td>
              <td>{{item.solicitud_up}}</td>
              <td>
                <span *ngIf="item.estado == -1" class="badge bg-danger">Cancelada</span>
                <span *ngIf="item.estado == 3" class="badge bg-success">Procesada</span>
                <span *ngIf="item.estado == 2" class="badge bg-warning">Pendiente</span>
                <span *ngIf="item.estado == 1" class="badge bg-warning">Pendiente</span>
              </td>
            </tr> -->
          </tbody>
        </table>
      </div>       
    </div>    
  </div>
</section>