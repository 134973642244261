import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-form-solicitud',
  templateUrl: './form-solicitud.component.html',
  styleUrls: ['./form-solicitud.component.scss']
})
export class FormSolicitudComponent implements OnInit, OnDestroy {
  public nuevaSolicitudForm: FormGroup;
  public isAuthLoading = false;
  public empresaValida = false;
  public empresaNoValida = false;
  sigla: any;
  et: any;
  nombre: any;

  constructor(private route: ActivatedRoute,private renderer: Renderer2,private toastr: ToastrService,private appService: AppService) { }

  ngOnInit(): void {
    this.renderer.addClass(
      document.querySelector('body'),
      'solicitud-new'
  );

    this.sigla = this.route.snapshot.paramMap.get('sigla');
    this.nuevaSolicitudForm = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      celular: new FormControl(null, Validators.required),
      fecha: new FormControl(null, Validators.required),
      adultos: new FormControl(null, Validators.required),
      infantes: new FormControl(null, Validators.nullValidator),
      detalle: new FormControl(null, Validators.required),
    }); 
    this.getEmpresa();    
  }


  getEmpresa(){
    this.isAuthLoading = true;
    let params = {
      empresasigla:true,
      sigla: this.sigla,
    };    
    this.appService.postSolicitudes(params).subscribe( (data) => {
        console.log(data);  
        if (data["logger"] == true){
          this.et = data['et'];
          this.nombre = data['nombre'];
          this.empresaValida = true;
        }else{
          this.empresaValida = false;
          this.empresaNoValida = true;
        }
        // empresaValida 
        this.isAuthLoading = false;
    });
  }

  async enviarSolicitud(){     
    if (this.nuevaSolicitudForm.valid) {
        this.isAuthLoading = true; 
          let params = {
              nuevo:true,
              nombre: this.nuevaSolicitudForm.value.nombre,
              celular: this.nuevaSolicitudForm.value.celular,
              email: this.nuevaSolicitudForm.value.email,            
              et: this.et,
              estado: 1,
            };
            // console.log(params);
            this.appService.postClientes(params)
          .subscribe( (data) => {
              if (data["logger"] == true){
                console.log(data);
                let clienteId = data['data'].id;
                console.log(clienteId);
                
                
                
                let params2 = {
                    nuevo:true,
                    id: clienteId,
                    detalle: "Fecha "+this.nuevaSolicitudForm.value.fecha+' Adultos:'+this.nuevaSolicitudForm.value.adultos+' Niños:'+this.nuevaSolicitudForm.value.infantes+' '+this.nuevaSolicitudForm.value.detalle,
                    et: this.et,
                    estado: 2,
                  };
                  this.appService.postSolicitudes(params2).subscribe( (data2) => {
                    if (data2["logger"] == true){  
                      this.toastr.success('Solicitud Enviada Exitosamente. Muy pronto tendrá respuesta.');
                      this.nuevaSolicitudForm.reset(); 
                    }else{
                      this.toastr.error(data["msg"]);
                    }   
                    });

                  // $('#nuevaSolicitud').modal('hide');
              }else{
                  this.toastr.error(data["msg"]);
              }
          }); 
        this.isAuthLoading = false;
    } else {
        this.toastr.error('El formulario no es válido! por favor llene todos los campos');
    }
  }


  ngOnDestroy() {
    this.renderer.removeClass(
        document.querySelector('body'),
        'solicitud-new'
    );
}

}
