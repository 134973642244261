<div class="card m-2">
  <div class="card-body">
    <div class="text-right pb-3">

      <div>
        <button *ngIf="votacion_app" class="btn btn-sm btn-secondary" (click)="votacionApp(false)">Cerrar votación</button>
        <button *ngIf="!votacion_app" class="btn btn-sm btn-primary" (click)="votacionApp(true)">Habilitar reporte votación App Mobil</button>


        <button class="btn btn-sm btn-outline-secondary" (click)="stopTimer()">Detener</button>
        <button [ngClass]="{ active: timerSelect == 3000 }" class="btn btn-sm btn-outline-secondary" type="button"
          (click)="startTimer(3000)">3 Seg</button>
        <button [ngClass]="{ active: timerSelect == 7000 }" class="btn btn-sm btn-outline-secondary" type="button"
          (click)="startTimer(7000)">7 Seg</button>
        <button [ngClass]="{ active: timerSelect == 12000 }" class="btn btn-sm btn-outline-secondary" type="button"
          (click)="startTimer(12000)">12 Seg</button>
      </div>

    </div>

    <div class="p-4 bg-warning" *ngIf="!votacion_app">
      <h4 class="text-center">El reporte de votación se encuentra deshabilitado para la aplicación mobil.</h4>
    </div>

    <div class="table-responsive">
      <table class="table table-sm table-bordered table-hover border-light ">
        <thead>
          <tr class="d-flex">
            <th scope="col" style="width: 300px!important;">
              <h4>Puestos de Votación</h4>
            </th>
            <th scope="col" style="width: 100px!important">
              <h4 class="text-center">#</h4>
            </th>
            <th scope="col" *ngFor="let in of counter(count_mesas); let i = index" class="text-center"
              style="width: 40px!important">
              <h6>{{ i + 1 }}</h6>
            </th>
            <th scope="col" class="text-center" style="width: 100px!important">
              <h4>%</h4>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="d-flex" *ngFor="let item of puestos_votacion">
            <th scope="row" style="width: 300px!important; font-size: 0.9em;">{{ item.nombre | titlecase }}</th>
            <td class="text-center" style="width: 100px!important"><span
                *ngIf="item.count_mesas">{{item.count_mesas}}</span> <span *ngIf="!item.count_mesas">0</span></td>
            <td *ngFor="let item of item.mesas" class=" text-center " 
              [ngClass]="{ 'bg-success': (item?.votacion_estado == '2' && item?.mala_votacion =='no') , 
                          'bg-warning': (item?.votacion_estado == '2' && item?.mala_votacion =='si'), 
                          'bg-danger': (parseIntValue(item?.total_mesa) > item?.sufragantes)}" 
                          
              (click)="selectMesa(item)" style="cursor: pointer; width: 40px!important">
              <!-- 'bg-danger': (item.sufragantes > item.total_mesa && item.sufragantes >=1 && item.total_mesa >=1) }"  -->
              <!-- {{item.total_mesa}} > {{item?.sufragantes}} -->
              <!-- V.E {{item.votacion_estado}} -
              M.V {{item.mala_votacion}} - -->
              <!-- S {{item.sufragantes}} - 
              T.M {{item.total_mesa}} -->

              <!-- {{item.votacion_estado}}
                {{item.mala_votacion}} -->
            </td>
            <td class="text-center" style="width: 100px!important"><b>{{item.porcentaje}} %</b></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-- Modal ver detalle  -->
<div class="modal fade" id="verMesa" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="verMesaLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header" [ngClass]="{ 'bg-success': (mesaInfo?.votacion_estado == '2' && mesaInfo?.mala_votacion =='no') , 
                          'bg-warning': (mesaInfo?.votacion_estado == '2' && mesaInfo?.mala_votacion =='si'), 
                          'bg-danger': (parseIntValue(countMesaTestigo) > mesaInfo?.sufragantes) }">
        <h5 class="modal-title" id="verMesaLabel">
          {{ mesaInfo?.puesto_nombre | titlecase }}
          {{ mesaInfo?.nombre | titlecase }}
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="reseall()"></button>
      </div>
      <div class="modal-body">

        <div class="row">
          <div class="col-md-6">
            <div class="card" style="width: 23rem;margin-left: auto;">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-8">
                    <h3 style="font-size: 1.2em;">E14 Reporte Testigo </h3>
                  </div>
                </div>

                <div class="card" *ngFor="let item of testigosList">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-2">
                        <img width="42px" [src]="item.fotografia_url" class="user-image img-circle elevation-2"
                          alt="User Image" />
                      </div>
                      <div class="col-md-6">
                        <h6>{{item.nombre | titlecase}}</h6>
                        <h6 style="font-weight: 300;">{{item.partido | titlecase}}</h6>
                      </div>
                      <div class="col-md-4 text-right">
                        <h4>{{item.votos}}</h4>
                        <h6>{{item.porcentaje}}%</h6>
                      </div>
                    </div>
                    <div class="progress mt-2" style="height: 4px;">
                      <div class="progress-bar" role="progressbar" [style.width]="item.porcentaje+'%'"
                        [attr.aria-valuenow]="item.porcentaje" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>


                <div style="text-align: center;">
                  <h3 class="mb-4">Total Votos {{mesaInfo?.total_mesa}}</h3>
                  <span *ngIf="usuario_nombre">Reportado por</span>
                  <h4 *ngIf="usuario_nombre"><b>{{usuario_nombre | titlecase}}</b></h4>
                  <h4 *ngIf="usuario_celular">celular <b>{{usuario_celular }}</b></h4>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">


            <div class="card" style="width: 23rem; margin-right: auto;">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6 bg-warning pt-2 pb-2">
                    <h3 style="font-size: 1.2em;"><b>Sufragantes {{mesaInfo?.sufragantes}}</b> </h3>
                  </div>
                  <div class="col-md-6 bg-success pt-2 pb-2">
                    <h3 style="font-size: 1.2em;"><b>Total Mesa {{countMesaTestigo}}</b>
                      <!-- {{mesaInfo?.total_mesa}} -->
                    </h3>
                  </div>
                  <!-- <div class="col-md-12 bg-danger" *ngIf="mesaInfo?.sufragantes > mesaInfo?.total_mesa && mesaInfo?.sufragantes >=1 && mesaInfo?.total_mesa >=1"> -->
                  <!-- {{countMesaTestigo}} > {{mesaInfo?.sufragantes}} -->
                  <div class="col-md-12 bg-danger" *ngIf="parseIntValue(countMesaTestigo) > mesaInfo?.sufragantes">
                    <h3 style="font-size: 1.2em;" class="text-center">La cantidad de votos es superior a los sufragantes
                      reportados</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="card" style="width: 23rem; margin-right: auto;">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <h3 style="font-size: 1.2em;">Hubo mala votación? <b>{{mesaInfo?.mala_votacion | titlecase}}</b>
                    </h3>
                    <h6>{{mesaInfo?.causales}}</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="card" style="width: 23rem; margin-right: auto;">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-7">
                    <h3 style="font-size: 1.2em;">Imagen Formato </h3>
                  </div>
                  <div class="col-md-5 text-right mb-2">

                    <button *ngIf="cargando" class="btn btn-sm btn-secondary mr-1" type="button" disabled>
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span class="visually-hidden">Loading...</span>
                    </button>

                    <button *ngIf="!cargando" type="button" class="btn btn-sm btn-secondary mr-1"
                      (click)="girarImagen('90',imgName)"><i class="fa fa-undo-alt"></i></button>
                    <button *ngIf="!cargando" type="button" class="btn btn-sm btn-secondary mr-1"
                      (click)="girarImagen('-90',imgName)"><i class="fa fa-redo-alt"></i></button>
                    <!-- <a [href]="e14Img" target="_blank" class="btn btn-secondary"><i class="fas fa-vote-yea"></i></a> -->
                  </div>
                </div>
                <a [href]="e14Img" target="_blank">
                  <img [src]="e14Img" class="img-fluid">
                </a>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <small>Testigo asignado</small>
                <h6><i class="fa fa-user-circle"></i> {{usuario_testigo?.nombre}}</h6>
                <h6><i class="fa fa-mobile"></i> {{usuario_testigo?.celular}}</h6>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <small>Supervisior asignado</small>
                <h6><i class="fa fa-user-circle"></i> {{usuario_supervisor?.nombre}}</h6>
                <h6><i class="fa fa-mobile"></i> {{usuario_supervisor?.celular}}</h6>
              </div>
            </div>

            <button *ngIf="countMesaTestigo" class="btn btn-block btn-danger mt-5" (click)="borrarVotacion(mesaInfo?.puesto_id, mesaInfo?.id)" type="button">
              <i class="fa fa-eraser"></i> Borrar votación
            </button>


          </div>

        </div>


      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="reseall()">
          Cerrar
        </button>
      </div>
    </div>
  </div>
</div>