<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h1>Empresas <button class="btn btn-sm btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#nuevaEmpresa"><i class="fa fa-plus"></i> NUEVO</button></h1>
          </div>
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                  <li class="breadcrumb-item active">Empresas</li>
              </ol>
          </div>
      </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
      <div class="card">
          <div class="card-header">
              <h3 class="card-title">Listado empresas</h3>
          </div>
          <div class="card-body table-responsive p-0">
            <table class="table table-hover table-valign-middle">
              <thead>
              <tr>
                <th>Nombre</th>
                <th>Email</th>
                <th>Telefono</th>
                <th>Encargado</th>
                <th>URL</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr  *ngFor="let item of empresas.items">
                <td>{{item.nombre | titlecase}}</td>
                <td>{{item.email}}</td>
                <td>{{item.telefono}}</td>
                <td>{{item.encargado | titlecase}}</td>
                <td>{{item.sigla}}</td>
                <td>
                  <button type="button" class="btn btn-light" (click)="editEmpresaModal(item)">
                    <i class="fas fa-search"></i>
                  </button>
                </td>
              </tr>
              </tbody>
              </table>            
          </div>
          <!-- /.card-body -->
          <div class="card-footer">
              <!-- paginador -->
              <div>
                <ul class="pagination pagination-sm m-0 float-right">
                  <li class="page-item" *ngIf="empresas.current > 1">
                    <button type="button" class="page-link" (click)="changePage(empresas.current - 1)">
                      <span>Atras</span>
                    </button>
                  </li>                  

                  <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': empresas.current == page}">
                    <button type="button" class="page-link" (click)="changePage(page)">
                      {{ page }}
                    </button>
                  </li>                  

                  <li class="page-item" *ngIf="empresas.current < empresas.total_page">
                    <button type="button" class="page-link" (click)="changePage(empresas.next)">
                      <span>Siguiente</span>
                    </button>
                  </li>                  
                </ul>
                <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
                  <p>Total registros encontrados: <b>{{empresas.size}}</b></p>
                </div>
              </div>              
              <!-- Fin paginador -->   

          </div>
          <!-- /.card-footer-->
      </div>




  <!-- Modal Nueva Empresa -->
  <div class="modal fade" id="nuevaEmpresa" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Nueva Empresa</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <form [formGroup]="nuevaEmpresaForm" (ngSubmit)="newEmpresa()">
              <div class="input-group mb-3">
                <input
                    formControlName="nombre"
                    type="text"
                    class="form-control"
                    placeholder="Nombre"
                />
              </div>

              <div class="input-group mb-3">
                <input
                    formControlName="nit"
                    type="text"
                    class="form-control"
                    placeholder="Nit"
                />
              </div>            

              <div class="input-group mb-3">
                  <input
                      formControlName="email"
                      type="email"
                      class="form-control"
                      placeholder="Email"
                  />

              </div>

              <div class="input-group mb-3">
                <input
                    formControlName="direccion"
                    type="text"
                    class="form-control"
                    placeholder="Dirección"
                />
              </div> 
              
              <div class="input-group mb-3">
                <input
                    formControlName="telefono"
                    type="text"
                    class="form-control"
                    placeholder="Teléfono"
                />
              </div> 
              
              <div class="input-group mb-3">
                <input
                    formControlName="encargado"
                    type="text"
                    class="form-control"
                    placeholder="Encargado"
                />
              </div> 
              
              <div class="input-group mb-3">
                <input
                    formControlName="sigla"
                    type="text"
                    class="form-control"
                    placeholder="Sigla"/>
              </div>  


              <div class="row">
                  <div class="col-12">
                      <app-button
                          [type]="'submit'"
                          [block]="true"
                          [loading]="isAuthLoading"
                      >
                          Crear Empresa
                      </app-button>
                  </div>
                  <!-- /.col -->
              </div>
          </form>        
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
        </div> -->
      </div>
    </div>
  </div>

  <!-- Modal Edit Empresa -->
  <div class="modal fade" id="editEmpresa" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-dark">
          <h5 class="modal-title" id="exampleModalLabel">Empresa | {{editEmpresaForm.value.nombre}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-6">
              <form [formGroup]="editEmpresaForm" (ngSubmit)="editEmpresa()">
                <div class="mb-3">
                  <label for="nombre" class="form-label">Nombre Empresa</label>
                  <input
                      formControlName="nombre"
                      type="text"
                      class="form-control"
                      placeholder="Nombre"
                  />
                </div>

                <div class="mb-3">
                  <label for="nombre" class="form-label">Nit</label>
                  <input
                      formControlName="nit"
                      type="text"
                      class="form-control"
                      placeholder="Nit"
                  />
                </div>            

                <div class="mb-3">
                    <label for="email" class="form-label">Email</label>
                    <input
                        formControlName="email"
                        type="email"
                        class="form-control"
                        placeholder="Email"
                    />

                </div>

                <div class="mb-3">
                  <label for="direccion" class="form-label">Dirección</label>
                  <input
                      formControlName="direccion"
                      type="text"
                      class="form-control"
                      placeholder="Dirección"
                  />
                </div> 
                
                <div class="mb-3">
                  <label for="telefono" class="form-label">Teléfono</label>
                  <input
                      formControlName="telefono"
                      type="text"
                      class="form-control"
                      placeholder="Teléfono"
                  />
                </div> 
                
                <div class="mb-3">
                  <label for="encargado" class="form-label">Persona Contacto</label>
                  <input
                      formControlName="encargado"
                      type="text"
                      class="form-control"
                      placeholder="Encargado"
                  />
                </div> 
                
                <div class="mb-3">
                  <label for="sigla" class="form-label">Sigla Empresa</label>
                  <input
                      formControlName="sigla"
                      type="text"
                      class="form-control"
                      placeholder="Sigla"
                  />
                </div> 
                
                <div class="mb-3">
                  <label for="sigla" class="form-label">Cantidad de Usuarios Permitidos</label>
                  <input
                      formControlName="cant_usuarios"
                      type="text"
                      class="form-control"
                      placeholder=""
                  />
                </div>                 

                <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Editar </app-button>          
              </form>        


              </div>
              <div class="col-6">
                <h4>Listado Usuarios Empresa</h4>
                <p>
                  <button class="btn btn-block btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAgregarUsuario" aria-expanded="false" aria-controls="collapseAgregarUsuario">
                    <i class="fa fa-plus"></i> Usuario
                  </button>
                </p>
                <div class="collapse" id="collapseAgregarUsuario">
                  <div class="card card-body bg-light">
                    <form [formGroup]="editEmpresaUsuarioForm" (ngSubmit)="editEmpresaUsuario(editEmpresaForm.value.id)">
                      <div class="mb-3">
                        <label for="nombre" class="form-label">Nombre</label>
                        <input
                            formControlName="nombre"
                            type="nombre"
                            class="form-control"
                            placeholder="Nombre"
                        />
    
                      </div>                      
                      <div class="mb-3">
                        <label for="email" class="form-label">Email</label>
                        <input
                            formControlName="email"
                            type="email"
                            class="form-control"
                            placeholder="Email"
                        />
                      </div>
                      <div class="mb-3">
                        <label for="password" class="form-label">Contraseña</label>
                        <input
                            formControlName="password"
                            type="password"
                            class="form-control"
                            placeholder="Password"
                        />
                      </div>
                      <div class="mb-3">
                        <label for="perfil_id" class="form-label">Perfil Usuario</label>
                        <select
                            formControlName="perfil_id"
                            class="form-control"
                            placeholder="Seleccione Perfil"
                        >
                        <option value="2">Administrador</option>
                        <option value="3">Asesor</option>
                        </select>
                      </div>                    
                      <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Crear Usuario</app-button>          
                    </form>
                  </div>
                </div>


                <div class="card mb-0" *ngFor="let item of editEmpresaForm.value.usuarios_empresa">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-8">
                        <h5>{{item.nombre | titlecase}}</h5>
                        <h6>{{item.email}}</h6>                        
                        <small *ngIf="item.perfil_id == 2">Administrador</small>                        
                        <small *ngIf="item.perfil_id == 3">Asesor</small>                        
                      </div>
                      <div class="col-4 text-end">
                        <button type="button" class="btn btn-sm btn-primary" (click)="editEmpresaUsuarioModal(item)"><i class="fa fa-edit"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>                      

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
          </div>

      </div>
    </div>
  </div>  


  </div>
  <!-- /.card -->
</section>
<!-- /.content -->


<!-- editEmpresaUsuarioModal -->
<div class="modal fade" id="editEmpresaUsuario" tabindex="-1" aria-labelledby="editEmpresaUsuarioLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="editEmpresaUsuarioLabel">Editar Usuario</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="editUsuarioForm" (ngSubmit)="editUsuario(editUsuarioForm.value.id)">
          <div class="mb-3">
            <label for="nombre" class="form-label">Nombre</label>
            <input
                formControlName="nombre"
                type="nombre"
                class="form-control"
                placeholder="Nombre"
            />

          </div>                      
          <div class="mb-3">
            <label for="email" class="form-label">Email</label>
            <input
                formControlName="email"
                type="email"
                class="form-control"
                placeholder="Email"
            />
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Contraseña</label>
            <input
                formControlName="password"
                type="password"
                class="form-control"
                placeholder="Password"
            />
          </div>
          <div class="mb-3">
            <label for="perfil_id" class="form-label">Perfil Usuario</label>
            <select
                formControlName="perfil_id"
                class="form-control"
                placeholder="Seleccione Perfil"
            >
            <option value="2">Administrador</option>
            <option value="3">Asesor</option>
            </select>
          </div>                    
          <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Editar Usuario</app-button>          

        </form>        
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
      </div>
    </div>
  </div>
</div>