<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-7">
            <h1>
              <i class="fas fa-couch"></i>  Sectores
              <button
                class="btn btn-sm btn-primary"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNuevo"
                aria-controls="offcanvasNuevo"
              >
                <i class="fa fa-plus"></i>
              </button>
              <!-- <button class="btn btn-sm btn-success ml-1" type="button" (click)="exportCliente()"><i class="fa fa-file-excel"></i></button> -->
            </h1>
          </div>
          <div class="col-5">
            <div class="input-group mb-0">
              <input
                type="text"
                class="form-control"
                [(ngModel)]="findInput"
                placeholder="Buscador"
              />
              <button
                class="btn btn-secondary"
                type="button"
                id="button-addon2"
                (click)="find()"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item active">Sectores Islas</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content m-2">
  <div class="card">
    <div class="card-body table-responsive p-0">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">NOMBRE</th>
            <th scope="col" class="text-center">PUESTOS</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of sectores.items">
            <td style="cursor: pointer; font-size: 1.2em" (click)="ver(item)">
              {{ item.nombre | titlecase }}
            </td>
            <td class="text-center">
              {{item.countPuestos}}
            </td>
            <td>
              <div class="dropdown">
                <button
                  class="btn btn-block btn-light dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                ></button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <span
                      class="dropdown-item"
                      style="cursor: pointer"
                      (click)="ver(item)"
                      >Ver Puesto</span
                    >
                  </li>
                  <li>
                    <span
                      class="dropdown-item text-danger"
                      style="cursor: pointer"
                      (click)="eliminar(item)"
                      >Eliminar</span
                    >
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- paginador -->
  <div class="card-footer clearfix">
    <ul class="pagination pagination-sm m-0 float-right">
      <li class="page-item" *ngIf="sectores.current > 1">
        <button
          type="button"
          class="page-link"
          (click)="changePage(sectores.current - 1)"
        >
          <span>Atras</span>
        </button>
      </li>

      <li
        class="page-item"
        *ngFor="let page of pagesNumber"
        [ngClass]="{ active: sectores.current == page }"
      >
        <button type="button" class="page-link" (click)="changePage(page)">
          {{ page }}
        </button>
      </li>

      <li class="page-item" *ngIf="sectores.current < sectores.total_page">
        <button
          type="button"
          class="page-link"
          (click)="changePage(sectores.next)"
        >
          <span>Siguiente</span>
        </button>
      </li>
    </ul>
    <div
      class="pagination"
      style="height: auto !important; margin: 10px; margin-top: 0px"
    >
      <p>
        Total registros encontrados: <b>{{ sectores.size }}</b>
      </p>
    </div>
  </div>
  <!-- Fin paginador -->
</section>

<!-- Modal Ver  -->
<div class="modal fade" id="verModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Sector {{ editForm.value.nombre | titlecase }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-5 mt-3">
            <form [formGroup]="editForm" (ngSubmit)="edit()">
              <div class="form-group">
                <input
                  formControlName="nombre"
                  placeholder="Nombre"
                  type="text"
                  class="form-control"
                />
              </div>
    
              <div class="form-group">
                <input
                  formControlName="detalle"
                  placeholder="Detalle"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="card">
                <h5 class="text-center mt-3 mb-1">Puestos de votación</h5>
                <div class="card-body">
                  <div class="list-group">
                    <button type="button" class="list-group-item list-group-item-action" *ngFor="let item of puesto_votacion" (click)="addPuestoSector(item.id,sector_id)">{{item.nombre | titlecase}} <i class="fa fa-chevron-right"></i></button>
                  </div>
                </div>
              </div>
    
              <!-- FIN SUPERVIDOR -->
              <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Actualizar Sector</app-button
              >
            </form>
          </div>
          <div class="col-7 mt-3">
              <!-- SUPERVIDOR -->
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-8"><h4>Abogado supervisor</h4></div>
                    <div class="col-4">
                      <button
                        class="btn btn-sm btn-block btn-primary"
                        (click)="getListUsuarios(5, editForm.value.id, 0)"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#buscarUsuarioModal"
                      >
                        <i class="fa fa-user-plus"></i>
                      </button>
                    </div>
                  </div>
    
                  <div class="card mt-3" *ngFor="let item of supervisor_votacion">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-8" *ngIf="item.usuario.nombre">
                          {{ item.usuario.nombre | titlecase }}
                          <h6>{{ item.usuario.celular }}</h6>
                        </div>
                        <div class="col-4" *ngIf="item.usuario.nombre">
                          <button
                            type="button"
                            class="btn btn-sm btn-block btn-outline-danger"
                            (click)="eliminarTestigo(item.id)">
                            <i class="fa fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>            
            <!-- MESAS -->
            <div class="card bg-secondary">
              <div class="card-body">
                <div class="row mb-4">
                  <div class="col-12">
                    <h3>
                      <div>Puestos de votación asignados</div>
                    </h3>
                  </div>
                </div>
    

    
                <div class="card text-body" *ngFor="let item of puesto_sector">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-10">
                        <h5>
                          <i class="fas fa-vote-yea"></i>
                          {{ item.nombre | titlecase }}
                        </h5>
                      </div>
                      <div class="col-2">
                        <button type="button" class="btn btn-sm btn-danger" (click)="deletePuestoSector(item.puesto_id, item.sector_id,item.id)"><i class="fa fa-times"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <!-- <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Actualizar Puesto</app-button>                        -->
          </div>
        </div>        
        
      </div>

    </div>
  </div>
</div>


<!-- Modal -->
<div
  class="modal fade"
  id="buscarUsuarioModal"
  tabindex="-1"
  aria-labelledby="buscarUsuarioModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Buscar Usuario Abogado</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div>
          <div class="form-group">
            <input [(ngModel)]="buscador" type="text" class="form-control" />
          </div>
          <div *ngIf="!buscador"></div>

          <button
            type="button"
            class="btn btn-block btn-sm btn-secondary"
            (click)="crearUsuarioCard()"
          >
            <i class="fa fa-user"> </i> Crear Usuario
          </button>

          <div class="card" id="nuevoUsuario" style="display: none">
            <div class="body-card">
              <form [formGroup]="nuevoFormUsuario" (ngSubmit)="newUsuario()">
                <div class="col-12 mt-3" id="crearCliente">
                  <div class="form-group">
                    <input
                      formControlName="nombreUser"
                      placeholder="Nombre"
                      type="text"
                      class="form-control"
                    />
                  </div>

                  <div class="form-group">
                    <input
                      formControlName="emailUser"
                      placeholder="Email"
                      type="text"
                      class="form-control"
                    />
                  </div>

                  <div class="form-group">
                    <input
                      formControlName="passwordUser"
                      placeholder="Contraseña"
                      type="text"
                      class="form-control"
                    />
                  </div>

                  <div class="form-group">
                    <input
                      formControlName="celularUser"
                      placeholder="Celular"
                      type="number"
                      class="form-control"
                    />
                  </div>
                  <app-button
                    [type]="'submit'"
                    [block]="true"
                    [loading]="isAuthLoading"
                    >Crear Usuario</app-button
                  >
                </div>
              </form>
            </div>
          </div>

          <ul class="list-group" *ngIf="buscador">
            <button
              *ngFor="let item of listUsuarios | filtroUsuario: buscador"
              type="button"
              class="list-group-item list-group-item-action"
              (click)="selectUsuarioAbogado(item.id, item.perfil_id)"
              data-bs-dismiss="modal"
              data-bs-toggle="modal"
              data-bs-target="#buscarUsuarioModal"
            >
              {{ item.nombre | titlecase }} {{ item.celular | titlecase }}
            </button>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Nueva  -->
<div
  class="offcanvas offcanvas-end"
  tabindex="-1"
  id="offcanvasNuevo"
  aria-labelledby="offcanvasNuevoLabel"
>
  <div class="offcanvas-header">
    <h5 id="offcanvasRightLabel">Nuevo Sector</h5>
    <button
      type="button"
      class="btn-close text-reset"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    ></button>
  </div>
  <div class="offcanvas-body">
    <form [formGroup]="nuevoForm" (ngSubmit)="new()">
      <div class="col-12 mt-3" id="crearCliente">
        <div class="form-group">
          <input
            formControlName="nombre"
            placeholder="Nombre"
            type="text"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <input
            formControlName="detalle"
            placeholder="Detalle"
            type="text"
            class="form-control"
          />
        </div>
        <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading"
          >Crear Sector</app-button
        >
      </div>
    </form>
  </div>
</div>
