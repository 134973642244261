import {Component} from '@angular/core';
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    public user: any = null;
    estadisticas: any = [];
    public cargando = false;

    constructor( public router: Router,private toastr: ToastrService, private appService: AppService, private http: HttpClient) { }
    ngOnInit(): void {
        this.user = this.appService.user;
        // console.log(this.user);
        this.getEstadisticas();        
    }

    getEstadisticas(){
            this.appService.getEstadisticas(this.user.perfil+'-'+this.user.eid).subscribe( (data) => {
            this.estadisticas = data;        
            this.cargando = false;
          });
    }

}
