import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

declare var $: any;

@Component({
  selector: 'app-codigo-iata',
  templateUrl: './codigo-iata.component.html',
  styleUrls: ['./codigo-iata.component.scss']
})
export class CodigoIataComponent implements OnInit {
  public nuevoForm: FormGroup;
  public editForm: FormGroup;
  public isAuthLoading = false;


  public user: any = null;
  reporteExport:any;
  findInput: any;
  codigos: any = [];
  pagesNumber: any;
  offset= 3;
  page= 1;  
  public cargando = false;


  constructor( public router: Router,private toastr: ToastrService, private appService: AppService, private http: HttpClient) { }

  ngOnInit(): void {
    this.user = this.appService.user;
    this.getList();  
    
    this.nuevoForm = new FormGroup({
      codigo: new FormControl(null, Validators.required),
      ciudad: new FormControl(null, Validators.required),
    });   
    
    this.editForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      codigo: new FormControl(null, Validators.required),
      ciudad: new FormControl(null, Validators.required),
    });       

  }

  getList(){
    this.appService.getIata(this.user.et+'-'+this.page).subscribe( (data) => {
    this.codigos = data['iata']; 
    this.ForpagesNumber();
    this.cargando = false;
    });
  }

  changePage(page) {
    this.page = page; //para el filtro
    this.codigos.current = page;
    this.getList();
  }
  
  ForpagesNumber() {
    let from = this.codigos.current - this.offset;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offset * 2); 
    if(to >= this.codigos.total_page){
      to = this.codigos.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

  async new() {     
    if (this.nuevoForm.valid) {
        this.isAuthLoading = true; 
          let params = {
              nuevo:true,
              et: this.user.et,
              codigo: this.nuevoForm.value.codigo,
              ciudad: this.nuevoForm.value.ciudad,
              estado: 1,
            };
            // console.log(params);
            this.appService.postIata(params)
          .subscribe( (data) => {
            // console.log(data);
              if (data["logger"] == true){
                  this.nuevoForm.reset()
                  $('#offcanvasNuevaUsuario').offcanvas('hide');
                  this.toastr.success(data["msg"]);
                  this.getList();
              }else{
                  this.toastr.error(data["msg"]);
              }
          }); 
        this.isAuthLoading = false;
    } else {
        this.toastr.error('El formulario no es válido!');
    }
  }


  ver(item){
    // console.log(item);
    this.editForm.get('id').setValue(item.id);
    this.editForm.get('codigo').setValue(item.codigo);
    this.editForm.get('ciudad').setValue(item.ciudad);
    $('#offcanvasVer').offcanvas('show');
  }

  async edit(){
    if (this.editForm.valid) {
      this.isAuthLoading = true; 
        let params = {
            editar:true,
            id: this.editForm.value.id,
            codigo: this.editForm.value.codigo,
            ciudad: this.editForm.value.ciudad,
          };
          // console.log(params);
          this.appService.postIata(params)
        .subscribe( (data) => {
            if (data["logger"] == true){
              this.toastr.success(data["msg"]);
              this.editForm.reset()
              $('#offcanvasVer').offcanvas('hide');
              this.getList();
            }else{
              this.toastr.error(data["msg"]);
            }
        }); 
      this.isAuthLoading = false;
  } else {
      this.toastr.error('El formulario no es válido!');
  }
  }

  async eliminar(item){
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea eliminar este registro?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        // console.log('dentro true');
        
        let params = {
          eliminar : true,
          id: item.id,
          empresa_id: this.user.eid
        }
        this.appService.postIata(params)
        .subscribe( (data) => {
            if (data["logger"] == true){
                this.getList();
                this.toastr.success(data["msg"]);
            }else{
                this.toastr.error(data["msg"]);
            }
        });         

      }
    });  

  }


  async find() {     
    let params = {
        buscar:true,
        buscar_input: this.findInput,
        page: this.page,
        et: this.user.et,
      };
        this.appService.postIata(params)
        .subscribe( (data) => {
            if (data["logger"] == true){
              this.codigos = data['obj']; 
            }else{
              this.toastr.error(data["msg"]);
            }
        });
  }

  async exportCliente() {     
    let params = {
        export:true,
        et: this.user.et,
      };
        this.appService.postClientes(params)
        .subscribe( (data) => {
          console.log(data['clientes']);
          
            if (data["logger"] == true){
              this.reporteExport = data['clientes'];

              const fileName = 'clientes.xlsx';
              const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.reporteExport);
        
              // ANCHO COLUMNAS
              ws['!cols'] = [ {width:2},{width:8},{width:35}, {width:35},{width:20},{width:20},{width:20},{width:20},{width:20},{width:20}];
              // ELIMINA COLUMNA1
              ws['!cols'][0] = { hidden: true };
              // ws['!cols'][1] = { hidden: true };
              // ws['!cols'][13] = { hidden: true };
              // ws['!cols'][14] = { hidden: true };
              // ws['!cols'][18] = { hidden: true };
              // // ws['G2'] = { Target:"#E2" };
              const wb: XLSX.WorkBook = XLSX.utils.book_new();
              // CAMBIA CABECERA
              XLSX.utils.sheet_add_aoa(ws, [["log","ID","NOMBRE","EMAIL","CELULAR","CIUDAD","NOMBRE"]]);
              XLSX.utils.sheet_add_json(ws, this.reporteExport, { origin: 'A2', skipHeader: true });  
        
              XLSX.utils.book_append_sheet(wb, ws, 'reporte');
              XLSX.writeFile(wb, fileName);

            }else{
              this.toastr.error(data["msg"]);
            }
        });
  }  



}
