<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-5">
                <h1>
                  Códigos IATA
                  <button class="btn btn-sm btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNuevaUsuario" aria-controls="offcanvasNuevaUsuario"><i class="fa fa-plus"></i></button>
                  <!-- <button class="btn btn-sm btn-success ml-1" type="button" (click)="exportCliente()"><i class="fa fa-file-excel"></i></button> -->
                </h1>
              </div>
              <div class="col-7">
                <div class="input-group mb-0">
                  <input type="text" class="form-control" [(ngModel)]="findInput" placeholder="Buscador">
                  <button class="btn btn-secondary" type="button"  id="button-addon2" (click)="find()"><i class="fa fa-search"></i></button>
                </div>                  
              </div>
            </div>

          </div>
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">

                  <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                  <li class="breadcrumb-item active">Códigos IATA</li>
              </ol>
          </div>
      </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content m-2">
  <div class="card">
    <div class="card-body table-responsive p-0">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">CÓDIGO</th>
            <th scope="col">CIUDAD</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of codigos.items">
            <td style="cursor: pointer;" (click)="ver(item)">{{item.codigo}}</td>
            <td>{{item.ciudad}}</td>
            <td>
              
              <div class="dropdown">
                <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"></button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><span class="dropdown-item"  style="cursor: pointer;" (click)="ver(item)">Ver Código IATA</span></li>
                  <li><span class="dropdown-item text-danger" style="cursor: pointer;" (click)="eliminar(item)">Eliminar</span></li>
                </ul>
              </div>           

            </td>
          </tr>
        </tbody>
      </table>      
    </div>
  </div>

  <!-- paginador -->
  <div class="card-footer clearfix">
    <ul class="pagination pagination-sm m-0 float-right">
      <li class="page-item" *ngIf="codigos.current > 1">
        <button type="button" class="page-link" (click)="changePage(codigos.current - 1)">
          <span>Atras</span>
        </button>
      </li>                  

      <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': codigos.current == page}">
        <button type="button" class="page-link" (click)="changePage(page)">
          {{ page }}
        </button>
      </li>                  

      <li class="page-item" *ngIf="codigos.current < codigos.total_page">
        <button type="button" class="page-link" (click)="changePage(codigos.next)">
          <span>Siguiente</span>
        </button>
      </li>                  
    </ul>
    <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
      <p>Total registros encontrados: <b>{{codigos.size}}</b></p>
    </div>
  </div>              
  <!-- Fin paginador -->  
</section>




  <!-- Modal Ver  -->
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasVer" aria-labelledby="offcanvasVerLabel">
      <div class="offcanvas-header">
        <h5 id="offcanvasRightLabel">Código IATA</h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <form [formGroup]="editForm" (ngSubmit)="edit()">
          <div class="row">
            <div class="col-12 mt-3">
                  <div class="form-group">
                    <input formControlName="codigo" placeholder="Codigo"  type="text" class="form-control"/>
                  </div> 

                  <div class="form-group"> 
                    <input formControlName="ciudad" placeholder="Ciudad" type="text" class="form-control"/>
                  </div>  

                  <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Actualizar Código IATA</app-button>                       
            </div> 
          </div>

        </form>
      </div>
    </div>



  <!-- Modal Nueva  -->
  <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNuevaUsuario" aria-labelledby="offcanvasNuevaUsuarioLabel">
    <div class="offcanvas-header">
      <h5 id="offcanvasRightLabel">Nuevo Código IATA</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">

      <form [formGroup]="nuevoForm" (ngSubmit)="new()">
        <div class="col-12 mt-3" id="crearCliente">
            <div class="form-group">
              <input formControlName="codigo" placeholder="Código" type="text" class="form-control"/>
            </div> 
            <div class="form-group">
              <input formControlName="ciudad" placeholder="Ciudad" type="text" class="form-control"/>
            </div>                                     
            <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Crear Código IATA</app-button>                       
        </div> 
      </form>
    </div>
  </div>
