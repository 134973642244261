import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {LoginComponent} from '@modules/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {PrivacyPolicyComponent} from '@modules/privacy-policy/privacy-policy.component';
import {MainMenuComponent} from '@pages/main-menu/main-menu.component';
import {SubMenuComponent} from '@pages/main-menu/sub-menu/sub-menu.component';
import { EmpresasComponent } from '@pages/empresas/empresas.component';
import { SolicitudesComponent } from '@pages/solicitudes/solicitudes.component';
import { VisasComponent } from '@pages/visas/visas.component';
import { UsuariosComponent } from '@pages/usuarios/usuarios.component';
import { FormSolicitudComponent } from '@modules/form-solicitud/form-solicitud.component';
import { ClientesComponent } from '@pages/clientes/clientes.component';
import { ReservasComponent } from '@pages/reservas/reservas.component';
import { ProveedoresComponent } from '@pages/proveedores/proveedores.component';
import { CodigoIataComponent } from '@pages/codigo-iata/codigo-iata.component';
import { CandidatosComponent } from '@pages/candidatos/candidatos.component';
import { PuestoVotacionComponent } from '@pages/puesto-votacion/puesto-votacion.component';
import { VotacionComponent } from '@pages/votacion/votacion.component';
import { SectoresComponent } from '@pages/sectores/sectores.component';
import { InscripcionComponent } from '@pages/inscripcion/inscripcion.component';
import { EscrutinioComponent } from '@pages/escrutinio/escrutinio.component';

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            { path: 'profile', component: ProfileComponent },
            { path: 'blank', component: BlankComponent },
            { path: 'sub-menu-1', component: SubMenuComponent },
            { path: 'sub-menu-2', component: BlankComponent },
            { path: '', component: DashboardComponent},
            { path: 'empresas',component: EmpresasComponent},
            { path: 'solicitudes',component: SolicitudesComponent},
            { path: 'visas',component: VisasComponent},
            { path: 'usuarios',component: UsuariosComponent},
            { path: 'clientes',component: ClientesComponent},
            { path: 'reservas',component: ReservasComponent},
            { path: 'proveedores',component: ProveedoresComponent},
            { path: 'codigo_iata',component: CodigoIataComponent},
            { path: 'candidatos',component: CandidatosComponent},
            { path: 'puesto-votacion',component: PuestoVotacionComponent},
            { path: 'votacion',component: VotacionComponent},
            { path: 'sectores',component: SectoresComponent},
            { path: 'inscripcion',component: InscripcionComponent},
            { path: 'escrutinio',component: EscrutinioComponent},
        ]
    },
    
    { path: 'login',component: LoginComponent,canActivate: [NonAuthGuard]},
    { path: 'register',component: RegisterComponent,canActivate: [NonAuthGuard]},
    { path: 'forgot-password',component: ForgotPasswordComponent,canActivate: [NonAuthGuard]},
    { path: 'recover-password',component: RecoverPasswordComponent,canActivate: [NonAuthGuard]},
    { path: 'privacy-policy',component: PrivacyPolicyComponent,canActivate: [NonAuthGuard]},
    { path: 'solicitud/:sigla',component: FormSolicitudComponent,canActivate: [NonAuthGuard]},
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
