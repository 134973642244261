import { HttpClientModule } from '@angular/common/http';
import {Component} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
    public user: any = null;
    public editForm: FormGroup;
    public loadingImg = false;
    public isAuthLoading = false;


    constructor( public router: Router, private appService: AppService,private toastr: ToastrService, private http: HttpClientModule) { }

    ngOnInit(): void {
        this.user = this.appService.user;  
        console.log(this.user);

        this.editForm = new FormGroup({
            id: new FormControl(null, Validators.required),
            fotografia: new FormControl(null, Validators.nullValidator),
            fotografia_url: new FormControl(null, Validators.nullValidator),      
        });         

        this.editForm.get('fotografia_url').setValue(this.user.fotografia);  

      }
      
      
      async editUsuario(){    
          this.isAuthLoading = true; 
            let params = {
                editarImg:true,
                et: this.user.token,
                fotografia: this.editForm.value.fotografia,
              };
              this.appService.postUsuario(params)
            .subscribe( (data) => {
                if (data["logger"] == true){
                    // console.log(data);                    
                }else{
                    this.toastr.error(data["msg"]);
                }
            }); 
          this.isAuthLoading = false;
      }

      preview(files,componente) {
        if (files.length === 0)
          return;   
        this.loadingImg =true;
        var reader = new FileReader();
        reader.readAsDataURL(files[0]); 
        reader.onload = (_event) => { 
          const imgURL = reader.result; 
        //   console.log("esta es la imagen"+imgURL);
    
          if(!imgURL) {
            console.log('error');
            return;
          } else {
            let params = {
              folder: 'usuarios',
              archivo: imgURL
            };
            // this.cargandoImg = true;
            this.appService.uploadImg(params)
            .subscribe( (data) => {
              if(data['logger'] == true){
                if(componente =='nuevo'){
                  this.editForm.get('fotografia').setValue(data['filename']);
                  this.editForm.get('fotografia_url').setValue(data['fileUrl']);  
                }
                if(componente =='editar'){
                  this.editForm.get('fotografia').setValue(data['filename']);
                  this.editForm.get('fotografia_url').setValue(data['fileUrl']);                
                } 
                                
                this.editUsuario();                
            
              }
              this.loadingImg =false;
            });
          }      
        }
    
      }      
}
