import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroIata'
})
export class FiltroIataPipe implements PipeTransform {

  transform(value: any, arg: any): any {  
    const resultPosts= [];
    for (const post of value) {
      if(post.ciudad.indexOf(arg.toUpperCase()) > -1){ 
        resultPosts.push(post);
      }; 
    };
    return resultPosts;
  }

}
