<!-- Main content -->
<section class="content m-2">
  <div class="row">

    <div class="col-lg-2 col-2">
      <!-- small box -->
      <div class="small-box bg-success">
          <div class="inner">
              <h3> <i class="fa fa-file-download"></i></h3>
              <p (click)="exportAll()" style="cursor: pointer;">Descargar</p>
          </div>
          <div class="icon">
              <i class="fa fa-users"></i>
          </div>
          <div class="small-box-footer"></div>
      </div>
    </div>
    <div class="col-lg-3 col-3">
      <!-- small box -->
      <div class="small-box bg-success">
          <div class="inner">
              <h3>{{countHabilitados}}</h3>
              <p>Cedulas Registradas</p>
          </div>
          <div class="icon">
              <i class="ion ion-person-add"></i>
          </div>
          <div class="small-box-footer"></div>
      </div>
    </div>

    <div class="col-lg-3 col-3">
        <!-- small box -->
      <div class="small-box bg-warning">
          <div class="inner">
              <h3>{{countDuplicados}}</h3>
              <p>Registros duplicados</p>
          </div>
          <div class="icon">
              <i class="fa fa-clone"></i>
          </div>
          <div class="small-box-footer"></div>
      </div>
    </div>

    <div class="col-lg-3 col-3">
        <!-- small box -->
      <div class="small-box bg-danger">
          <div class="inner">
              <h3>{{countErrados}}</h3>
              <p>Registros Errados</p>
          </div>
          <div class="icon">
              <i class="fa fa-exclamation-triangle"></i>
          </div>
          <div class="small-box-footer"></div>
      </div>
    </div>
    

    <div class="col-lg-6 col-6">
      <div><button class="btn btn-sm btn-block btn-primary" data-bs-toggle="modal" data-bs-target="#nuevo">Recolectores</button></div>

      <div class="card" *ngIf="!selectRecolector">
        <div class="card-body">
          <div class="text-center"><h5>Buscar Recolector</h5></div>
          <div>
            <div class="row">
              <div class="col-md-12 text-center">
                <div class="input-group  input-group-lg mb-3">
                  <input type="text" class="form-control text-center" [(ngModel)]="buscarRecolector"  placeholder="Nombre">
                  <button [disabled]="!buscarRecolector" class="btn btn-primary" type="button" (click)="findRecolector()" id="button-addon2"><i class="fa fa-search"></i></button>
                </div>
              </div>
            </div>
            <div *ngIf="isAuthLoading" class="text-center">
              <h5>
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div> Buscando..
              </h5>
            </div>
          </div>
          <div class="list-group">
            <div *ngFor="let item of recolectoresList" class="list-group-item list-group-item-action" style="cursor: pointer;" (click)="selectRecolector = item.id; selectRecolectorNombre = item.nombre">
              <div class="row">
                <div class="col-6">{{item.nombre | titlecase}}</div>
                <div class="col-2 text-success text-center"><i class="fa fa-check-circle"></i> {{item.countOK }}</div>
                <div class="col-2 text-warning text-center"><i class="fa fa-copy"></i> {{item.countDuplicado }}</div>
                <div class="col-2 text-danger text-center"><i class="fa fa-exclamation-triangle"></i> {{item.countErrada }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="card mt-3" *ngIf="selectRecolector">
        <div class="card-body">
          <div class="text-center"><h5>Digite cédula de ciudadanía</h5></div>
          <div class="row">
            <div class="col-md-12 text-center">
              <div class="input-group  input-group-lg mb-3">
                <input type="text" class="form-control text-center" [(ngModel)]="documento">
                <button class="btn btn-primary" type="button" (click)="buscarDocumento()" id="button-addon2"><i class="fa fa-search"></i></button>
              </div>
            </div>
          </div>
          <div class="text-center"><h6>Recolector seleccionado <b>{{selectRecolectorNombre | titlecase}}</b></h6></div>
          <div class="text-center"><button class="btn btn-sm btn-outline-secondary" (click)="selectRecolector = ''; selectRecolectorNombre = ''">Cambiar Recolector</button></div>
        </div>
      </div>

      <div class="card mt-3">
        <div class="card-header">
          <div class="text-center"><h5>Ultimos registros</h5></div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Cédula</th>
                    <th scope="col">Nombre</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr  *ngFor="let item of ultimosDatos">
                    <th scope="row">{{item.cedula}}</th>
                    <td>{{item.nombre1}} {{item.nombre2}} {{item.apellido1}} {{item.apellido2}}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
   

    <div class="col-lg-6 col-6" *ngIf="datos.cedula">
      <div class="card mt-3">
        <div class="card-header">
          <div class="text-center"><h5>Información consulta</h5></div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 text-center" style="font-size: 1.2em;">
              <label>Nombre completo</label>
              <div>{{datos.nombre1}} {{datos.nombre2}} {{datos.apellido1}} {{datos.apellido2}}</div>

              <label>Cédula</label>
              <div>{{datos.cedula}}</div>

              <label>Fecha nacimiento</label>
              <div>{{datos.fchn}}</div>

              <label>Sexo</label>
              <div>{{datos.sexo}}</div>

              <label>Puesto votación</label>
              <div>{{datos.puesto}}</div>

            </div>

          </div>
        </div>
        <div class="card-footer text-center">
          <h5>Desea agregar este registro a la lista de Inscripción</h5>
          <div class="btn-group mt-3 text-center" role="group">
            <button type="button" class="btn btn-success" (click)="agregar(1)">Si, Agregar</button>
            <button type="button" class="btn btn-warning" (click)="agregar(2)">Información no inconside</button>
            <button type="button" class="btn btn-danger" (click)="datos.cedula = null; documento =null">Cancelar</button>
          </div>          
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-6" *ngIf="datos.status ==0">
      <div class="card mt-3">
        <div class="card-header">
          <div class="text-center"><h5>Información consulta</h5></div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 text-center">
              <i class="fa fa-exclamation-triangle" style="font-size:4em;color: #afafaf;"></i>
              <h4>Cédula <b>{{datos.busqueda}}</b><br> no esta habilitada para votar en la zona</h4>
            </div>

          </div>
        </div>

      </div>
    </div>


  </div>


    <!-- Modal Nueva  -->
    <div class="modal fade" id="nuevo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Nuevo Recolector</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
  
              <form [formGroup]="nuevoForm" (ngSubmit)="nuevoRecolector()">
                <div class="col-12 mt-3" id="crearCliente">
                      <div class="form-group">
                        <input formControlName="nombre" placeholder="Nombre" type="text" class="form-control"/>
                      </div> 

                      <div class="form-group">
                        <input formControlName="celular" placeholder="Celular" type="text" class="form-control"/>
                      </div>                     
  
                      <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Crear</app-button>                       
                </div> 
  
              </form>
                      
            </div>
         
          </div>
        </div>
      </div>
    </div>

</section>


