import { Component, OnInit, OnDestroy, Renderer2, HostBinding } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DateTime } from 'luxon';
import { AppService } from '@services/app.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

    // HostBinding para establecer clase en el componente raíz de la aplicación 
    @HostBinding('class') class = 'login-box';

    // Obtener año actual 
    public currentYear: string = DateTime.now().toFormat('y');

    // Formulario de inicio de sesión 
    public loginForm: FormGroup;

    // Estado de carga de autenticación 
    public isAuthLoading = false;

    constructor(private renderer: Renderer2, private toastr: ToastrService, private appService: AppService) { }

    ngOnInit() {

        // Agregar clase al componente raíz 
        this.renderer.addClass(document.querySelector('app-root'), 'login-page-new');

        // Inicializar formulario de inicio de sesión 
        this.loginForm = new FormGroup({ email: new FormControl(null, Validators.required), password: new FormControl(null, Validators.required) });
    }

    async loginByAuth() {
        if (this.loginForm.valid) {
            this.isAuthLoading = true;
            await this.appService.Login(this.loginForm.value);
            this.isAuthLoading = false;
        } else {
            this.toastr.error('El formulario no es válido!');
        }
    }


    ngOnDestroy() {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'login-page-new'
        );
    }
}
