import {Component, OnInit} from '@angular/core';
import {AppService} from '@services/app.service';
import { userInfo } from 'os';

@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    public user;
    public menu = MENU;

    constructor(public appService: AppService) {}

    ngOnInit() {
        this.user = this.appService.user;   
    }
}

export const MENU = [
    { name: 'Operaciones',path: ['/'], icon: 'fa-tachometer-alt',perfil: '15kwqgk3123'},
    { name: 'Empresas',path: ['/empresas'], icon: 'fa-city', perfil: '15kwqgk3123'},

    { name: 'Operaciones',path: ['/'], icon: 'fa-tachometer-alt',perfil: '2fk6i2o3143'},    
    { name: 'inscripción',path: ['/inscripcion'], icon: 'fa-user-plus', perfil: '2fk6i2o3143'},
    { name: 'Votación',path: ['/votacion'], icon: 'fa-vote-yea', perfil: '2fk6i2o3143'},
    { name: 'Sectores',path: ['/sectores'], icon: 'fa-umbrella-beach', perfil: '2fk6i2o3143'},
    { name: 'Escrutinio',path: ['/escrutinio'], icon: 'fa-search-plus', perfil: '2fk6i2o3143'},
    { name: 'Candidatos',path: ['/candidatos'], icon: 'fa-user-tie', perfil: '2fk6i2o3143'},
    { name: 'Puesto Votación',path: ['/puesto-votacion'], icon: 'fa-couch', perfil: '2fk6i2o3143'},

    // { name: 'Solicitudes',path: ['/solicitudes'], icon: 'fa-calendar-check', perfil: '2fk6i2o3143'},
    // { name: 'Reservas',path: ['/reservas'], icon: 'fa-money-check', perfil: '2fk6i2o3143'},
    // { name: 'Visas',path: ['/visas'], icon: 'fa-passport',  perfil: '2fk6i2o3143'},
    // { name: 'Proveedores',path: ['/proveedores'], icon: 'fa-boxes',  perfil: '2fk6i2o3143'},
    // { name: 'IATA',path: ['/codigo_iata'], icon: 'fa-code',  perfil: '2fk6i2o3143'},
    // { name: 'Clientes',path: ['/clientes'], icon: 'fa-user-friends',  perfil: '2fk6i2o3143'},
    { name: 'Usuarios',path: ['/usuarios'], icon: 'fa-users',  perfil: '2fk6i2o3143'},

    { name: 'Operaciones',path: ['/'], icon: 'fa-tachometer-alt',perfil: '3af3s12f153'},
    
    { name: 'Operaciones',path: ['/'], icon: 'fa-tachometer-alt',perfil: '4af4s12f154'},
    { name: 'sector',path: ['/sectores'], icon: 'fa-umbrella-beach', perfil: '4af4s12f154'},
    
    
    { name: 'Operaciones',path: ['/'], icon: 'fa-tachometer-alt',perfil: '5AFC4A35f555'},
    // { name: 'Escrutinio',path: ['/escrutinio'], icon: 'fa-search-plus', perfil: '5AFC4A35f555'},

    
    { name: 'Operaciones',path: ['/'], icon: 'fa-tachometer-alt',perfil: '6DTC6A445FJK5'},
    { name: 'inscripción',path: ['/inscripcion'], icon: 'fa-user-plus', perfil: '6DTC6A445FJK5'},
    
    // { name: 'Proveedores',path: ['/proveedores'], icon: 'fa-boxes',  perfil: '3af3s12f153'},
    // { name: 'IATA',path: ['/codigo_iata'], icon: 'fa-code',  perfil: '3af3s12f153'},
    // { name: 'Clientes',path: ['/clientes'], icon: 'fa-user-friends',  perfil: '3af3s12f153'},
    // { name: 'Main Menu', icon: 'fa-list-ul',
    //     children: [
    //         {
    //             name: 'Sub Menu',
    //             path: ['/sub-menu-1']
    //         },

    //         {
    //             name: 'Blank',
    //             path: ['/sub-menu-2']
    //         }
    //     ]
    // }
];
