<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-7">
                <h1><i class="fas fa-users"></i>  Usuarios <button class="btn btn-sm btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#nuevaUsuario"><i class="fa fa-plus"></i></button></h1>
              </div>
              <div class="col-5">
                <div class="input-group mb-0">
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="findInput"
                    placeholder="Buscador"
                  />
                  <button
                    class="btn btn-secondary"
                    type="button"
                    id="button-addon2"
                    (click)="find()"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                  <li class="breadcrumb-item active">Usuarios</li>
              </ol>
          </div>
      </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content m-2">


  <div class="card">
    <div class="card-body table-responsive p-0">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Nombre</th>
            <th scope="col">Email</th>
            <th scope="col">Perfil</th>
            <th scope="col">Estado</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of usuarios.items">
            <td data-bs-toggle="modal" data-bs-target="#verUsuario" style="cursor: pointer;" (click)="verUsuario(item)">
              <img *ngIf="item.fotografia_url" width="36px"
              [src]="item.fotografia_url"
              class="user-image img-circle elevation-2"
              alt="User Image"/>              
            </td>
            <td style="cursor: pointer;font-size: 1.2em;" data-bs-toggle="modal" data-bs-target="#verUsuario" style="cursor: pointer;" (click)="verUsuario(item)">{{item.nombre | titlecase}}</td>
            <td>{{item.email}}</td>
            <td>
              <span *ngIf="item.perfil_id ==6">Logistico</span> <span *ngIf="item.perfil_id ==5">Abogado</span> <span *ngIf="item.perfil_id ==4">Testigo</span> <span *ngIf="item.perfil_id ==3">Supervisor</span> <span *ngIf="item.perfil_id ==2">Administrador</span>
            </td>
            <td><span class="badge bg-success" *ngIf="item.estado ==1">Activo</span></td>
            <td>
              
              <div class="dropdown">
                <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"></button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><span class="dropdown-item" data-bs-toggle="modal" data-bs-target="#verUsuario" style="cursor: pointer;" (click)="verUsuario(item)">Ver Usuario</span></li>
                  <li><span class="dropdown-item text-danger" style="cursor: pointer;" (click)="eliminarUsuario(item)">Eliminar</span></li>
                </ul>
              </div>           

            </td>
          </tr>
        </tbody>
      </table>      
    </div>
  </div>

  <!-- paginador -->
  <div class="card-footer clearfix">
    <ul class="pagination pagination-sm m-0 float-right">
      <li class="page-item" *ngIf="usuarios.current > 1">
        <button type="button" class="page-link" (click)="changePage(usuarios.current - 1)">
          <span>Atras</span>
        </button>
      </li>                  

      <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': usuarios.current == page}">
        <button type="button" class="page-link" (click)="changePage(page)">
          {{ page }}
        </button>
      </li>                  

      <li class="page-item" *ngIf="usuarios.current < usuarios.total_page">
        <button type="button" class="page-link" (click)="changePage(usuarios.next)">
          <span>Siguiente</span>
        </button>
      </li>                  
    </ul>
    <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
      <p>Total registros encontrados: <b>{{usuarios.size}}</b></p>
    </div>
  </div>              
  <!-- Fin paginador -->  

</section>


  <!-- Modal Nueva  -->
  <div class="modal fade" id="nuevaUsuario" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Nuevo Usuario</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">

            <form [formGroup]="nuevoForm" (ngSubmit)="newUsuario()">
              <div class="col-12 mt-3" id="crearCliente">
                    <div class="form-group">
                      <input formControlName="nombre" placeholder="Nombre" type="text" class="form-control"/>
                    </div> 
                    
                    <div class="form-group">
                      <input formControlName="email" placeholder="Email" type="text" class="form-control"/>
                    </div> 
                    
                    <div class="form-group">
                      <input formControlName="password" placeholder="Contraseña" type="text" class="form-control"/>
                    </div> 

                    <div class="form-group">
                      <input formControlName="celular" placeholder="Celular" type="text" class="form-control"/>
                    </div>                     

                    <div class="form-group">
                      <select formControlName="perfil_id" class="form-control" placeholder="Seleccione Perfil">
                        <!-- <option value="2">Administrador</option> -->
                        <option value="3">Supervisor</option>
                        <option value="5">Abogado</option>
                        <option value="6">Logistico</option>
                        <option value="4">Testigo</option>
                    </select>                      
                    </div>

                    <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Crear Usuario</app-button>                       
              </div> 

            </form>
                    
          </div>
       
        </div>
      </div>
    </div>
  </div>


  <!-- Modal Ver  -->
  <div class="modal fade" id="verUsuario" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Usuario</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">

            <form [formGroup]="editForm" (ngSubmit)="editUsuario()">
              <div class="row">
                <div class="col-3 mt-3">
                  <img *ngIf="editForm.value.fotografia_url"
                  [src]="editForm.value.fotografia_url"
                  class="user-image elevation-2 img-fluid"
                  alt="User Image"/>
                </div>
                <div class="col-9 mt-3">
                      <div class="form-group">
                        <input formControlName="nombre" placeholder="Nombre" type="text" class="form-control"/>
                      </div> 
                      
                      <div class="form-group">                        
                        <h4>{{editForm.value.email}}</h4>
                      </div> 
                      
                      <div class="form-group">
                        <input formControlName="password" placeholder="Contraseña" type="text" class="form-control"/>
                      </div>

                      <div class="form-group">
                        <input formControlName="celular" placeholder="Celular" type="text" class="form-control"/>
                      </div>                        
  
                      <div class="form-group">
                        <select formControlName="perfil_id" class="form-control" placeholder="Seleccione Perfil">
                          <!-- <option value="2">Administrador</option> -->
                          <option value="3">Supervisor</option>
                          <option value="5">Abogado</option>
                          <option value="6">Logistico</option>
                          <option value="4">Testigo</option>
                        </select>                      
                      </div>
  
                      <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Actualizar Usuario</app-button>                       
                </div> 
              </div>

            </form>
                    
          </div>
        
        </div>
      </div>
    </div>
  </div>
