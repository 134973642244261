import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

declare var $: any;

@Component({
  selector: 'app-reservas',
  templateUrl: './reservas.component.html',
  styleUrls: ['./reservas.component.scss']
})
export class ReservasComponent implements OnInit {
  public nuevoForm: FormGroup;
  public editForm: FormGroup;
  public nuevoClienteForm: FormGroup;  
  public nuevoPasajeroForm: FormGroup;
  public nuevoVueloForm: FormGroup;
  public editPasajeroForm: FormGroup;

  public isAuthLoading = false;


  public user: any = null;
  reporteExport:any;
  findInput: any;
  tarjeta_reserva: any = [];
  pagesNumber: any;
  offset= 3;
  page= 1;  
  public cargando = false;

  buscador: any;
  listClientes: any[];
  listPasajeros: any[];
  listIata: any[];
  listProveedores: any[];
  listProveedoresActividades: any[];

  selectPasajeros = [];
  selectVuelos = [];
  selectProveedorActividades = [];
  
  contacto_nombre: any;
  contacto_celular: any;
  seguro_viaje: any;
  seguro_viaje_nota: any;
  referido: any;
  usuario_id: any;



  constructor( public router: Router,private toastr: ToastrService, private appService: AppService, private http: HttpClient) { }
  ngOnInit(): void {
    this.user = this.appService.user;
    // console.log(this.user);
    this.getList(); 
    this.getListClientes();
    this.getListPasajeros();    
    this.getListIata();    
    this.getListProveedoresActividades();    

    
    this.nuevoForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.nullValidator),
      cc: new FormControl(null, Validators.nullValidator),
      email: new FormControl(null, Validators.nullValidator),
      direccion: new FormControl(null, Validators.nullValidator),
      celular: new FormControl(null, Validators.nullValidator),
      ciudad: new FormControl(null, Validators.nullValidator),
      nota: new FormControl(null, Validators.nullValidator),
    });   
    
    this.editForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.required),
      cc: new FormControl(null, Validators.nullValidator),
      email: new FormControl(null, Validators.nullValidator),
      direccion: new FormControl(null, Validators.nullValidator),      
      celular: new FormControl(null, Validators.nullValidator),  
      ciudad: new FormControl(null, Validators.nullValidator),
    });  
    
    this.nuevoClienteForm = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      cc: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      direccion: new FormControl(null, Validators.required),
      celular: new FormControl(null, Validators.required),
    });

    this.nuevoPasajeroForm = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      cc: new FormControl(null, Validators.required),
      adulto: new FormControl(null, Validators.required),
      fecha_nacimiento: new FormControl(null, Validators.required),
      nota: new FormControl(null, Validators.nullValidator),
    }); 
    
    this.nuevoVueloForm = new FormGroup({
      cia: new FormControl(null, Validators.required),
      vuelo: new FormControl(null, Validators.nullValidator),
      origen: new FormControl(null, Validators.required),
      destino: new FormControl(null, Validators.required),
      clase: new FormControl(null, Validators.nullValidator),
      dia: new FormControl(null, Validators.nullValidator),
      mes: new FormControl(null, Validators.nullValidator),
      sale: new FormControl(null, Validators.nullValidator),
      llega: new FormControl(null, Validators.nullValidator),
    });     

    this.editPasajeroForm= new FormGroup({
      id: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.required),
      cc: new FormControl(null, Validators.required),
      adulto: new FormControl(null, Validators.required),
      fecha_nacimiento: new FormControl(null, Validators.required),
      nota: new FormControl(null, Validators.nullValidator),
    }); 

  }


  getList(){
    this.appService.getTarjetaReserva(this.user.et+'-'+this.page).subscribe( (data) => {
    // console.log(data['tarjeta_reserva']);
    
    this.tarjeta_reserva = data['tarjeta_reserva']; 
    this.ForpagesNumber();
    this.cargando = false;
    });
  }

  getListClientes(){
    this.appService.getClientes(this.user.et).subscribe( (data) => {
      this.listClientes = data['clientes'].items;                 
      // console.log(this.listClientes);   
        this.cargando = false;
    });
  }

  getListPasajeros(){
    this.appService.getPasajeros(this.user.et).subscribe( (data) => {
      this.listPasajeros = data['pasajeros'].items;                 
      // console.log(this.listPasajeros);   
        this.cargando = false;
    });
  }  

  getListIata(){
    let params = {
      get:true,
      et: this.user.et,            
    };
    this.appService.postIata(params).subscribe( (data) => {
      this.listIata = data['iata'];                 
      // console.log(this.listIata);   
        this.cargando = false;
    });
  }  
  
  getListProveedoresActividades(){
    let params = {
      get:true,
      et: this.user.et,            
    };
    this.appService.postProveedorActividad(params).subscribe( (data) => {
      this.listProveedoresActividades = data['actividades'];   
        this.cargando = false;
    });    
  }



  selectCliente(item){
    this.buscador = null;
    this.nuevoForm.get('id').setValue(item.id);
    this.nuevoForm.get('nombre').setValue(item.nombre);
    this.nuevoForm.get('cc').setValue(item.cc);
    this.nuevoForm.get('email').setValue(item.email);    
    this.nuevoForm.get('direccion').setValue(item.direccion);    
    this.nuevoForm.get('celular').setValue(item.celular);  
    console.log(item);      
  }


  createCliente(buscador){
    this.buscador = null;
    $('#crearCliente').show();
  } 
  

  async newCliente(){     
    if (this.nuevoClienteForm.valid) {
        this.isAuthLoading = true; 
          let params = {
              nuevo:true,
              nombre: this.nuevoClienteForm.value.nombre,
              cc: this.nuevoClienteForm.value.cc,
              celular: this.nuevoClienteForm.value.celular,
              direccion: this.nuevoClienteForm.value.direccion,
              email: this.nuevoClienteForm.value.email,
              et: this.user.et,            
            };
            // console.log(params);
            this.appService.postClientes(params)
          .subscribe( (data) => {
              if (data["logger"] == true){
                this.nuevoForm.get('id').setValue(data["data"].id);
                this.nuevoForm.get('nombre').setValue(data["data"].nombre);
                this.nuevoForm.get('cc').setValue(data["data"].cc);    
                this.nuevoForm.get('email').setValue(data["data"].email);    
                this.nuevoForm.get('direccion').setValue(data["data"].direccion); 
                this.nuevoForm.get('celular').setValue(data["data"].celular); 
                // this.router.navigate(['/']);
                  this.nuevoClienteForm.reset()
                  $('#crearCliente').hide();
                  this.getListClientes();
              }else{
                  this.toastr.error(data["msg"]);
              }
          }); 
        this.isAuthLoading = false;
    } else {
        this.toastr.error('El formulario no es válido!');
    }
  } 
  
  
  createPasajero(buscador){
    this.buscador = null;
    $('#crearPasajero').show();
  } 

  async newPasajero(){             
    if (this.nuevoPasajeroForm.valid) {

        this.isAuthLoading = true; 
          let params = {
              nuevo:true,
              nombre: this.nuevoPasajeroForm.value.nombre,
              cc: this.nuevoPasajeroForm.value.cc,
              adulto: this.nuevoPasajeroForm.value.adulto,
              fecha_nacimiento: this.nuevoPasajeroForm.value.fecha_nacimiento,
              nota: this.nuevoPasajeroForm.value.nota,
              et: this.user.et,            
            };
            // console.log(params);
            this.appService.postPasajeros(params)
          .subscribe( (data) => {
              if (data["logger"] == true){
                // this.selectPasajeros.push(this.nuevoPasajeroForm.value);
                this.selectPasajeros.push(data["data"]);
                console.log(this.selectPasajeros);
                this.nuevoPasajeroForm.reset()
                $('#crearPasajero').hide();
                  this.getListPasajeros();
              }else{
                  this.toastr.error(data["msg"]);
              }
          }); 
        this.isAuthLoading = false;
    } else {
        this.toastr.error('El formulario no es válido!');
    }
  } 

  selectPasajero(item){
    this.buscador = null;
    this.selectPasajeros.push(item);
    console.log(item);      
  }  

  async editPasajero(){  
    console.log('dentro');       
    if (this.nuevoPasajeroForm.valid) {
      this.selectPasajeros.push(this.nuevoPasajeroForm.value);
      console.log(this.selectPasajeros);
      this.nuevoPasajeroForm.reset()
      $('#verPasajeroModal').hide();
    }
  } 
  
  
  selectVueloOrigen(item){
    this.nuevoVueloForm.get('origen').setValue(item.codigo);
    $('#iataOrigen').hide();
  }

  selectVueloDestino(item){
    this.nuevoVueloForm.get('destino').setValue(item.codigo); 
    $('#iataDestino').hide();
  }  

  async newVuelo(){  
    console.log('dentro');
       
    if (this.nuevoVueloForm.valid) {
        this.isAuthLoading = true; 
        this.selectVuelos.push(this.nuevoVueloForm.value);
        this.nuevoVueloForm.reset() 
        this.isAuthLoading = false;

    } else {
        this.toastr.error('El formulario no es válido!');
    }
  }  


  selectProveedorActividad(item){
    this.buscador = null;
    this.selectProveedorActividades.push(item);
    console.log(item);      
  } 
  
  deletePasajeros(i){
    this.selectPasajeros.splice(i,1);       
  }
  deleteVuelos(i){
    this.selectVuelos.splice(i,1);       
  }

  deleteProveedorActividades(i){
    this.selectProveedorActividades.splice(i,1);       
  }
  
  changePage(page) {
    this.page = page; //para el filtro
    this.tarjeta_reserva.current = page;
    this.getList();
  }
  
  ForpagesNumber() {
    let from = this.tarjeta_reserva.current - this.offset;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offset * 2); 
    if(to >= this.tarjeta_reserva.total_page){
      to = this.tarjeta_reserva.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

  async nuevoTarjeta() {     
    if (this.nuevoForm.valid) {
        this.isAuthLoading = true; 
          let params = {
              nuevo:true,
              et: this.user.et,
              id: this.nuevoForm.value.id,
              pasajeros: this.selectPasajeros,
              vuelos: this.selectVuelos,
              estado: 1,
            };
            console.log(params);
            this.appService.postTarjetaReserva(params)
            .subscribe( (data) => {
            // console.log(data);
              if (data["logger"] == true){
                  this.nuevoForm.reset()
                  $('#nuevo').modal('hide');
                  this.toastr.success(data["msg"]);
                  this.selectPasajeros = [];
                  this.getList();
              }else{
                  this.toastr.error(data["msg"]);
              }
          }); 
        this.isAuthLoading = false;
    } else {
        this.toastr.error('El formulario no es válido!');
    }
  }


  ver(item){
    console.log(item);
    this.editForm.get('id').setValue(item.id);
    this.editForm.get('nombre').setValue(item.nombre);
    this.editForm.get('email').setValue(item.email);
    this.editForm.get('cc').setValue(item.cc);
    this.editForm.get('direccion').setValue(item.direccion);
    this.editForm.get('celular').setValue(item.celular);
    this.editForm.get('ciudad').setValue(item.ciudad);
    $('#ver').modal('show');
  }

  async editUsuario(){
    if (this.editForm.valid) {
      this.isAuthLoading = true; 
        let params = {
            editar:true,
            id: this.editForm.value.id,
            nombre: this.editForm.value.nombre,
            email: this.editForm.value.email,
            direccion: this.editForm.value.direccion,
            celular: this.editForm.value.celular,
            cc: this.editForm.value.cc,
            password: this.editForm.value.password,
            nota: this.editForm.value.nota,
            empresa_id: this.user.eid
          };
          // console.log(params);
          this.appService.postClientes(params)
        .subscribe( (data) => {
            if (data["logger"] == true){
              this.toastr.success(data["msg"]);
              this.editForm.reset()
              $('#ver').modal('hide');
              this.getList();
            }else{
              this.toastr.error(data["msg"]);
            }
        }); 
      this.isAuthLoading = false;
  } else {
      this.toastr.error('El formulario no es válido!');
  }
  }

  async eliminarUsuario(item){
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea eliminar al usuario?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        // console.log('dentro true');
        
        let params = {
          eliminar : true,
          id: item.id,
          empresa_id: this.user.eid
        }
        this.appService.postUsuario(params)
        .subscribe( (data) => {
            if (data["logger"] == true){
                this.getList();
                this.toastr.success(data["msg"]);
            }else{
                this.toastr.error(data["msg"]);
            }
        });         

      }
    });  

  }


  async findCliente() {     
    let params = {
        buscar:true,
        buscar_input: this.findInput,
        page: this.page,
        et: this.user.et,
      };
        this.appService.postClientes(params)
        .subscribe( (data) => {
            if (data["logger"] == true){
              this.tarjeta_reserva = data['tarjeta_reserva']; 
            }else{
              this.toastr.error(data["msg"]);
            }
        });
  }
 

}
