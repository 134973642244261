import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

declare var $: any;


@Component({
  selector: 'app-sectores',
  templateUrl: './sectores.component.html',
  styleUrls: ['./sectores.component.scss']
})
export class SectoresComponent implements OnInit {
  public nuevoForm: FormGroup;
  public editForm: FormGroup;
  public nuevoFormUsuario: FormGroup;
  public isAuthLoading = false;


  public user: any = null;
  sector_id: any;
  reporteExport: any;
  findInput: any;
  sectores: any = [];
  puesto_votacion: any = [];
  mesa_votacion: any = [];
  supervisor_votacion: any = [];
  puesto_sector: any = [];
  listUsuarios: any = [];
  buscador: any;
  mesaId: any;
  perfilUsarioCrear: any;
  puestoId: any;
  pagesNumber: any;
  offset = 3;
  page = 1;
  public cargando = false;
  public editando = false;
  nombreMesa: any;
  sufragantesMesa: any;
  count_sufragantes: any;

  constructor(public router: Router, private toastr: ToastrService, private appService: AppService, private http: HttpClient) { }

  ngOnInit(): void {
    this.user = this.appService.user;
    this.getList();
    this.getPuestoVotacion();

    this.nuevoForm = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      detalle: new FormControl(null, Validators.nullValidator),
    });

    this.editForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.required),
      detalle: new FormControl(null, Validators.nullValidator),
    });

    this.nuevoFormUsuario = new FormGroup({
      nombreUser: new FormControl(null, Validators.required),
      emailUser: new FormControl(null, Validators.required),
      passwordUser: new FormControl(null, Validators.required),
      celularUser: new FormControl(null, Validators.required),
      perfil_id: new FormControl(null, Validators.nullValidator),
    });


  }

  getList() {
    this.appService.getSector(this.user.et + '-' + this.page).subscribe((data) => {
      this.sectores = data['obj'];
      this.ForpagesNumber();
      this.cargando = false;
    });
  }

  changePage(page) {
    this.page = page; //para el filtro
    this.sectores.current = page;
    this.getList();
  }

  ForpagesNumber() {
    let from = this.sectores.current - this.offset;
    if (from < 1) {
      from = 1;
    }

    let to = from + (this.offset * 2);
    if (to >= this.sectores.total_page) {
      to = this.sectores.total_page;
    }

    let pagesArray = [];
    while (from <= to) {
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

  async new() {
    if (this.nuevoForm.valid) {
      this.isAuthLoading = true;
      let params = {
        nuevo: true,
        et: this.user.et,
        nombre: this.nuevoForm.value.nombre,
        detalle: this.nuevoForm.value.detalle,
        estado: 1,
      };
      // console.log(params);
      this.appService.postSector(params)
        .subscribe((data) => {
          // console.log(data);
          if (data["logger"] == true) {
            this.nuevoForm.reset()
            $('#offcanvasNuevo').offcanvas('hide');
            this.toastr.success(data["msg"]);
            this.getList();
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }


  ver(item) {
    this.sector_id = item.id;
    this.editForm.get('id').setValue(item.id);
    this.editForm.get('nombre').setValue(item.nombre);
    this.editForm.get('detalle').setValue(item.detalle);
    this.count_sufragantes = item.count_sufragantes;

    $('#verModal').modal('show');
    this.editando = false;
    this.getSectorPuestos(item.id);
    this.getSectorRelacion(item.id);
    console.log(item);
  }


  getListUsuarios(perfil_id, puestoId, mesaId) {
    this.perfilUsarioCrear = perfil_id;
    // console.log(this.perfilUsarioCrear);

    this.mesaId = mesaId;
    this.puestoId = puestoId;
    let params = {
      get: true,
      et: this.user.et,
      perfil_id: perfil_id,
    };
    // console.log(params);
    this.appService.postUsuario(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.listUsuarios = data['obj'];
          // console.log(this.listUsuarios);          
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  async edit() {
    if (this.editForm.valid) {
      this.isAuthLoading = true;
      let params = {
        editar: true,
        id: this.editForm.value.id,
        nombre: this.editForm.value.nombre,
        detalle: this.editForm.value.detalle,
      };
      // console.log(params);
      this.appService.postPuestoVotacion(params)
        .subscribe((data) => {
          if (data["logger"] == true) {
            this.toastr.success(data["msg"]);
            this.editForm.reset()
            $('#offcanvasVer').offcanvas('hide');
            this.getList();
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }

  async eliminar(item) {
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea eliminar este registro?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        let params = {
          eliminar: true,
          id: item.id,
          empresa_id: this.user.eid
        }
        this.appService.postPuestoVotacion(params)
          .subscribe((data) => {
            if (data["logger"] == true) {
              this.getList();
              this.toastr.success(data["msg"]);
            } else {
              this.toastr.error(data["msg"]);
            }
          });

      }
    });

  }


  async find() {
    let params = {
      buscar: true,
      buscar_input: this.findInput,
      page: this.page,
      et: this.user.et,
    };
    this.appService.postPuestoVotacion(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.sectores = data['obj'];
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }


  crearUsuarioCard() {
    $('#nuevoUsuario').show();
  }

  async newUsuario() {
    if (this.nuevoFormUsuario.valid) {
      this.isAuthLoading = true;
      let params = {
        nuevo: true,
        nombre: this.nuevoFormUsuario.value.nombreUser,
        email: this.nuevoFormUsuario.value.emailUser,
        password: this.nuevoFormUsuario.value.passwordUser,
        celular: this.nuevoFormUsuario.value.celularUser,
        perfil_id: this.perfilUsarioCrear,
        estado: 1,
        empresa_id: this.user.eid
      };
      console.log(params);
      this.appService.postUsuario(params)
        .subscribe((data) => {
          if (data["logger"] == true) {
            this.nuevoFormUsuario.reset()
            $('#nuevoUsuario').hide();
            $('#buscarUsuarioModal').modal('hide');
            this.toastr.success(data["msg"]);
            console.log(data["obj"]);

            this.selectUsuarioAbogado(data["obj"].id, data["obj"].perfil_id)
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }


  getSectorRelacion(id) {
    let params = {
      get: true,
      et: this.user.et,
      id: id,
      estado: 1,
    };
    // console.log(params);
    this.appService.postSectorRelacion(params)
      .subscribe((data) => {
        // console.log(data);
        if (data["logger"] == true) {

          this.count_sufragantes = data["count_sufragantes"];
          this.mesa_votacion = data['obj'];
          this.supervisor_votacion = data['obj2'];
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  getSectorPuestos(id) {
    let params = {
      get: true,
      et: this.user.et,
      id: id,
      estado: 1,
    };
    // console.log(params);
    this.appService.postSectorPuesto(params)
      .subscribe((data) => {
        console.log(data);
        if (data["logger"] == true) {
          // this.count_sufragantes = data["count_sufragantes"];
          this.puesto_sector = data['obj'];
          // this.supervisor_votacion = data['obj2'];
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  addPuestoSector(id, sector_id) {
    let params = {
      nuevo: true,
      et: this.user.et,
      puesto_id: id,
      sector_id: sector_id,
      estado: 1,
    };
    console.log(params);
    this.appService.postSectorPuesto(params)
      .subscribe((data) => {
        // console.log(data);
        if (data["logger"] == true) {

          this.getSectorPuestos(sector_id);
          this.getPuestoVotacion();
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  deletePuestoSector(puesto_id, sector_id, id) {
    let params = {
      eliminar: true,
      et: this.user.et,
      id: id,
      puesto_id: puesto_id,
      estado: 1,
    };
    console.log(params);
    this.appService.postSectorPuesto(params)
      .subscribe((data) => {
        // console.log(data);
        if (data["logger"] == true) {

          this.getSectorPuestos(sector_id);
          this.getPuestoVotacion();
          // this.getList();
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  selectUsuarioAbogado(id, perfil_id) {
    let params = {
      nuevo: true,
      et: this.user.et,
      id: id,
      sector_id: this.sector_id,
      puesto_id: this.puestoId,
      perfil_id: perfil_id,
      estado: 1,
    };
    // console.log(params);
    this.appService.postSectorRelacion(params)
      .subscribe((data) => {
        // console.log(data);
        if (data["logger"] == true) {
          this.getSectorRelacion(this.sector_id);
          this.mesaId = null;
          this.puestoId = null;
          this.buscador = null;
          this.toastr.success(data["msg"]);
          // this.mesa_votacion = data['obj'];
        } else {
          this.mesaId = null;
          this.puestoId = null;
          this.buscador = null;
          this.toastr.error(data["msg"]);
        }
      });
  }

  edicion(item) {
    this.editando = true;
    this.nombreMesa = item.nombre;
    this.sufragantesMesa = item.sufragantes;
    this.mesaId = item.id;
    this.puestoId = item.puesto_id;
  }

  actualizarMesa() {
    let params = {
      editar: true,
      et: this.user.et,
      id: this.mesaId,
      nombre: this.nombreMesa,
      sufragantes: this.sufragantesMesa,
    };
    console.log(params);
    console.log(this.puestoId);
    this.appService.postMesaVotacion(params)
      .subscribe((data) => {
        // console.log(data);
        if (data["logger"] == true) {
          this.getSectorRelacion(this.sector_id);
          this.getList();
          this.editando = false;
          this.toastr.success(data["msg"]);
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  eliminarMesa(idMesa, idPuesto) {
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea eliminar esta mesa de votación?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        let params = {
          eliminar: true,
          id: idMesa,
          puesto_id: idPuesto,
          empresa_id: this.user.eid
        }
        this.appService.postMesaVotacion(params)
          .subscribe((data) => {
            if (data["logger"] == true) {
              this.getSectorRelacion(idPuesto);
              this.getList();
              // this.getMesaVotacion(puestoId);
              this.toastr.success(data["msg"]);
            } else {
              this.toastr.error(data["msg"]);
            }
          });

      }
    });
  }

  eliminarTestigo(id) {
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea eliminar este registro?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        let params = {
          eliminar: true,
          id: id,
          empresa_id: this.user.eid
        }
        this.appService.postSectorRelacion(params)
          .subscribe((data) => {
            if (data["logger"] == true) {
              this.getSectorRelacion(this.sector_id);
              // this.toastr.success(data["msg"]);
            } else {
              this.toastr.error(data["msg"]);
            }
          });

      }
    });
  }


  getPuestoVotacion() {
    let params = {
      getPuestoSector: true,
      et: this.user.et,
    };
    // console.log(params);
    this.appService.postPuestoVotacion(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.puesto_votacion = data['obj'];
          // console.log(this.puesto_votacion);          
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }



}
