<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button class="nav-link active" id="mapa-tab" data-bs-toggle="tab" data-bs-target="#mapa_votacion" type="button" role="tab"
      aria-controls="mapa_votacion" aria-selected="false">Mapa Escrutinio <i class="fa fa-traffic-light"></i></button>
  </li>
</ul>

<div class="tab-content" id="myTabContent">

  <!-- TAB MAPA VOTACION -->
  <div class="tab-pane fade show active bg-body" id="mapa_votacion" role="tabpanel" aria-labelledby="mapa-tab">
  <!-- <app-mapa-votacion [selectorEdit]="editItem" (nuevaMatriz)="actualizar($event)"></app-mapa-votacion> -->
    <app-mapa-escrutinio></app-mapa-escrutinio>
  </div>

</div>