import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';

declare var $: any;
@Component({
  selector: 'app-candidatos',
  templateUrl: './candidatos.component.html',
  styleUrls: ['./candidatos.component.scss']
})
export class CandidatosComponent implements OnInit {
  public nuevoForm: FormGroup;
  public editForm: FormGroup;
  public isAuthLoading = false;
  public loadingImg = false;


  public user: any = null;
  candidatos: any = [];
  pagesNumber: any;
  offset= 3;
  page= 1;  
  public cargando = false;


  constructor( public router: Router,private toastr: ToastrService, private appService: AppService, private http: HttpClient) { }

  ngOnInit(): void {
    this.user = this.appService.user;
    this.getUsuarios();  
    
    this.nuevoForm = new FormGroup({
      fotografia: new FormControl(null, Validators.nullValidator),
      fotografia_url: new FormControl(null, Validators.nullValidator),      
      nombre: new FormControl(null, Validators.required),
      partido: new FormControl(null, Validators.required),
    });   
    
    this.editForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      fotografia: new FormControl(null, Validators.nullValidator),
      fotografia_url: new FormControl(null, Validators.nullValidator),      
      nombre: new FormControl(null, Validators.required),
      color: new FormControl('#ff0000'),
      partido: new FormControl(null, Validators.nullValidator),
    });       

  }

  getUsuarios(){
    this.appService.getCandidatos(this.user.et+'-'+this.page).subscribe( (data) => {
    this.candidatos = data['obj']; 
    this.ForpagesNumber();
    this.cargando = false;
    });
  }


  changePage(page) {
    this.page = page; //para el filtro
    this.candidatos.current = page;
    this.getUsuarios();
  }
  
  ForpagesNumber() {
    let from = this.candidatos.current - this.offset;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offset * 2); 
    if(to >= this.candidatos.total_page){
      to = this.candidatos.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
  }


  async newUsuario() {   
    // console.log(this.nuevoForm);
    if (this.nuevoForm.valid) {
        this.isAuthLoading = true; 
          let params = {
              nuevo:true,
              nombre: this.nuevoForm.value.nombre,
              partido: this.nuevoForm.value.partido,
              fotografia: this.nuevoForm.value.fotografia,
              estado: 1,
              et: this.user.et,
            };
            // console.log(params);
            this.appService.postCandidato(params)
          .subscribe( (data) => {
              if (data["logger"] == true){
                  this.nuevoForm.reset()
                  $('#nuevaUsuario').modal('hide');
                  this.getUsuarios();
              }else{
                  this.toastr.error(data["msg"]);
              }
          }); 
        this.isAuthLoading = false;
    } else {
        this.toastr.error('El formulario no es válido!');
    }
  }

  verUsuario(item){
    console.log(item);
    this.editForm.get('id').setValue(item.id);
    this.editForm.get('fotografia').setValue(item.fotografia);
    this.editForm.get('fotografia_url').setValue(item.fotografia_url);
    this.editForm.get('nombre').setValue(item.nombre);
    this.editForm.get('color').setValue(item.color);
    this.editForm.get('partido').setValue(item.partido);
  }

  async editUsuario(){    
    if (this.editForm.valid) {
      this.isAuthLoading = true; 
        let params = {
            editar:true,
            id: this.editForm.value.id,
            nombre: this.editForm.value.nombre,
            partido: this.editForm.value.partido,
            color: this.editForm.value.color,
            fotografia: this.editForm.value.fotografia,
            estado: 1,
            empresa_id: this.user.eid
          };
          console.log(params);
          this.appService.postCandidato(params)
        .subscribe( (data) => {
            if (data["logger"] == true){
                this.editForm.reset()
                $('#verUsuario').modal('hide');
                this.getUsuarios();
            }else{
                this.toastr.error(data["msg"]);
            }
        }); 
      this.isAuthLoading = false;
  } else {
      this.toastr.error('El formulario no es válido!');
  }
  }

  async eliminarUsuario(item){
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea eliminar al Candidato?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        console.log('dentro true');
        
        let params = {
          eliminar : true,
          id: item.id,
          empresa_id: this.user.eid
        }
        this.appService.postCandidato(params)
        .subscribe( (data) => {
            if (data["logger"] == true){
                this.getUsuarios();
                this.toastr.success(data["msg"]);
            }else{
                this.toastr.error(data["msg"]);
            }
        });         

      }
    });  

  }

  preview(files,componente) {
    if (files.length === 0)
      return;   
    this.loadingImg =true;
    var reader = new FileReader();
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      const imgURL = reader.result; 

      if(!imgURL) {
        console.log('error');
        return;
      } else {
        let params = {
          folder: 'candidatos',
          archivo: imgURL
        };
        // this.cargandoImg = true;
        this.appService.uploadImg(params)
        .subscribe( (data) => {
          if(data['logger'] == true){
            if(componente =='nuevo'){
              this.nuevoForm.get('fotografia').setValue(data['filename']);
              this.nuevoForm.get('fotografia_url').setValue(data['fileUrl']);  
            }
            if(componente =='editar'){
              this.editForm.get('fotografia').setValue(data['filename']);
              this.editForm.get('fotografia_url').setValue(data['fileUrl']);                
            }            
            // console.log(data);
          }
          this.loadingImg =false;
        });
      }      
    }

  }

}
